import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { Link as EELink } from '@micro-frontend-react/employee-experience/lib/Link';

const LinkStyled = `
    display: block;
    font-weight: 600;
    text-align: left;
    height: auto;
    min-width: 0;
    color: rgb(0, 80, 169);
    padding: 0;
    border: 0;
    margin-top: 15px;
    margin-left: 0;
    background-color: transparent;

    & span {
        margin-left: 0;
    } 

    &&&:hover {
        background-color: transparent;
        color: rgb(0, 80, 169);
        text-decoration: underline;
    }
`

export const QuestionLink = styled(DefaultButton)`
   ${LinkStyled}
`; 

export const Header = styled.h3`
    margin-bottom: 12px;
`;

export const ContentSection = styled.div`
    &&&:first-of-type {
        margin-top: 0px;
    }
    
    margin-top: 30px;
`;

export const Link = styled(EELink)`
    ${LinkStyled}
    text-decoration: underline;
`;

export const ListItem = styled.li`
    &&&:first-of-type {
        margin-top: 0px;
    }

    margin-top: 10px;
`