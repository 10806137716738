import { IUserInfo } from "./GlobalHolidays/GlobalHolidaysService.types";
import * as React from 'react';

export interface IStore {
    userInfo: IUserInfo;
}

export interface IAction {
    type: string, 
    payload: any
}

export const AppContext = React.createContext(null);

/**
     * Performs an action against the store to update the store
     * @param store - the current store
     * @param action - an Action created by an Action Creator in Actions.ts
     * @returns the new store
     */
export const reducer = (store: any, action: { type: string; payload: any; }) => {
    switch(action.type) {
        case 'UPDATE_USER_INFO':
            return {
                ...store,
                userInfo: action.payload
            };
        default: 
            throw new Error("Unrecognized action type");
    }
}

export const initialState: IStore = {
    userInfo: null
}

/**
 * Actions and Action Creators.
 */

export const Actions = {
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
}

export const updateUserInfoAction = (userInfo: IUserInfo) => ({
    type: Actions.UPDATE_USER_INFO,
    payload: userInfo
});