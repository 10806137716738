export const InputContainer = styled.div`
    position: relative;
    min-width: 180px;
    max-width: 60%;
`;

export const InputTitle = styled.div`
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 5px;
    margin-top: 20px;
`;

export const Alert = styled.div`
    position: relative;
    min-width: 180px;
    max-width: 60%;
    margin-top: 20px;
    color: red;
`;