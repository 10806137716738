import { ErrorStateComponent, ErrorStateIconType } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { EventType, UsageEventName, UserEvent } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { usePageTracking } from '@micro-frontend-react/employee-experience/lib/usePageTracking';
import * as _ from 'lodash';
import { CommandButton, IContextualMenuItem, IContextualMenuListProps, IDropdownOption, IRenderFunction, MessageBar, MessageBarType, SearchBox, Spinner, SpinnerSize } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { Page, PageHeading, PageHeadingCaption } from '../Shared/Layout.styled';
import { AppContext } from '../StateManagement';
import { CalendarPanel } from './CalendarPanel/CalendarPanel';
import * as Styled from './GlobalHolidaysPage.styled';
import GlobalHolidaysService from '../Services/GlobalHolidaysService';
import CalendarService from '../Services/CalendarService';
import { IHoliday, IHolidayDomainData, IHolidayRegion, IUserInfo, IHolidaysRenderData, IHolidaysCustomText, IHolidaysCustomTextMapping } from '../Services/GlobalHolidaysService.types';
import { allDropdownOptions, getDropdownIndexOfHolidayType, HolidaysList } from './HolidaysList';
import { updateUserInfoAction } from '../StateManagement';
import { cloneDeep } from 'lodash';
import * as moment from 'moment';
import { IStore,  IAction } from '../StateManagement';
import Announcements from '../Components/Announcements';
import CustomText from './CustomText/CustomText';

export const COMPANY_CODE_1010 = "1010";

export const HomePageUserEvent: Partial<UserEvent> = {
    feature: 'GCH',
    type: EventType.User
};

// TODOs --
//  - When user is retail or 'retail' is selected from dropdown inside HolidayList, display a different/shorter
//      list of companies/countries (since there are fewer retail company codes)
//      -- The `selectedHolidayType` state from HolidayList.tsx may need to be pulled up to this file and 
//          passed to HolidayList as props
//      -- GCH API needs to have a way to specify if a company code is for just corporate or corporate + retail
//  - Update below to use future updated GCH API.  Some assumptions were made that may not end up being true:
//      -- PersonnelSubAreaCode will be added to User Info (alongside already existing PersonnelSubAreaName)
//      -- User Info from /includedomaindata endpoint will include PersonnelType (corporate vs retail)
//      -- Holidays will have HolidayType property (corporate vs retail)

export const GlobalHolidaysPage = (): React.ReactElement => {
    const NO_RESULTS_FOUND = "No results found";

    usePageTitle(`${__APP_NAME__}`);
    const pageEvent = { ...HomePageUserEvent, subFeature: 'Home', eventName: UsageEventName.PageLoad };
    // @ts-ignore
    usePageTracking(pageEvent);

    const { authClient, httpClient, telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    
    const globalHolidaysService = new GlobalHolidaysService(httpClient);
    const calendarService = new CalendarService(httpClient);

    const { store, dispatch } : { store: IStore, dispatch: React.Dispatch<IAction> } = React.useContext(AppContext);

    const nationalOnlyLabel = 'All other areas';
    const nationalOnlySubArea = {
        SubAreaCode: '0',
        SubAreaName: nationalOnlyLabel,
    };

    const corporateDropdownOption = allDropdownOptions.find(x => x.key == 'corporate');
    const retailDropdownOption = allDropdownOptions.find(x => x.key == 'retail');

    const [loading, setLoading] = useState<boolean>(true);
    const [loadingHolidays, setLoadingHolidays] = useState<boolean>(true);

    const [initialLoadError, setInitialLoadError] = useState<boolean>(false);
    const [userInfoError, setUserInfoError] = useState<boolean>(false);
    const [getHolidaysError, setGetHolidaysError] = useState<boolean>(false);
    const [successNotification, setSuccessNotification] = useState<boolean>(false);

    const userInfo = store.userInfo;
    const setUserInfo = (userInfoData : IUserInfo) => {
        dispatch(updateUserInfoAction(userInfoData));
    }

    const [customTextMapping, setCustomTextMapping] = useState<IHolidaysCustomTextMapping>({});
    const [selectedHolidayType, setSelectedHolidayType] = useState<IDropdownOption>();
    const [selectedRegion, setSelectedRegion] = useState<IHolidayRegion>();
    const [regions, setRegions] = useState<IHolidayRegion[]>();
    const [dropdownOptions, setDropdownOptions] = useState<IDropdownOption[]>([]);
    const [searchString, setSearchString] = useState<string>('');
    const [searchAnnouncementText, setAnnouncementText] = useState<string>('');
    
    const [currentHolidays, setCurrentHolidays] = useState<IHoliday[]>([]);

    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    const [holidaysRenderData, setHolidaysRenderData] = useState<IHolidaysRenderData>();
    
    const [selectedYear, setSelectedYear] = useState<number>(); // this variable is trash, not even used 
    const [selectedHoliday, setSelectedHoliday] = useState<IHoliday>();

    useEffect(() => {
        // On initial page load, get the following info from GCH API, and process it:
        //  - Region Info - Companies, Countries, Sub-areas
        //  - Holiday Info (for current user)
        //  - User Info (for current user)
        authClient.getUser().then(user => {
            const tenantId = user.oid?.split(".")[1];

            // TODO: remove once calendar permissions are approved for msft tenant
            if (__MSFT_TID__ != tenantId) {
                // fetch msgraphuserinfo to ensure user has accepted requested permissions for test tenant users
                calendarService.getMsGraphUserInfo()
                    .then(upn => console.log("upn: ", upn))
                    .catch(err => console.error(err));    
                
                const mockHolidaysDomainData = globalHolidaysService.getMockHolidayDomainData();

                try {
                    setHolidayDomainData(mockHolidaysDomainData);
                } catch (err) {
                    console.error(err);
                    setInitialLoadError(true);
                }    
            } else {
                let getCorpHolidayPromiseDomainDataPromise = globalHolidaysService.getHolidayDomainData();
                let getRetailHolidayPromiseDomainDataPromise = globalHolidaysService.getRetailHolidayDomainData();

                const promises = Promise.all([getRetailHolidayPromiseDomainDataPromise, getCorpHolidayPromiseDomainDataPromise]);

                promises.then(res => {
                    if (!res[0]) {
                        throw new Error("Unable to fetch corporate holidays domain data");
                    } 

                    if (!res[1]) {
                        throw new Error("Unable to fetch retail holidays domain data");
                    } 

                    const retailResp = res[0];
                    const corpResp = res[1];

                    if (retailResp?.UserInfo?.WorkForceClassId == "RT") {
                        setHolidayDomainData(retailResp);
                    } else {
                        setHolidayDomainData(corpResp);
                    }
                }).catch(() => setInitialLoadError(true));
            }
        }).catch(err => {
            console.error(err);
            telemetryClient.trackException({
                exception: new Error("Failed to get user info."),
            }, {
                error: err as Error
            });
        })

    }, [authClient]);

    useEffect(() => {
        // If selected region changes, get holidays for new selection
        if (!loading && selectedRegion && userInfo) {
            setLoadingHolidays(true);
            setGetHolidaysError(false);

            let getHolidaysPromise;

            // TODO: change if there are new retail holiday regions added, optimize calls so other regions that don't have retail holidays dont need to call extra endpoint
            if (selectedRegion.CompanyCode == "1010") {
                getHolidaysPromise = globalHolidaysService.getHolidays(selectedRegion.CompanyCode, selectedRegion.SubAreaCode)
            } else {
                getHolidaysPromise = globalHolidaysService.getCorporateHolidays(selectedRegion.CompanyCode, selectedRegion.SubAreaCode)
            }

            setLoadingHolidays(true);
            getHolidaysPromise
                .then((res) => {
                    console.log("done loading holidays");
                    setHolidaysData(res);
                }).catch(() => {
                    setLoadingHolidays(false);
                    setCurrentHolidays([]);
                    setGetHolidaysError(true);
                });

            globalHolidaysService.getHolidaysCustomText()
                .then((res) => {
                    setCustomTextData(res);
                }).catch((err) => {
                    console.error("err: ", err);
                });
        }
    }, [loading, selectedRegion, userInfo]);

    // setDropdownOptions
    // TODO: once full retail is implemented, update this logic to consider whether a country has both or one of corp, retail holidays
    useEffect(() => {
        if (selectedRegion && currentHolidays && currentHolidays.length > 0 && userInfo) {
            const companyCode : string = selectedRegion?.CompanyCode.toString();
            if (companyCode == COMPANY_CODE_1010) {

                if (userInfo.PersonnelType == "retail") {
                    setDropdownOptions([retailDropdownOption])
                } else {
                    setDropdownOptions(allDropdownOptions);
                }
            } else {
                // only show corporate holidays
                setDropdownOptions([corporateDropdownOption]);
            }
        }
    }, [selectedRegion, currentHolidays, userInfo])

    // set selectedHolidayType, based on dropdownOptions
    useEffect(() => {
        if (userInfo && dropdownOptions && dropdownOptions.length > 0) {
            const dropdownIndex = getDropdownIndexOfHolidayType(userInfo.PersonnelType, dropdownOptions);

            // if not dropdown options match the user's PersonnelType, then set the default to corporate
            if (dropdownIndex < 0) {
                setSelectedHolidayType(dropdownOptions[0])
            } else {
                setSelectedHolidayType(dropdownOptions[dropdownIndex])
            }
        }
    }, [userInfo, dropdownOptions]);

    useEffect(() => {
        // executed only once
        // Once region and user info is loaded on page initialization,
        //  find the user's region from the list of regions, and set it as
        //  the current selection (used by ContextualMenu/CommandButton, and to get new holidays)
        if (regions && userInfo) {
            const foundRegions = regions.filter((region: IHolidayRegion) => {
                return region.CompanyCode === userInfo.CompanyCode
                    && region.SubAreaCode === userInfo.PersonnelSubAreaCode;
            });

            if (foundRegions && foundRegions.length > 0) {
                setSelectedRegion(foundRegions[0]);
            } else {
                const foundGeneralRegions = regions.filter((region: IHolidayRegion) => {
                    return region.CompanyCode === userInfo.CompanyCode;
                });

                if (foundGeneralRegions && foundGeneralRegions.length > 0) {
                    setSelectedRegion({
                        ...foundGeneralRegions[0],
                        ...nationalOnlySubArea
                    })
                } else {
                    setSelectedRegion(undefined);
                }
            }
        }
    }, [regions, userInfo]);


    const setHolidayDomainData = (holidayDomainData: IHolidayDomainData) => {
            setRegions(globalHolidaysService.convertToRegions(
                holidayDomainData.GlobalHolidayCompanyCodes,
                holidayDomainData.GlobalHolidayCountries,
                holidayDomainData.GlobalHolidaySubAreas
            ));

            const workForceClassId = holidayDomainData.UserInfo.WorkForceClassId;

            const userInfoData: IUserInfo = {
                ...holidayDomainData.UserInfo,
                // TODO -- Remove later.  Temporary until PersonnelSubAreaCode is implemented in GCH API /getByUpn/includeDomainData
                PersonnelSubAreaCode: '0',
                PersonnelType: workForceClassId == 'RT' ? 'retail' : 'corporate'
            };

            setUserInfo(userInfoData);

            if (userInfoData.CompanyCode && userInfoData.PersonnelSubAreaCode) {
                setCurrentHolidays(holidayDomainData.Holidays);
            } else {
                setUserInfoError(true);

                if (!userInfoData.PersonnelSubAreaCode) {
                    setUserInfo({ ...userInfoData, PersonnelSubAreaCode: '0' });
                }
            }

            setLoading(false);
            setLoadingHolidays(false);
    };

    const setCustomTextData = (customTextList : IHolidaysCustomText[]) => {
        
        let customTextMapping : IHolidaysCustomTextMapping = {};

        for(let i = 0; i < customTextList.length; i++) {
            let companyCode = customTextList[i].CompanyCode;
            customTextMapping[companyCode] = customTextList[i];
        }

        setCustomTextMapping(customTextMapping);
    }

    const setHolidaysData = (holidays : IHoliday[], isRetail = false) => {
        setCurrentHolidays(holidays || []);
        setLoadingHolidays(false);
    }

    const regionMenuItems = useMemo<IContextualMenuItem[]>(() => {
        
        const getOnClickHandler = (region : IHolidayRegion) => {
            return () => {
                setSelectedRegion(region);
                setUserInfoError(false);
                const event: UserEvent = {
                    feature: 'GCH',
                    subFeature: 'SelectRegion',
                    featureLocation: 'Home',
                    eventName: UsageEventName.ButtonClicked,
                    type: EventType.User,
                };
                telemetryClient.trackEvent(event, {
                    ...region
                });
            }
        }
        
        if (regions) {
            const sortedRegions = _.sortBy(regions, ['CountryName', 'CompanyCode', 'SubAreaName']);

            const firstLevelRegions = _.uniqBy(sortedRegions, (region: IHolidayRegion) => region.CompanyCode);

            const menuItems: IContextualMenuItem[] = firstLevelRegions.map((region: IHolidayRegion) => {
                const allSubAreas: IHolidayRegion[] = _.filter(sortedRegions, ['CompanyCode', region.CompanyCode]);

                const hasSubMenu = allSubAreas.length > 1;
                const defaultSubAreaCode = allSubAreas.length === 1 ? allSubAreas[0].SubAreaCode : '0';

                const subAreaMenuItems: IContextualMenuItem[] = !hasSubMenu ? undefined :
                    allSubAreas.map((subArea: IHolidayRegion) => {
                        return {
                            key: subArea.SubAreaCode,
                            text: subArea.SubAreaName,
                            onClick: getOnClickHandler({
                                CompanyCode: subArea.CompanyCode,
                                CountryCode: subArea.CountryCode,
                                CountryName: subArea.CountryName,
                                SubAreaCode: subArea.SubAreaCode,
                                SubAreaName: subArea.SubAreaName,
                            }),
                            ariaLabel: subArea.SubAreaName,
                        };
                    });

                if (hasSubMenu) {
                    subAreaMenuItems.unshift({
                        key: '0',
                        text: nationalOnlyLabel,
                        onClick: getOnClickHandler({
                            ...nationalOnlySubArea,
                            CompanyCode: region.CompanyCode,
                            CountryCode: region.CountryCode,
                            CountryName: region.CountryName,
                        }),
                        ariaLabel: nationalOnlyLabel,
                    });
                }

                const menuItemTitle = `${region.CountryName} (${region.CompanyCode})`;
                const menuItem: IContextualMenuItem = {
                    key: region.CompanyCode,
                    text: menuItemTitle,
                    subMenuProps: hasSubMenu ? { items: subAreaMenuItems } : undefined,
                    onClick: hasSubMenu ? undefined : getOnClickHandler({
                        CompanyCode: region.CompanyCode,
                        CountryCode: region.CountryCode,
                        CountryName: region.CountryName,
                        SubAreaCode: defaultSubAreaCode,
                        SubAreaName: undefined,
                    }),
                    style: { color: '#171717' },
                    ariaLabel: menuItemTitle,
                };

                return menuItem;
            });

            return menuItems;
        } else {
            return [];
        }
    }, [regions]);

    const itemsRef = React.useRef(null);

    // for accessibility to make sure that pressing the down arrow will focus the first element in the dropdown
    const handleSearchKeyPress = (event: any) => {
        if (event.key == "ArrowDown") {
            if (itemsRef?.current?.children[0]?.children[0]?.children[0]) {
                const buttonRef = itemsRef.current.children[0].children[0].children[0];
                buttonRef.tabIndex = 0;
                setTimeout(() => {
                    buttonRef.focus();
                }, 50);
                
                buttonRef.dispatchEvent(new KeyboardEvent('keydown', {'key': 'Tab'}))
            }
        }
    }

    const filteredMenuItems = useMemo<IContextualMenuItem[]>(() => {
        if (!searchString) {
            return regionMenuItems;
        }

        const filteredItems = regionMenuItems.filter(
            item => item.text && item.text.toLowerCase().indexOf(searchString.toLowerCase()) !== -1
        );

        filteredItems.map(x => {
            return x.className = ""
        })

        if (!filteredItems || !filteredItems.length) {
            filteredItems.push({
                key: 'no_results',
                onRender: () => (
                    <div key='no_results'>
                        <span>{NO_RESULTS_FOUND}</span>
                    </div>
                ),
            });
        }

        return filteredItems;
    }, [searchString, regionMenuItems]);

    const menuOnChange = (event?: React.ChangeEvent<HTMLInputElement>, newValue?: string) => {
        setSearchString(newValue);
    };

    const renderUserInfoWarning = () => {
        if (userInfoError) {
            return (
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.error}>
                        Error retrieving user's assigned region.
                    </MessageBar>
                </Stack.Item>
            );
        }
    };

    const renderSubAreaWarning = () => {
        if (selectedRegion?.SubAreaCode === '0' && _.filter(regions, ['CompanyCode', selectedRegion?.CompanyCode]).length > 1) {
            return (
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.warning}>
                        You currently have "{nationalOnlyLabel}" selected.  You may need to select a specific local region to see all of your holidays.
                    </MessageBar>
                </Stack.Item>
            )
        }
    };

    const renderSuccessNotification = () => {
        if (successNotification) {
            return (
                <Stack.Item>
                    <MessageBar messageBarType={MessageBarType.success} onDismiss={() => setSuccessNotification(false)}>
                        Holiday updates saved to your Outlook calendar.
                    </MessageBar>
                </Stack.Item>
            )
        }
    }

    const setSearchAnnouncementText = () => {
        if (filteredMenuItems.length == 1 && filteredMenuItems[0].key == "no_results") {
            setAnnouncementText(`0 search results displayed`)
        } else {
            setAnnouncementText(`${filteredMenuItems.length} search results displayed`)
        }
    }

    const renderMenuList = (props?: IContextualMenuListProps, defaultRender?: IRenderFunction<IContextualMenuListProps>) => {
        return (
            <>
                <SearchBox
                    ariaLabel="Search your country or company code here"
                    placeholder="Search your country or company code here"
                    onChange={(menuOnChange)}
                    onSearch={setSearchAnnouncementText}
                    styles={{
                        root: { fontSize: 12, maxWidth: 290 },
                        iconContainer: [{ width: 32 }],
                        icon: { opacity: 1 },
                    }}
                    style={{width:"290px"}}
                    value={searchString}
                    role="search"
                    onKeyDown={handleSearchKeyPress}
                />
                <div ref={itemsRef}>
                    {defaultRender(props)}
                </div>
            </>
        );
    };

    const renderCustomText = (selectedCompanyCode : string, personnelType: string) => {

        let customTextData : IHolidaysCustomText = customTextMapping[selectedCompanyCode]
        let customText;
        if (personnelType == customTextData?.PersonnelType || customTextData?.PersonnelType == "" || customTextData?.PersonnelType == null) {
            customText = customTextData?.Text;
        } 
        return <CustomText text={customText} />
    }

    if (initialLoadError) {
        return (
            <ErrorStateComponent
                iconType={ErrorStateIconType.OrangeCapAndCat}
                headingText='Something went wrong :('
                descriptionText='Could not retrieve data'
            />
        );
    }

    if (loading) {
        return (
            <Styled.LoadingContainer>
                <Styled.Loading>
                    <Spinner size={SpinnerSize.large} />
                    <PageHeading>Loading your content...</PageHeading>
                </Styled.Loading>
            </Styled.LoadingContainer>
        );
    }

    const menuLabel = (): string => {
        if (selectedRegion) {
            return selectedRegion.SubAreaName ?
                `${selectedRegion.CountryName} (${selectedRegion.CompanyCode}) - ${selectedRegion.SubAreaName}` :
                `${selectedRegion.CountryName} (${selectedRegion.CompanyCode})`;
        }
        return 'Please select a region';
    };

    return (
        <>
            {renderUserInfoWarning()}
            {renderSubAreaWarning()}
            {renderSuccessNotification()}
            <Page>
                <Announcements message={`${searchAnnouncementText}`}/>
                <Stack>
                    <Stack.Item grow={1}>
                        <PageHeading>
                            Holidays
                        </PageHeading>
                    </Stack.Item>
                    <Stack.Item grow={1}>
                        <PageHeadingCaption>
                            Find the dates that Microsoft offices will be closed to observe holidays. These dates may differ from the actual date of the holiday in cases where the holiday falls on a weekend.
                        </PageHeadingCaption>
                    </Stack.Item>
                    <Stack.Item>
                        <CommandButton
                            text={menuLabel()}
                            menuProps={{
                                onRenderMenuList: renderMenuList,
                                items: filteredMenuItems,
                                hidden: false,
                                shouldFocusOnMount: true
                            }}
                            styles={{
                                root: { padding: 0, fontSize: 18, fontWeight: 600},
                                label: { marginLeft: 0 }
                            }}
                        />
                    </Stack.Item>
                    <Stack.Item>
                        {renderCustomText(selectedRegion?.CompanyCode, selectedHolidayType?.key?.toString())}
                    </Stack.Item>
                    <Stack.Item>
                        <HolidaysList
                            dropdownOptions={dropdownOptions}
                            holidays={currentHolidays}
                            userInfo={userInfo}
                            selectedHolidayType={selectedHolidayType}
                            setSelectedHolidayType={setSelectedHolidayType}
                            loading={loadingHolidays}
                            error={getHolidaysError}
                            onInitiateCalendar={(newSelectedYear?: number, newSelectedHoliday?: IHoliday, newHolidayRenderData?: IHolidaysRenderData) => {
                                setSelectedYear(newSelectedYear);
                                setSelectedHoliday(newSelectedHoliday);
                                setHolidaysRenderData(newHolidayRenderData);
                                setPanelOpen(true);
                            }}
                            onSaveCalendar={() => setPanelOpen(false)}
                        /> 
                    </Stack.Item>
                </Stack>
            </Page>
            <CalendarPanel
                open={panelOpen}
                holidaysRenderData={holidaysRenderData}
                selectedYear={selectedYear}
                selectedHoliday={selectedHoliday}
                onSuccess={() => setSuccessNotification(true)}
                onClose={() => setPanelOpen(false)}
            />
        </>
    );
};
