interface SingleHolidayContainerProps {
    modified: boolean,
    deleted: boolean
}

export const SingleHolidayContainer = styled.tr<SingleHolidayContainerProps>`
    background-color: ${props => props.deleted ? '#ffe4ec' : props.modified? '#fff4cc' : 'white'};
    border: 1px solid black;
`;

export const LargeCol = styled.td`
    padding: 5px;
    width: 25%;
    word-wrap: break-word;
    border: 1px solid black;
`;

export const MedCol = styled.td`
    padding: 5px;
    width: 14%;
    word-wrap: break-word;
    border: 1px solid black;
`;

export const SmallCol = styled.td`
    padding: 5px;
    width: 10%;
    word-wrap: break-word;
    text-align: center;
    border: 1px solid black;
`;

export const HeaderContainer = styled.tr`
    font-weight: 500;
    background-color: #b0b0b0;
    border: 1px solid black;
`;

export const LargeHeadCol = styled.th`
    padding: 5px;
    width: 25%;
    word-wrap: break-word;
    border: 1px solid black;
`;

export const MedHeadCol = styled.th`
    padding: 5px;
    width: 14%;
    word-wrap: break-word;
    border: 1px solid black;
`;

export const SmallHeadCol = styled.th`
    padding: 5px;
    width: 10%;
    word-wrap: break-word;
    text-align: center;
    border: 1px solid black;
`;

export const ButtonsContainer = styled.div`
    width: 15%,
    height: 100%,
    padding: 3px;
    font-size: 14px;
    display: inline-block;
`;
