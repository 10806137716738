import { ReactElement, useState, useEffect } from 'react';
import * as React from 'react';
import { cloneDeep } from 'lodash';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { SectionTitle } from '../../../Shared/Layout.styled';
import { TableContainer, ManagerTable, DropdownContainer, ButtonContainer, CountContainer, DeleteAllContainer } from './HolidayManager.styled';
import { IHolidayEditable, IHolidaySubArea, IHolidayRegion, IHolidayYearSet } from '../../../Services/GlobalHolidaysService.types';
import { Stack, IDropdownOption, Dropdown } from '@fluentui/react';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { SingleHoliday, HolidayHeader } from './SingleHoliday';
import { DeletePanel } from './../Panels/DeletePanel';
import { AddPanel } from './../Panels/AddPanel';
import { EditPanel } from './../Panels/EditPanel';
import { BackPanel } from './../Panels/BackPanel';
import { UndoPanel } from './../Panels/UndoPanel';
import { SubmitPanel } from './../Panels/SubmitPanel';
import { DeleteCompanyCodePanel } from './../Panels/DeleteCompanyCodePanel';
import GlobalHolidaysService from '../../../Services/GlobalHolidaysService';
import { FaBullseye } from 'react-icons/fa';
import { EventType, UsageEventName, UserEvent } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';

interface HolidayManagerProps {
    region: IHolidayRegion,
    isNew: boolean,
    holidays: IHolidayYearSet[],
    holidayTypes: IDropdownOption[],
    subAreas: IHolidaySubArea[],
    globalHolidaysService : GlobalHolidaysService,
    onBack: () => void,
    displayMessage: (message: string, isError: boolean) => void
}

export const HolidayManager = (props: HolidayManagerProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [originalHolidays, setOriginalHolidays] = useState<Map<string, IHolidayEditable[]>>(new Map());
    const [updatedHolidays, setUpdatedHolidays] = useState<Map<string, IHolidayEditable[]>>(new Map());
    const [isModified, setIsModified] = useState<boolean>(false);
    const [currentHoliday, setCurrentHoliday] = useState<IHolidayEditable>();
    const [years, setYears] = useState<string[]>([]);
    const [currentYear, setCurrentYear] = useState<string>('');

    const [viewDeletePanel, setViewDeletePanel] = useState<boolean>(false);
    const [viewAddPanel, setViewAddPanel] = useState<boolean>(false);
    const [viewEditPanel, setViewEditPanel] = useState<boolean>(false);
    const [viewBackPanel, setViewBackPanel] = useState<boolean>(false);
    const [viewUndoPanel, setViewUndoPanel] = useState<boolean>(false);
    const [viewSubmitPanel, setViewSubmitPanel] = useState<boolean>(false);
    const [viewDeleteCompanyCodePanel, setViewDeleteCompanyCodePanel] = useState<boolean>(false);

    useEffect(() => {
        // On initial load
        // Map from year to holiday set, giving each a unique ID, and record years existing in data
        const yearToEditableHolidays = new Map<string, IHolidayEditable[]>();
        const existingYears: string[] = [];
        let id = 0;
        props.holidays.map((holidaySet) => {
            existingYears.push(holidaySet.Year);
            holidaySet.HolidaySet.map((holiday) => {
                if (!yearToEditableHolidays.has(holidaySet.Year)) {
                    yearToEditableHolidays.set(holidaySet.Year, []);
                }
                yearToEditableHolidays.get(holidaySet.Year).push({
                    id: id,
                    isModified: false,
                    isDeleted: false,
                    ...holiday,
                });
                id++;
            });
        });
        setOriginalHolidays(cloneDeep(yearToEditableHolidays));
        setUpdatedHolidays(cloneDeep(yearToEditableHolidays));
        setYears(existingYears);
        setCurrentYear(existingYears[existingYears.length - 1]);
    }, [props.holidays]);

    // Finds the original holiday for a given id, returns null if none exists
    const getOriginalHoliday = (id: number) : IHolidayEditable => {
        let original : IHolidayEditable = null;
        if (id != -1) {  // If id is -1, holiday is new and no original exists
            originalHolidays.forEach((holidaySet, year) => {
                const holidays = holidaySet.filter((holiday) => {return holiday.id === id;});
                if (holidays.length === 1) {
                    original = holidays[0];
                }
            });
        }
        return original;
    }

    // Called by edit and delete to check if new holidays list still contains unsubmitted changes
    const checkChanges = (holidays : Map<string, IHolidayEditable[]>) => {
        holidays.forEach((holidaySet, year) => {
            holidaySet.map((holiday) => {
                if (holiday.isModified || holiday.isDeleted) {
                    setIsModified(true);
                    return;
                }
            })
        })
    }

    // Updates map of holidays with new or updated holiday
    const updateHolidays = (newHoliday : IHolidayEditable, 
        holidays : Map<string, IHolidayEditable[]>) => {
        
        // Add updated holiday to updated holidays list
        const holidayYear = newHoliday.PublicHolidayStartDate.substring(0, 4);
        if (!holidays.has(holidayYear)) {
            // Add new year and holiday set
            holidays.set(holidayYear, []);
            setYears([...years, holidayYear].sort((a, b) => {
                return a < b ? -1 : a > b? 1 : 0;
            }));
            holidays.get(holidayYear).push(newHoliday);
        } else {
            // Add holiday to correct spot in sorted set
            let index = holidays.get(holidayYear).findIndex((holiday) => {
                return holiday.PublicHolidayStartDate > newHoliday.PublicHolidayStartDate;
            });
            if (index == -1) {
                // Add holiday to end
                holidays.get(holidayYear).push(newHoliday);
            } else {
                holidays.get(holidayYear).splice(index, 0, newHoliday);
            }
        }
        setCurrentYear(holidayYear);
        setUpdatedHolidays(holidays);
    }

    // Displays panel to delete holidays and handles deletion or cancelation
    const renderDeletePanel = () => {
        return (
            <>
                {
                    viewDeletePanel && (
                        <DeletePanel
                            holiday={currentHoliday}
                            region={props.region}
                            onConfirm={(deletedOriginal : IHolidayEditable) => {
                                // Remove holiday from updated holidays list
                                const update = new Map<string, IHolidayEditable[]>(updatedHolidays);
                                const index = updatedHolidays.get(currentYear).indexOf(currentHoliday);
                                const holidayYear = currentHoliday.PublicHolidayStartDate.substring(0, 4);
                                update.get(currentYear).splice(index, 1);

                                // Update holidays
                                if (deletedOriginal) {
                                    // When deleted holiday was original
                                    updateHolidays(deletedOriginal, update);
                                    setIsModified(true);
                                } else {
                                    // When deleted holiday was newly added
                                    setUpdatedHolidays(update);

                                    // Check new holidays list to see if any unsubmitted changes exist
                                    setIsModified(false);
                                    checkChanges(update);
                                }

                                // Check if holidays still exist for current year
                                if(update.get(holidayYear).length == 0) {
                                    update.delete(holidayYear);
                                    const newYears = years.filter((year) => {return year != holidayYear});
                                    setYears(newYears);
                                    setCurrentYear(newYears[newYears.length - 1]);
                                } else {
                                    setCurrentYear(holidayYear);
                                }

                                // Close panel and render sucess message
                                props.displayMessage(
                                    `${currentHoliday.PublicHolidayName} on ${currentHoliday.PublicHolidayStartDate.substring(0,10)} deleted.`, false
                                );
                                setViewDeletePanel(false);
                            }}
                            onCancel={() => {
                                setViewDeletePanel(false);
                            }}
                        />
                    )
                }
            </>
        );
    }

    // Displays panel to add holidays and handles addition or cancelation
    const renderAddPanel = () => {
        return (
            <>
                {
                    viewAddPanel && (
                        <AddPanel
                            region={props.region}
                            subAreas={props.subAreas.map((subArea) => {
                                return { key: subArea.PersonnelSubAreaCode, text: subArea.PersonnelSubAreaName }
                            })}
                            holidayTypes={props.holidayTypes}
                            onConfirm={(newHoliday : IHolidayEditable) => {
                                // Update holidays
                                const update = new Map<string, IHolidayEditable[]>(updatedHolidays);
                                updateHolidays(newHoliday, update);
                                setIsModified(true);

                                // Close panel and render sucess message
                                props.displayMessage(
                                    `${newHoliday.PublicHolidayName} on ${newHoliday.PublicHolidayStartDate.substring(0,10)} added.`, false
                                );
                                setViewAddPanel(false);
                            }}
                            onCancel={() => {
                                setViewAddPanel(false);
                            }}
                        />
                    )}
            </>
        )
    }

    // Displays panel to edit holidays and handles changes or cancelation
    const renderEditPanel = () => {
        return (
            <>
                {
                    viewEditPanel && (
                        <EditPanel
                            region={props.region}
                            holiday={currentHoliday}
                            originalHoliday={getOriginalHoliday(currentHoliday.id)}
                            subAreas={props.subAreas.map((subArea) => {
                                return { key: subArea.PersonnelSubAreaCode, text: subArea.PersonnelSubAreaName }
                            })}
                            holidayTypes={props.holidayTypes}
                            onConfirm={(updatedHoliday : IHolidayEditable) => {
                                // Remove old holiday from updated holidays list
                                const update = new Map<string, IHolidayEditable[]>(updatedHolidays);
                                const index = updatedHolidays.get(currentYear).indexOf(currentHoliday);
                                update.get(currentYear).splice(index, 1);

                                // Update Holidays
                                updateHolidays(updatedHoliday, update);

                                // Check if holidays still exist for current year
                                if(update.get(currentYear).length == 0) {
                                    update.delete(currentYear);
                                    const newYears = years.filter((year) => {return year != currentYear});
                                    setYears(newYears);
                                    setCurrentYear(newYears[newYears.length - 1]);
                                }

                                // Check new holidays list to see if any unsubmitted changes exist
                                setIsModified(false);
                                checkChanges(update);

                                // Close panel and render sucess message
                                props.displayMessage(
                                    `${updatedHoliday.PublicHolidayName} on ${updatedHoliday.PublicHolidayStartDate.substring(0,10)} updated.`, false
                                );
                                setViewEditPanel(false);
                            }}
                            onCancel={() => {
                                setViewEditPanel(false);
                            }}
                        />
                    )}
            </>
        )
    }

    // Displays panel to confirm if user wants to go back
    const renderBackPanel = () => {
        return (
            <>
                {
                    viewBackPanel && (
                        <BackPanel
                            onConfirm={() => {
                                setViewBackPanel(false);
                                props.onBack();
                            }}
                            onCancel={() => {
                                setViewBackPanel(false);
                            }}
                        />
                    )}
            </>
        )
    }

    // Displays panel to submit final changes
    const renderUndoPanel = () => {
        return (
            <>
                {
                    viewUndoPanel && (
                        <UndoPanel
                            onConfirm={() => {
                                // Reset holidays and years
                                setUpdatedHolidays(cloneDeep(originalHolidays));
                                const originalYears : string[] = [];
                                originalHolidays.forEach((holidaySet, year) => {
                                    originalYears.push(year);
                                });
                                setYears(originalYears);
                                setCurrentYear(originalYears.includes(currentYear) ? currentYear : originalYears[originalYears.length - 1]);
                                props.displayMessage('All changes reverted', false);
                                setIsModified(false);
                                setViewUndoPanel(false);
                            }}
                            onCancel={() => {
                                props.displayMessage('', false);  // Clears any lingering message
                                setViewUndoPanel(false);
                            }}
                        />
                    )}
            </>
        )
    }
    // Displays panel to submit final changes
    const renderSubmitPanel = () => {
        return (
            <>
                {
                    viewSubmitPanel && (
                        <SubmitPanel
                            region={props.region}
                            holidays={updatedHolidays}
                            globalHolidaysService={props.globalHolidaysService}
                            onCancel={() => {
                                setViewSubmitPanel(false);
                            }}
                            onClose={() => {
                                props.displayMessage('', false);  // Clears any lingering message
                                props.onBack();
                            }}
                        />
                )}
            </>
        )
    }

    const renderDeleteCompanyCodePanel = () => {
        return (
            <>
                {
                    viewDeleteCompanyCodePanel && (
                        <DeleteCompanyCodePanel
                            region={props.region}
                            globalHolidaysService={props.globalHolidaysService}
                            onBack={props.onBack}
                            onCancel={() => {setViewDeleteCompanyCodePanel(false);}}
                        />
                )}
            </>
        )
    }

    return (
        <>
            <SectionTitle style={{marginBottom: 10}}>
                {`${props.region.CountryName} (${props.region.CompanyCode})`}
            </SectionTitle>

            <div style={{marginBottom: 20}}>
                Unsubmitted changes appear as follows: New or updated holidays are bolded and highlighted with yellow. 
                Deleted holidays are italicized and highlighted with red. You must use the "Submit Changes" button 
                before leaving this page in order for them to be saved.
            </div>

            
            <Stack>
                <Stack.Item>
                    <DropdownContainer>
                        {
                            updatedHolidays.has(currentYear) && (
                                <Dropdown
                                    selectedKey={currentYear}
                                    onChange={ 
                                        (e, option) => {
                                            const event: UserEvent = {
                                                feature: 'GCH',
                                                subFeature: 'SelectYear',
                                                featureLocation: 'Admin',
                                                eventName: UsageEventName.DropdownSelected,
                                                type: EventType.User,
                                            };
                                            telemetryClient.trackEvent(event, {
                                                Year: option.text
                                            });
                                            setCurrentYear(option.key.toString());
                                        }
                                    }
                                    options={years.map((year) => {return {key: year, text: year}})}
                                    title='Year Dropdown'
                                    ariaLabel='Year Dropdown'
                                />
                            )
                        }
                    </DropdownContainer>

                    <ButtonContainer>
                        <DefaultButton
                            text='Go Back'
                            title='Go Back'
                            iconProps={{ ariaLabel: 'Back', iconName: 'Back' }}
                            usageEvent={{ feature: 'GHC', subFeature: 'BackToMenu', featureLocation: 'Admin' }}
                            style={{ marginRight: 10, marginBottom: 5 }}
                            onClick={() => {
                                props.displayMessage('', false);  // Clears any lingering message
                                isModified ? setViewBackPanel(true) : props.onBack();
                            }}
                        />

                        <DefaultButton
                            text='Undo All Changes'
                            title='Undo All Changes'
                            iconProps={isModified ? 
                                { ariaLabel: 'Undo', iconName: 'Undo' } :
                                { ariaLabel: 'Undo', iconName: 'Undo', style: {color: '#4A4A4A'} }
                            }
                            usageEvent={{ feature: 'GHC', subFeature: 'UndoAllChanges', featureLocation: 'Admin' }}
                            style={isModified ? 
                                { marginRight: 10, marginBottom: 10, color: '#e50000', borderColor: '#e50000' } : 
                                { marginRight: 10, marginBottom: 10, color: '#4A4A4A' }
                            }
                            disabled={!isModified}
                            onClick={() => {
                                setViewUndoPanel(true);
                            }}
                        />

                        <DefaultButton
                            text='Add a Holiday'
                            title='Add a Holiday'
                            iconProps={{ ariaLabel: 'Add', iconName: 'Add' }}
                            usageEvent={{ feature: 'GHC', subFeature: 'AddHoliday', featureLocation: 'Admin' }}
                            style={{ marginRight: 10, marginBottom: 10, color: '#0078d4', backgroundColor: 'white', borderColor: '#0078d4' }}
                            onClick={() => { setViewAddPanel(true); }}
                        />

                        <DefaultButton
                            text='Submit Changes'
                            title='Submit Changes'
                            iconProps={isModified ? 
                                { ariaLabel: 'CheckMark', iconName: 'CheckMark' } :
                                { ariaLabel: 'CheckMark', iconName: 'CheckMark', style: {color: '#4A4A4A'} }
                            }
                            usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays', featureLocation: 'Admin' }}
                            style={isModified ?
                                { marginRight: 10, marginBottom: 10, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' } :
                                { marginRight: 10, marginBottom: 10, color: '#4A4A4A' }    
                            }
                            disabled={!isModified}
                            onClick={() => { setViewSubmitPanel(true); }}
                        />
                    </ButtonContainer>
                </Stack.Item>

                {
                    // Display holidays table
                    updatedHolidays.has(currentYear) && updatedHolidays.get(currentYear).length && (
                        <TableContainer>
                            <ManagerTable>
                                <HolidayHeader />
                                {updatedHolidays.get(currentYear).map((holiday: IHolidayEditable, index : number) => {
                                return (
                                    <SingleHoliday
                                        holiday={holiday}
                                        onEdit={() => {
                                            setCurrentHoliday(holiday);
                                            setViewEditPanel(true);
                                        }}
                                        onDelete={() => {
                                            setCurrentHoliday(holiday);
                                            setViewDeletePanel(true);
                                        }}
                                    />
                                )})}
                            </ManagerTable>
                        </TableContainer>
                    )
                }

                {
                    // Table's footnotes
                }
                <Stack.Item>
                    <DeleteAllContainer>
                        {
                            !props.isNew && (
                                <DefaultButton
                                    text='Delete Company Code and All Holidays'
                                    title='Delete Company Code and All Holidays'
                                    iconProps={{ ariaLabel: 'Trash', iconName: 'Trash' }}
                                    usageEvent={{ feature: 'GHC', subFeature: 'DeleteCompanyCode', featureLocation: 'Admin' }}
                                    style={{ height: 'auto', minHeight: 30, marginRight: 10, padding: 5,
                                        marginBottom: 5, color: 'white', backgroundColor: '#e50000', borderColor: '#e50000' }}
                                    onClick={() => { setViewDeleteCompanyCodePanel(true); }}
                                />
                            )
                        }
                    </DeleteAllContainer>
                    <CountContainer>
                        Count: {updatedHolidays.has(currentYear) ? updatedHolidays.get(currentYear).length : '0'} holidays
                    </CountContainer>
                </Stack.Item>
            </Stack>

            {renderDeletePanel()}
            {renderAddPanel()}
            {renderEditPanel()}
            {renderBackPanel()}
            {renderUndoPanel()}
            {renderSubmitPanel()}
            {renderDeleteCompanyCodePanel()}
        </>
    );
}
