import { AuthClient } from '@micro-frontend-react/employee-experience/lib/AuthClient';
import { GraphClient } from '@micro-frontend-react/employee-experience/lib/GraphClient';
import { HttpClient } from '@micro-frontend-react/employee-experience/lib/HttpClient';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';
import { TelemetryClient } from '@micro-frontend-react/employee-experience/lib/TelemetryClient';
import { ReducerRegistry } from '@micro-frontend-react/employee-experience/lib/ReducerRegistry';
import { Shell } from '@micro-frontend-react/employee-experience/lib/Shell';
import { StoreBuilder } from '@micro-frontend-react/employee-experience/lib/StoreBuilder';
import { withStore } from '@micro-frontend-react/employee-experience/lib/withStore';

const telemetryClient : ITelemetryClient = new TelemetryClient({
    instrumentationKey: __INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: __IS_DEVELOPMENT__ ? 'Non-Production' : 'Production',
        ServiceOffering: 'Human Resources',
        ServiceLine: 'HR Experiences',
        Service: 'Global Country Holidays',
        ComponentName: 'Global Country Holidays',
        ComponentId: 'ecbba339-c859-49fa-b2a8-fb0e1391777f',
    },
    defaultProperties: {
        appName: __APP_NAME__,
    },
});
const authClient = new AuthClient(
    {
        auth: {
            clientId: __CLIENT_ID__,
            redirectUri: window.location.origin,
            authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47',
            // authority: 'https://login.microsoftonline.com/common',
        },
    },
    telemetryClient
);

const httpClient = new HttpClient(telemetryClient, authClient);
const graphClient = new GraphClient(httpClient);

const reducerRegistry = new ReducerRegistry();
const appName = 'GCH Web';
const storeResult = new StoreBuilder(reducerRegistry, {})
    .configureLogger(__IS_DEVELOPMENT__)
    .configureSaga({telemetryClient, authClient, httpClient, graphClient, appName})
    .build();

export const ShellWithStore = withStore(storeResult)(Shell);
