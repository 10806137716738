import * as React from 'react';
import { useState, useEffect } from 'react';
import {
    ComboBox,
    IComboBox,
    IComboBoxOption
} from '@fluentui/react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DropdownContainer } from './HolidayOptionsMenu.styled';

export interface ICompanyCodeSelector {
    onChange: (companyCode: string) => void,
    options: IComboBoxOption[],
    placeholderOption : IComboBoxOption
}

// Component for selecting company code from a searchable drop down
export const CompanyCodeSelector = (props: ICompanyCodeSelector) => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [selectedKey, setSelectedKey] = useState<string | number | undefined>(props.placeholderOption.key);
    const [selectedData, setSelectedData] = useState<string>(props.placeholderOption.text)
    //const [selectedOption, setSelectedOption] = useState<IComboBoxOption>(props.placeholderOption);
    const [allOptions, setAllOptions] = useState<IComboBoxOption[]>();
    const [filteredOptions, setFilteredOptions] = useState<IComboBoxOption[]>();

    const handleDropDownChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        if (event.type !== "blur") {
            const newKey: string = option.key.toString();
            const newData: string = option.text.toString();
            setSelectedData(newData);
            props.onChange(newKey);
        } else {
            setSelectedKey(selectedKey);
            setSelectedData(selectedData);
            setFilteredOptions(allOptions);
        }
    };

    const handleUserChange = (option?: IComboBoxOption, index?: number, value?: string) => {
        if (!value || option) {

            if (value === "") {
                setFilteredOptions(allOptions);
            } else if (value === " ") {

            }
            return;
        }

        if (value === "") {
            setFilteredOptions(allOptions);
        } else {
            var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (format.test(value)) {
                value = "";
            }

            const filteredCodes: IComboBoxOption[] = allOptions.filter((comboBoxOption: IComboBoxOption) => {
                return value ? comboBoxOption.text.search(value) > -1 : false;
            });
            setFilteredOptions(filteredCodes);
        }
    };

    useEffect(() => {
        setAllOptions([...props.options]);
        setFilteredOptions([...props.options]);
    }, [props.options]);

    return (
        <div>
            <DropdownContainer>
                <ComboBox
                    ariaLabel={props.placeholderOption.text}
                    placeholder={props.placeholderOption.text}
                    selectedKey={selectedKey}
                    scrollSelectedToTop={true}
                    allowFreeform={true}
                    autoComplete='on'
                    openOnKeyboardFocus={true}
                    options={filteredOptions}
                    onChange={handleDropDownChange}
                    text={selectedData}
                    onPendingValueChanged={handleUserChange}
                    calloutProps={{calloutMaxHeight: 300, directionalHintFixed: false}}
                    useComboBoxAsMenuWidth={true}
                />
            </DropdownContainer>
        </div>
    );
};

export default CompanyCodeSelector;
