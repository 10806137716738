import {
    IHolidayCompany,
    IHolidayCountry,
    IHoliday,
    IHolidayRegion,
    IHolidaySubArea,
    IPerson,
    IHolidayDomainData,
    IMsGraphUserInfo,
} from './GlobalHolidaysService.types';
import { IHttpClient } from '@micro-frontend-react/employee-experience';
import ServiceInterface from './ServiceInterface';
import { ICalendarEvent, Meeting } from '../State/Calendar';
import { SCOPES } from './ServiceUtils';

const requestDefaults = {
    resource: `${__HR_RESOURCE_URI__}`,
};

export interface IODataResponse<T> {
    "@odata.context": string,
    "value": T[]
}

// TODO: change time zone ltaer on when there is more guidance from business.

export default class CalendarService implements ServiceInterface {
    _httpClient: IHttpClient;
    _timeZone = "Pacific Standard Time";
    _baseUrl = "https://graph.microsoft.com";
    _scopes = SCOPES;

    constructor(httpClient: IHttpClient) {
        this._httpClient = httpClient;
    }

    public getMsGraphUserInfo = async (): Promise<any> => {
        const res = await Promise.resolve(
            this._httpClient.request({
                url: 'https://graph.microsoft.com/v1.0/me',
                resource: this._scopes,
            })
        );
        return res.data as IMsGraphUserInfo;
    };

    public getUserEvents = async (startDateTime : string, endDateTime: string): Promise<IODataResponse<ICalendarEvent>> => {
        const filter = `(responseRequested eq true) and (isCancelled eq false) and (showAs ne 'free')`;
        const maxLength = 999;
        const selectParams = "organizer,subject,responseRequested";
        const path = `v1.0/me/calendar/calendarView?startDateTime=${startDateTime}&endDateTime=${endDateTime}&$filter=${filter}&$top=${maxLength}&select=${selectParams}`;

        const { data } = await Promise.resolve(
            this._httpClient.request({
                headers: {
                    Prefer: `outlook.timezone="${this._timeZone}"`,
                },
                url: `${this._baseUrl}/${path}`,
                resource: this._scopes,
            })
        );
        return data;
    };

    public cancelEvent = async (id : string, comment?: string) : Promise<IODataResponse<any>> => {
        let body = {};
        if (comment) {
            Object.assign(body, {
                comment: comment
            })
        }

        const path = `v1.0/me/events/${id}/cancel`;

        const { data } = await Promise.resolve(
            this._httpClient.request({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: body,
                url: `${this._baseUrl}/${path}`,
                resource: this._scopes
            })
        );
        return data;
    };

    public replyTentative = async (id : string, comment?: string) : Promise<IODataResponse<any>> => {
        let body = {};
        if (comment) {
            Object.assign(body, {
                comment: comment,
                sendResponse: true
            })
        }

        const path = `v1.0/me/events/${id}/tentativelyAccept`;

        const { data } = await Promise.resolve(
            this._httpClient.request({
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: body,
                url: `${this._baseUrl}/${path}`,
                resource: this._scopes
            })
        );
        return data;
    };

    public blockCalendar = async (calendarEvent : Meeting) : Promise<IODataResponse<any>> => {
        
        const path = "v1.0/me/calendar/events";

        const { data } = await Promise.resolve(
            this._httpClient.request({
                method: 'POST',
                headers: {
                    Prefer: `outlook.timezone="${this._timeZone}"`,
                },
                data: calendarEvent,
                url: `${this._baseUrl}/${path}`,
                resource: this._scopes,
            })
        );
        return data;
    };

}
