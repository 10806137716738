import { Spinner as OfficeSpinner } from '@fluentui/react';

export const UpdateContent = styled.div`
    margin-top: 1.5rem;
`;

export const TaskRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 2rem;
    margin-bottom: 1rem;
`;

export const Spinner = styled(OfficeSpinner)<any>`
    & > .ms-Spinner-circle {
        border-width: 3px;
        height: ${props => props.size}px;
        width: ${props => props.size}px;
    }
`
export const StatusText = styled.p`
    margin: 0;
    margin-left: auto;
    text-align: right;
    padding-left: 1rem;
`;

export const LowerContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const WarningText = styled.p`
    margin: 10px 0;    
    color: grey;
    font-size: 14px;
    text-align: center;
    line-height: 16px;
`;