import * as React from 'react';
import { CoherenceNav, INavLinkGroup } from '@coherence-design-system/controls/lib/nav';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { UserEvent, EventType, UsageEventName } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { useCoherenceNavGroups } from './useCoherenceNavGroups';

import { useState, useEffect } from 'react';
import { IAdminPermissions } from '../../Services/AdminService';
import AdminService from '../../Services/AdminService';

export interface INavItem {
    key: string;
    name: string;
    href: string;
    icon: string;
    bundleLocation: string;
    componentName: string;
}

export default function Nav(): React.ReactElement {
    
    // Get and check admin permissions
    const { httpClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const adminService = new AdminService(httpClient);

    const [allowedAdmin, setAllowedAdmin] = useState<boolean>(false);

    useEffect(() => {
        adminService.getPermissions()
            .then((results) => {setAllowedAdmin(results.Admin)})
            .catch((err) => {console.error("err: ", err); setAllowedAdmin(false);});
    }, []);

    const homeItem: INavItem = {
        key: 'Holidays',
        name: 'Holidays',
        href: '/',
        icon: 'Home',
        bundleLocation: 'Holidays',
        componentName: 'Holidays',
    };
    const hrWeb: INavItem = {
        key: 'HRWeb',
        name: 'HRWeb',
        href: `${__URL_HRWEB__}`,
        icon: 'HRWeb',
        bundleLocation: '',
        componentName: 'HRWeb',
    };

    // Admin Page
    const admin: INavItem = {
        key: 'Admin',
        name: 'Admin',
        href: `/admin`,
        icon: 'Admin',
        bundleLocation: '',
        componentName: 'Admin',
    };

    const navLink: INavLinkGroup = {
        key: 'main',
        links: allowedAdmin ? [homeItem, hrWeb, admin] : [homeItem, hrWeb]
    };
    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const onToggle = (isOpen: boolean) => {
        // commenting out the below code as the below CoherenceNav is calling this method recurrently and causing the page to hang 

        // TODO: Investigate why this method is being called recurrently. #12465032 
    };

    const navGroup: INavLinkGroup[] = [navLink];
    const coherenceNavGroups = useCoherenceNavGroups(navGroup);

    return (
        <CoherenceNav
            groups={coherenceNavGroups}
            onNavCollapsed={(isOpen: boolean) => onToggle(isOpen)}
            defaultIsNavCollapsed={true}
            telemetryHook={telemetryClient}
        />
    );
}
