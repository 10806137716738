import { IHttpClient } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import { IHolidayRegion } from './GlobalHolidaysService.types';
import ServiceInterface from './ServiceInterface';

const requestDefaults = {
    resource: `${__HR_RESOURCE_URI__}`,
};

interface ICountryInfo {
    CountryCode: string;
    CountryName: string;
}

interface ICountryToCompany {
    CountryCode: string;
    CompanyCode: string;
}

export default class CompanyCodeService implements ServiceInterface {
    _httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this._httpClient = httpClient;
    }

    // Gets company codes whose holidays are managed by MsVacation
    public getMsVacationCompanyCodes = async (): Promise<IHolidayRegion[]> => {
        let companyCodes: ICountryToCompany[] = [];
        let countryNames: ICountryInfo[] = [];
        try {
            // Get company codes with country codes
            companyCodes = await this._httpClient
                .request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/domaindata/msvacation/companyCodes`
                })
                .then((result) => result.data as ICountryToCompany[]);

            // Get country names with country codes
            countryNames = await this._httpClient
                .request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/domaindata/countries`,
                })
                .then((result) => result.data as ICountryInfo[]);

        } catch (err) {
            console.error(err);
            return [] as IHolidayRegion[];
        }

        // Merge the data to produce country codes with display names
        let results: IHolidayRegion[] = [];
        companyCodes.map((company) => {

            // Get the country name for a given company code
            const country = countryNames.find((item) => {
                if (item.CountryCode === company.CountryCode) {
                    return item;
                }
            });

            // Add code
            results.push({
                CompanyCode: company.CompanyCode,
                CountryCode: country.CountryCode,
                CountryName: country.CountryName,
                SubAreaCode: null,
                SubAreaName: null
            })
        })

        return results;
    }

    // Gets company codes whose holidays are not managed by MsVacation
    public getNonMsVacationCompanyCodes = async (): Promise<IHolidayRegion[]> => {
        let companyCodes: ICountryToCompany[] = [];
        let countryNames: ICountryInfo[] = [];
        try {
            // Get company codes with country codes
            companyCodes = await this._httpClient
                .request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/domaindata/nonmsvacation/companyCodes`
                })
                .then((result) => result.data as ICountryToCompany[]);

            // Get country names with country codes
            countryNames = await this._httpClient
                .request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/domaindata/countries`,
                })
                .then((result) => result.data as ICountryInfo[]);

        } catch (err) {
            console.error(err);
            return [] as IHolidayRegion[];
        }

        // Merge the data to produce country codes with display names
        let results: IHolidayRegion[] = [];
        companyCodes.map((company) => {

            // Get the country name for a given company code
            const country = countryNames.find((item) => {
                if (item.CountryCode === company.CountryCode) {
                    return item;
                }
            });

            // Add code
            results.push({
                CompanyCode: company.CompanyCode,
                CountryCode: country.CountryCode,
                CountryName: country.CountryName,
                SubAreaCode: null,
                SubAreaName: null
            })
        })

        return results;
    }
}