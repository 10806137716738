import * as React from 'react';
import { Header } from './Components/Header/Header';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Footer } from './GlobalHolidays/Footer/Footer';
import { AppContext, reducer, initialState } from './StateManagement';
import { HelpContext, IHelpContext } from './Shared/HelpContext';
import Nav from './GlobalHolidays/Nav/Nav';
import { initializeCustomIcons } from './Shared/initializeCustomIcons';
import { useLoginOnStartup } from '@micro-frontend-react/employee-experience/lib/useLoginOnStartup';
import { Main } from './Components/Main';
import { CoherenceThemeProvider } from './Components/CoherenceThemeProvider';
import { ShellWithStore } from './ShellWithStore';
import { navConfig } from './navConfig';
import { Routes } from './Routes';
import { useHeaderConfig } from './useHeaderConfig';
import { initializeCentro } from '@coherence-design-system/controls';

initializeCustomIcons();

// The locale of the HVC to be loaded, in BCP-47 format
const language: string = 'en';

function App(): React.ReactElement {
    useLoginOnStartup();
    // const headerConfig = useHeaderConfig();
    const [store, dispatch] = React.useReducer(reducer, initialState);

    // help panel context
    const toggleShowHelpContextPanel = (valueSuccess: boolean) => {
        _setHelpMetadataContext({ ..._helpMetadataContext, showHelpContextPanel: valueSuccess });
    };
    const initState: IHelpContext = {
        showHelpContextPanel: false,
        toggleShowHelpContextPanel: toggleShowHelpContextPanel,
    };
    const [_helpMetadataContext, _setHelpMetadataContext] = React.useState<IHelpContext>(initState);

    React.useEffect(() => {
        initializeCentro(__OCV_APP_NAME__, language, __OCV_APP_ID__);
    }, []);

    return (
        <AppContext.Provider value={{ store, dispatch }}>
            <HelpContext.Provider value={_helpMetadataContext}>
                <CoherenceThemeProvider>
                    <BrowserRouter>
                        <Header />
                        <Nav />
                        <Main tabIndex={-1}>
                            <Routes />
                        </Main>
                        <Footer />
                    </BrowserRouter>
                </CoherenceThemeProvider>
            </HelpContext.Provider>
        </AppContext.Provider>
    );
}

render(
    <ShellWithStore>
        <App />
    </ShellWithStore>,
    document.getElementById('app')
);
