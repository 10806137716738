import { ReactElement, useState } from 'react';
import * as React from 'react';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import GlobalHolidaysService from '../../../Services/GlobalHolidaysService';
import { IHolidayRegion, IHolidayResponse } from '../../../Services/GlobalHolidaysService.types';


interface DeleteCompanyCodePanelProps {
    region: IHolidayRegion,
    globalHolidaysService: GlobalHolidaysService,
    onBack: () => void,
    onCancel: () => void,
}

// Fly out panel to confirm user wants to undo all changes
export const DeleteCompanyCodePanel = (props: DeleteCompanyCodePanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [deleted, setDeleted] = useState<boolean>(false);
    const [response, setResponse] = useState<IHolidayResponse>({Success: false});

    // Handles deletion in database
    const onSubmit = () => {
        // Submit holidays and get response
        props.globalHolidaysService.deleteCompanyCode(
            props.region.CompanyCode,
            props.region.CountryCode)
            .then((results) => {setResponse(results); setDeleted(true);})
            .catch((err) => {setDeleted(true); console.error("err: ", err)});
    }

    return (
        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Confirmation'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >
            {
                !deleted && (
                    <p>
                        This action will delete this company code and any associated holidays from managed storage. 
                        It will not effect any holidays in Microsoft Vacation. Are you sure you would like to do this?
                    </p>
                )
            }

            {
                deleted && response.Success && (
                    <p>
                        Deletion successful! Changes may not be visible in the main holidays app for up 
                        to {response.CacheRefreshTimeInHours} hours, but are available in the admin portal.
                        To make them immediately visible in the main holidays app, go to admin home page 
                        and use the "Sync Holiday Data" button.
                    </p>
                )
            }

            {
                deleted && !response.Success && (
                    <p>
                        Something went wrong, please try again soon.
                    </p>
                )
            }

            <DefaultButton
                text={deleted ? 'Close' : 'Go Back'}
                title={deleted ? 'Close' : 'Go Back'}
                usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays.Back', featureLocation: 'Admin' }}
                style={{ marginRight: 20, marginTop: 20 }}
                onClick={deleted ? props.onBack : props.onCancel}
            />

            {
                !deleted && (
                    <DefaultButton
                        text='Confirm'
                        title='Confirm'
                        usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays.Confirm', featureLocation: 'Admin' }}     
                        style={{ marginTop: 20, color: 'white', backgroundColor: '#e50000', borderColor: '#e50000' }}
                        onClick={onSubmit}
                    />
                )
            }
        </CoherencePanel>
    );
}