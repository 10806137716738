import { getClickFeature, getFeature } from '@micro-frontend-react/employee-experience/lib/UsageTelemetryHelper';
import { Link } from '@micro-frontend-react/employee-experience/lib/Link';
import * as React from 'react';
import { useState } from 'react';
import { HelpContext } from '../../Shared/HelpContext';
import * as Styled from './Help.styled';
import { HelpPanel } from './HelpPanel';
import { AppContext } from '../../StateManagement';
import { FaChevronRight } from 'react-icons/fa'; 

export interface IKeyLink {
    text: string;
    url: string;
};

export interface IFaqDataEntry {
    question: string;
    answer: string[];
}

export function HelpPanelBase(): React.ReactElement {
    // TODO: for prod, add this mapping data to be pulled from the database
    let keyLinkData : IKeyLink[] = [
        {
            text: "Learn about vacation, leave of absence, and time off",
            url: __URL_HRWEB_VACATION__
        },
        {
            text: "Visit MS Vacation",
            url: __URL_MS_VACATION__
        },
        {
            text: "Use the Thrive Holidays App",
            url: __URL_THRIVE_HOLIDAYS__
        },
    ]

    const USOnlyTar: IKeyLink = 
    {
        text: "Visit TAR",
        url: __URL_TAR__
    };

    const faqData : IFaqDataEntry[] = [
        {
            question: "How can I find the holidays for my location?",
            answer: [
                `<span>Open <a href="${window.location.origin}">Holidays</a><span>`,
                "From the selector at the top, ensure the country and company code reflect your location – adjust if needed"
            ]
        },
        {
            question: "Where are the holidays for next year?",
            answer: [
                "As soon as the holidays for the upcoming year are available in the system, you can access them by picking the respective year from the selection at the top."
            ]
        },
        {
            question: "Can I add the dates to my calendar?",
            answer: [
                "Next to the selected year, click “Add all to calendar” to download all dates for the year.",
                "Click “Add to calendar” next to one of the holidays to download each date individually."
            ]
        },
    ]

    const { store } = React.useContext(AppContext);
    const feature = getFeature('GCH', 'GCH');
    const [selectedFaqEntry, setSelectedFaqEntry] = useState<IFaqDataEntry>({
        question: "",
        answer: []
    });

    // show TAR instead of MSVacation for US Employees
    let isUsEmployee = store.userInfo && store.userInfo.CountryName == "United States";
    if (isUsEmployee) {
        keyLinkData[1] = USOnlyTar;
    }

    return (
        <>
            <HelpContext.Consumer>
                {(context) => (
                    <>
                        <div
                            style={{
                                marginTop: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                flexFlow: 'wrap',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                textAlign: 'left',
                                height: 'auto',
                            }}
                        >
                            <Styled.ContentSection>
                                <Styled.Header>Key links</Styled.Header>
                                {keyLinkData.map((link, i) => 


                                    <Styled.Link
                                        to={link.url}
                                        title={link.text + " (Opens in a new tab)"}
                                        target="_blank"
                                        usageEvent={{
                                            feature: '',
                                            subFeature: '',
                                            subFeatureLevel2: '',
                                            featureLocation: '',
                                        }}
                                    >
                                        <FaChevronRight style={{marginRight: "1rem"}}/>{link.text}
                                    </Styled.Link>
                                )}
                            </Styled.ContentSection>

                            <Styled.ContentSection>
                                <Styled.Header>Frequently asked questions</Styled.Header>
                                    {faqData.map((faqEntry, i) => 
                                        <Styled.QuestionLink
                                        title={faqEntry.question}
                                        ariaLabel={faqEntry.question}
                                        onClick={() => {
                                            setSelectedFaqEntry(faqEntry);
                                            context.toggleShowHelpContextPanel(true);
                                        }}
                                        {...getClickFeature(feature, 'SeeAllPositionsLink')}
                                    >
                                        <FaChevronRight style={{marginRight: "1rem"}}/>{faqEntry.question}
                                    </Styled.QuestionLink>
                                )}
                            </Styled.ContentSection>
                        </div>
                        <Styled.ContentSection>
                            <Styled.Header>Need help?</Styled.Header>
                            <Styled.Link
                                to={__URL_ASKHR_SUPPORT__}
                                title="Contact AskHR Link (Opens in a new tab)"
                                target="_blank"
                                usageEvent={{
                                    feature: '',
                                    subFeature: '',
                                    subFeatureLevel2: '',
                                    featureLocation: '',
                                }}
                            >
                                <FaChevronRight style={{marginRight: "1rem"}}/>
                                Contact AskHR
                            </Styled.Link>
                        </Styled.ContentSection>
                        <HelpPanel question={selectedFaqEntry.question} answer={selectedFaqEntry.answer} />
                    </>
                )}
            </HelpContext.Consumer>
        </>
    );
}
