import * as React from 'react';
import CalendarService, { IODataResponse } from '../Services/CalendarService';
import { IHoliday, IMsGraphUserInfo } from '../Services/GlobalHolidaysService.types';
import * as moment from 'moment';
import { ICalendar } from '@fluentui/react';
import { IAction, IStore } from '../StateManagement';
import { cloneDeep } from 'lodash';
import { SystemEvent, EventType } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { ITelemetryClient } from '@micro-frontend-react/employee-experience/lib/ITelemetryClient';
import { v4 as uuidv4 } from 'uuid';

export interface ICalendarStore {
    tasks: ICalendarTasks
}

interface ICalendarTasks {
    'GET_EVENTS': ITask,
    'CANCEL_MEETINGS': ITask,
    'REPLY_TENTATIVE': ITask,
    'BLOCK_CALENDAR': ITask
}

export enum Tasks {
    GET_EVENTS = "GET_EVENTS",
    CANCEL_MEETINGS = "CANCEL_MEETINGS",
    REPLY_TENTATIVE = "REPLY_TENTATIVE",
    BLOCK_CALENDAR = "BLOCK_CALENDAR",
}

export interface ICalendarPayload {
    actions: {
        isBlockCalendar: boolean;
        isReplyTentative: boolean;
        isCancelMeetings: boolean;        
    },
    holidays: IHoliday[]
}
export interface ICalendarAction { 
    type: string,
    payload: any
}

export enum Status {
    NotSelected,
    NotStarted,
    Loading,
    Complete, 
    Error
}

export interface ITask {
    'name': Tasks,
    'text': string,
    'status': Status, 
    'err': any
}

interface ITaskModifier {
    'name': Tasks,
    'text'?: string,
    'status'?: Status, 
    'err'?: any
}

export interface ICalendarEvent {
    id: string,
    organizer: {
        emailAddress: {
            address: string,
            name: string
        }
    },
    responseRequested: boolean,
    subject: string
}

export type GraphEmail = {
    address: string;
    name: string;
};

export type Attendee = {
    emailAddress: GraphEmail;
    status: {
        response: "none" | "organizer" | "tentativelyAccepted" | "accepted" | "declined" | "notResponded";
        time: string;
    };
    type: "required" | "optional" | "resource";
};

export type DateTimeTimeZone = {
    dateTime: string;
    timeZone: string;
};

export enum ShowAsValues {
    Busy = "busy",
    Free = "free",
    Oof = "oof",
    Tentative = "tentative",
    Unknown = "unknown",
    WorkingElsewhere = "workingElsewhere"
}

export type Meeting = {
    attendees?: Attendee[];
    body?: {
        content: string;
        contentType: "text" | "html";
    };
    end?: DateTimeTimeZone;
    id?: string;
    isAllDay?: boolean;
    organizer?: {
        emailAddress: GraphEmail;
    };
    responseRequested?: boolean;
    showAs: ShowAsValues;
    start?: DateTimeTimeZone;
    subject: string;
};

export const InitialTasks : ICalendarTasks = {
    'GET_EVENTS': {
        name: Tasks.GET_EVENTS,
        text: 'Getting your events...',
        status: Status.NotSelected,
        err: null
    },
    'CANCEL_MEETINGS': {
        name: Tasks.CANCEL_MEETINGS,
        text: 'Cancelling your meetings...',
        status: Status.NotSelected,
        err: null
    },
    'REPLY_TENTATIVE': {
        name: Tasks.REPLY_TENTATIVE,
        text: 'Replying "Tentative" to other meetings...',
        status: Status.NotSelected,
        err: null
    },
    'BLOCK_CALENDAR': {
        name: Tasks.BLOCK_CALENDAR,
        text: 'Blocking off calendar...',
        status: Status.NotSelected,
        err: null
    }
}

export const initialState : ICalendarStore = {
    tasks: InitialTasks
}

export const CalendarContext = React.createContext(null);

export class CalendarManager {
    private calendarService;
    private calendarStore;
    private calendarDispatch;
    private appStore;
    private appDispatch;
    private telemetryClient : ITelemetryClient;

    timeZone = "Pacific Standard Time";
    dateFormat = "YYYY-MM-DD";

    numRetries = 2;
    codeTooManyRequests = 429;

    constructor(calendarService : CalendarService,
        calendarStore : ICalendarStore,
        calendarDispatch : React.Dispatch<ICalendarAction>,
        appStore : IStore,
        appDispatch : React.Dispatch<IAction>,
        telemetryClient : ITelemetryClient) {
        this.calendarService = calendarService;
        this.calendarStore = calendarStore;
        this.calendarDispatch = calendarDispatch;
        this.appStore = appStore;
        this.appDispatch = appDispatch;
        this.telemetryClient = telemetryClient;
    }

    /**
     * Parses actions in Calendar Payload to modify user calendar
     * @param payload payload data used to perform actions
     */
    public async manageCalendar(payload : ICalendarPayload) : Promise<boolean> {
        // reset the store to manage the tasks, for each 
        this.setInitialState(payload);

        let events;
        // check if events are required to be fetched
        if (payload.actions.isReplyTentative || payload.actions.isCancelMeetings) {
            events = await this.getEvents(payload.holidays);
        }

        let isSuccess = true;

        if(payload.actions.isCancelMeetings) {
            isSuccess = isSuccess && await this.cancelMeetings(events);
        }

        if(payload.actions.isReplyTentative) {
            isSuccess = isSuccess && await this.replyTentative(events);
        }

        if(payload.actions.isBlockCalendar) {
            isSuccess = isSuccess && await this.blockCalendar(payload.holidays, ShowAsValues.Oof);
        }

        return isSuccess;
    }

    private async setInitialState(payload : ICalendarPayload) {
        
        const tasks = cloneDeep(InitialTasks);

        if (payload.actions.isBlockCalendar) {
            tasks.BLOCK_CALENDAR.status = Status.NotStarted;
        }
        if (payload.actions.isCancelMeetings) {
            tasks.GET_EVENTS.status = Status.NotStarted;
            tasks.CANCEL_MEETINGS.status = Status.NotStarted;
        }
        if(payload.actions.isReplyTentative) {
            tasks.GET_EVENTS.status = Status.NotStarted;
            tasks.REPLY_TENTATIVE.status = Status.NotStarted;
        }

        let newStore = {tasks};

        this.calendarDispatch(updateStore(newStore));
    }
    
    private async getEvents(holidays : IHoliday[]) : Promise<ICalendarEvent[]> {

        //  update event status in the store
        this.calendarDispatch(updateTaskStatus(Tasks.GET_EVENTS, Status.Loading));

        // aggregate the events for all days within the days of the holiday
        try {
            let getEventsPromiseFns = [];

            for (const holiday of holidays) {
                const holidayStartAndEnd = getHolidayStartAndEnd(holiday);
                // TODO: test if meetings that start on 12 am the next day are included or excluded?
                getEventsPromiseFns.push(
                    async () => {
                        return (await this.calendarService.getUserEvents(holidayStartAndEnd.start, holidayStartAndEnd.end)) as IODataResponse<any>
                    }
                );
            }
            
            let startTime = new Date().getTime(); // milliseconds
            const allEventsOData = await this.executeCalendarActions(getEventsPromiseFns, "Failed to fetch events, after 2 retries");
            let endTime = new Date().getTime(); 
            let timeTaken = endTime - startTime;

            let events : ICalendarEvent[] = [];
            for(const day of allEventsOData) {
                events = [...events, ...day.value]
            }
            this.calendarDispatch(updateTaskStatus(Tasks.GET_EVENTS, Status.Complete));

            const event: SystemEvent = {
                eventName: 'FetchedEvents',
                feature: 'GCH',
                subFeature: 'FetchEvents',
                featureLocation: 'CalendarUpdatePanel',
                timeTaken: timeTaken,
                type: EventType.System,
                businessTransactionId: uuidv4(),
            };

            const customProperties = {
                numHolidays: holidays.length.toString(),
                numEvents: events.length.toString(),
            };

            this.telemetryClient.trackEvent(event, customProperties);

            return events;
        } catch (err) {
            this.calendarDispatch(updateTask({
                name: Tasks.GET_EVENTS,
                text: "We can’t get your events to update your calendar. Please go to your calendar to make changes.",
                status: Status.Error,
                err: err
            }))
            console.error("caught err: ", err);
            // TODO: ADD EXCEPTION LOGGING HERE FOR UAT DEPLOYMENT
            this.telemetryClient.trackException({
                exception: new Error("Failed to get events."),
            }, {
                error: JSON.stringify(err)
            });
        }
    }

    // for each of the ui actions, there are multiple events, that are triggered, check if the event has already been triggerred and what the set value is
    /**
     * Cancel all events / meetings that are on the holiday, based on the startDate and endDate in PST time
     * @param holidays 
     */
    private async cancelMeetings(meetings : ICalendarEvent[]) : Promise<boolean> {

        this.calendarDispatch(updateTaskStatus(Tasks.CANCEL_MEETINGS, Status.Loading));

        try {
            let userInfo : IMsGraphUserInfo = await this.calendarService.getMsGraphUserInfo();
    
            const meetingsToCancel = meetings.filter(
                meeting => 
                    meeting.organizer?.emailAddress?.address.toLowerCase() === userInfo.userPrincipalName.toLowerCase() || 
                    meeting.organizer?.emailAddress?.address.toLowerCase() === userInfo.mail.toLowerCase()
            );
    
            let cancelPromiseFns = [];
    
            for (const meeting of meetingsToCancel) {
                // TODO: change cancel message to match MyHub
                cancelPromiseFns.push(
                    async () => {
                        return (await this.calendarService.cancelEvent(meeting.id, "Cancelling meeting due to holiday."))
                    }
                );
            }

            let startTime = new Date().getTime(); // milliseconds
            // iterate through all the cancelled meetings, if the failure message is 429, retry 3 times
            await this.executeCalendarActions(cancelPromiseFns, `Failed to cancel meeting after ${this.numRetries} tries.`);
            let endTime = new Date().getTime();
            let timeTaken = endTime - startTime;

            this.calendarDispatch(updateTaskStatus(Tasks.CANCEL_MEETINGS, Status.Complete));

            const event: SystemEvent = {
                eventName: 'CancelledMeetings',
                feature: 'GCH',
                subFeature: 'CancelMeetings',
                featureLocation: 'CalendarUpdatePanel',
                timeTaken: timeTaken,
                type: EventType.System,
                businessTransactionId: uuidv4(),
            };

            const customProperties = {
                numMeetings: meetings.length.toString(),
            };

            this.telemetryClient.trackEvent(event, customProperties);

            return true;

        } catch (err) {
            this.calendarDispatch(updateTask({
                name: Tasks.CANCEL_MEETINGS,
                text: "We won’t be able to cancel your meetings. Please go to your calendar to cancel.",
                status: Status.Error,
                err: err
            }))
            console.error("caught err: ", err);
            // TODO: ADD EXCEPTION LOGGING HERE FOR UAT DEPLOYMENT
            this.telemetryClient.trackException({
                exception: new Error("Failed to get cancel meetings."),
            }, {
                error: err as Error
            });
            return false;
        }
    }

    // for each of the ui actions, there are multiple events, that are triggered, check if the event has already been triggerred and what the set value is
    /**
     * Cancel all events / meetings that are on the holiday, based on the startDate and endDate in PST time
     * @param holidays
     */
    private async replyTentative(meetings : ICalendarEvent[]) : Promise<boolean> {

        this.calendarDispatch(updateTaskStatus(Tasks.REPLY_TENTATIVE, Status.Loading));

        try {
            let userInfo : IMsGraphUserInfo = await this.calendarService.getMsGraphUserInfo();

            // Filter Meetings Organized by Others to reply Tentative
            const meetingsToRespond = meetings.filter(
                meeting => ( 
                    meeting.organizer?.emailAddress?.address.toLowerCase() !== userInfo.userPrincipalName.toLowerCase() &&
                    meeting.organizer?.emailAddress?.address.toLowerCase() !== userInfo.mail.toLowerCase() &&
                    meeting.responseRequested === true
                )
            );

            let replyPromisesFns = [];

            for (const meeting of meetingsToRespond) {

                // TODO: change reply message to match MyHub
    
                replyPromisesFns.push(
                    async () => {
                        return (await this.calendarService.replyTentative(meeting.id, "Tentatively accepting meeting due to holiday."))
                    }
                );
            }

            let startTime = new Date().getTime(); // milliseconds
            // iterate through all the meetings to reply tentative to, if the failure message is 429, retry 3 times
            await this.executeCalendarActions(replyPromisesFns, `Failed to reply tentative to meetings after ${this.numRetries} tries.`);
            let endTime = new Date().getTime();
            let timeTaken = endTime - startTime;

            this.calendarDispatch(updateTaskStatus(Tasks.REPLY_TENTATIVE, Status.Complete));

            const event: SystemEvent = {
                eventName: 'RepliedTentative',
                feature: 'GCH',
                subFeature: 'ReplyTentative',
                featureLocation: 'CalendarUpdatePanel',
                timeTaken: timeTaken,
                type: EventType.System,
                businessTransactionId: uuidv4(),
            };

            const customProperties = {
                numMeetings: meetings.length.toString(),
            };

            this.telemetryClient.trackEvent(event, customProperties);
            
            return true;

        } catch (err) {
            this.calendarDispatch(updateTask({
                name: Tasks.REPLY_TENTATIVE,
                text: "We won’t be able to respond to meeting invites. Please go to your calendar to respond.",
                status: Status.Error,
                err: err
            }))
            console.error("caught err: ", err);
            // TODO: ADD EXCEPTION LOGGING HERE FOR UAT DEPLOYMENT
            this.telemetryClient.trackException({
                exception: new Error("Failed to get reply tentatively to meetings."),
            }, {
                error: err as Error
            });
            return false;
        }
    }

    // for each of the ui actions, there are multiple events, that are triggered, check if the event has already been triggerred and what the set value is
    /**
     * Cancel all events / meetings that are on the holiday, based on the startDate and endDate in PST time
     * @param holidays 
     */
    private async blockCalendar(holidays : IHoliday[], showAs: ShowAsValues) : Promise<boolean> {
        this.calendarDispatch(updateTaskStatus(Tasks.BLOCK_CALENDAR, Status.Loading));

        try {
            let blockPromiseFns = [];

            for (const holiday of holidays) {
                const meetingStart = moment(holiday.PublicHolidayStartDate).format(this.dateFormat);
                const meetingEnd = moment(holiday.PublicHolidayEndDate).add(1, "days").format(this.dateFormat);

                const calendarEvent: Meeting = {
                    subject: holiday.PublicHolidayName,
                    attendees: undefined,
                    responseRequested: false,
                    body: {
                        content: this.getReplyTentativeText(holiday),
                        contentType: "text",
                    },
                    start: {
                        dateTime: meetingStart,
                        timeZone: this.timeZone,
                    },
                    end: {
                        dateTime: meetingEnd,   
                        timeZone: this.timeZone,
                    },
                    isAllDay: true,
                    showAs,
                };

                blockPromiseFns.push(async () => {
                    return await this.calendarService.blockCalendar(calendarEvent)
                });
            }

            let startTime = new Date().getTime(); // milliseconds
            await this.executeCalendarActions(blockPromiseFns, `Failed to block events, after ${this.numRetries} retries.`);
            let endTime = new Date().getTime();
            let timeTaken = endTime - startTime;

            this.calendarDispatch(updateTaskStatus(Tasks.BLOCK_CALENDAR, Status.Complete));

            const event: SystemEvent = {
                eventName: 'BlockedCalendar',
                feature: 'GCH',
                subFeature: 'BlockCalendar',
                featureLocation: 'CalendarUpdatePanel',
                timeTaken: timeTaken,
                type: EventType.System,
                businessTransactionId: uuidv4(),
            };

            const customProperties = {
                numHolidays: holidays.length.toString(),
            };

            this.telemetryClient.trackEvent(event, customProperties);

            return true;

        } catch (err) {
            this.calendarDispatch(updateTask({
                name: Tasks.BLOCK_CALENDAR,
                text: "We can’t update your calendar with a blocking event. Please go to your calendar to update.",
                status: Status.Error,
                err: err
            }))
            console.error("caught err: ", err);
            // TODO: ADD EXCEPTION LOGGING HERE FOR UAT DEPLOYMENT
            this.telemetryClient.trackException({
                exception: new Error("Failed to get add event to block calendar."),
            }, {
                error: err as Error
            });
            return false;
        }
    }

    // helper methods

    private async executeCalendarActions(actionPromiseFns: (() => Promise<IODataResponse<any>>)[], retryFailureErrMessage: string) {
            
        const result = [];

        for (const actionPromiseFn of actionPromiseFns) {
            let failedCount = 0;

            // retry a max of 3 times
            while (failedCount < this.numRetries) {

                console.log("running loop");
                try {
                    const val =  await actionPromiseFn();
                    console.log("val: ", val);
                    result.push(val);
                    break;
                } catch (err: any)  {
                    console.error("err: ", err);

                    if (err?.status === this.codeTooManyRequests) {
                        console.log("got 429");
                        failedCount++;
                    } else {
                        throw err;
                    }
                }
            }

            if (failedCount >= this.numRetries) {
                throw new Error(retryFailureErrMessage);
            }
        }

        return result;
    }

    private getReplyTentativeText(holiday : IHoliday) : string {
        const dateFormat = 'MMMM DD, YYYY'
        const startDate : string = moment(holiday.PublicHolidayStartDate).format(dateFormat);
        const endDate : string = moment(holiday.PublicHolidayEndDate).format(dateFormat);

        return `In observance of ${holiday.PublicHolidayName}, Microsoft will be closed ${startDate == endDate ? `on ${startDate}` : `from ${startDate} to ${endDate}`}`;
    }
}

// manageCalendar helper methods

const getHolidayStartAndEnd = (holiday : IHoliday) : {start : string, end : string} => {
    const startDateTime = moment(holiday.PublicHolidayStartDate).toISOString();
    const endDateTime = moment(holiday.PublicHolidayEndDate).add(1, 'days').toISOString();

    return {
        start: startDateTime,
        end: endDateTime
    }
}

// reducer helper methods

const getUpdatedStoreWithNewTask = (store : ICalendarStore, taskModifier : ITaskModifier) : ICalendarStore => {

    const oldTask : ITask = store.tasks[taskModifier.name];

    return {
        tasks: {
            ...store.tasks,
            [taskModifier.name]: {
                text: taskModifier.text || oldTask.text,
                status: taskModifier.status || oldTask.status,
                err: taskModifier.err || oldTask.err,
            }
        }
    }
}

/**
 * @param store og calendar store
 * @param payload     payload: {
        taskName: string,
        taskStatus: Status
    } 
 * @returns new calendar store
 */
const getUpdatedStoreWithNewTaskStatus = (store : ICalendarStore, payload: {taskName: Tasks, taskStatus: Status}) : ICalendarStore => {    
    return {
        tasks: {
            ...store.tasks,
            [payload.taskName]: {
                ...store.tasks[payload.taskName],
                status: payload.taskStatus
            }
        }
    }
}

/**
     * Performs an action against the store to update the store
     * @param store - the current store
     * @param action - an Action created by an Action Creator in Actions.ts
     * @returns the new store
     */
export const reducer = (store: any, action: { type: string; payload: any; }) : ICalendarStore => {
    switch(action.type) {
        case 'RESET_STORE':
            return cloneDeep(initialState);
        case 'UPDATE_STORE':
            return cloneDeep(action.payload);
        case 'UPDATE_TASK':
            return getUpdatedStoreWithNewTask(store, action.payload)
        case 'UPDATE_TASK_STATUS':
            return getUpdatedStoreWithNewTaskStatus(store, action.payload)
        default: 
            throw new Error("Unrecognized Calendar action type");
    }
}

/**
 * Actions and Action Creators.
 */

export const Actions = {
    RESET_STORE: 'RESET_STORE',
    UPDATE_STORE: 'UPDATE_STORE',
    UPDATE_TASK: 'UPDATE_TASK',
    UPDATE_TASK_STATUS: 'UPDATE_TASK_STATUS' 
}

export const resetStore = () => ({
    type: Actions.RESET_STORE
});

export const updateStore = (newStore : ICalendarStore) : ICalendarAction => ({
    type: Actions.UPDATE_STORE,
    payload: newStore
});

export const updateTask = (taskModifier : ITaskModifier) : ICalendarAction => ({
    type: Actions.UPDATE_TASK,
    payload: {
        ...taskModifier
    }
})

export const updateTaskStatus = (taskName : string, taskStatus : Status) : ICalendarAction => ({
    type: Actions.UPDATE_TASK_STATUS,
    payload: {
        taskName: taskName,
        taskStatus: taskStatus
    }
})
