import { Text } from '@fluentui/react/lib/Text';

export const Container = styled.div`
    padding-bottom: 24px;
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: 5%;
    margin-right: 5%;
`;

export const Space = styled.div`
    margin-bottom: 24px;
`;

export const PageHeading = styled(Text).attrs({
    as: 'h1',
    variant: 'xLarge',
    block: true
})`
    font-size: 26px;
    margin-bottom: 16px;
`;

export const PageHeadingCaption = styled(Text).attrs({
    as: "p",
    variant: 'small',
    block: true
})`
    font-size: 14px;
    margin-bottom: 48px;

    @media (max-width: 1023px) {
        margin-bottom: 32px;
    }
`;

export const PageDescription = styled(Text).attrs({
    as: 'p',
    block: true
})`
    margin-bottom: 24px;
`;

export const SectionTitle = styled(Text).attrs({
    as: 'h2',
    variant: 'large',
    block: true
})`
    margin-bottom: 24px;
`;

export const Page = styled.div`
    position: relative;
    height: auto;
    max-width: 1872px;
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 12vw;
    padding-right: 12vw;
`;