export const TableContainer = styled.div`
    overflow-x: scroll;
`;

export const ManagerTable = styled.table`
    border: 1px solid black;
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    font-size: 14px;
    min-width: 760px;
`;

export const DropdownContainer = styled.div`
    float: left;
    width: 10%;
    min-width: 75px;
`;

export const ButtonContainer = styled.div`
    float: right;
    text-align: right;
    width: 90%;
`;

export const DeleteAllContainer = styled.div`
    float: left;   
    width: 50%;
    color: gray;
    margin-top: 10px;
`;

export const CountContainer = styled.div`
    float: right;   
    width: 50%;
    margin-top: 10px;
    padding-right: 10px;
    text-align: right;
    font-size: 18px;
    font-weight: 500;
`;

