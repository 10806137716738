// import { CoherencePivot, CoherencePivotTabProps, PivotContent, PivotContentItem } from '@coherence-design-system/controls';
import { ErrorStateComponent, ErrorStateIconType } from '@coherence-design-system/controls';
import * as _ from 'lodash';
import { Dropdown, FontIcon, IDropdownOption, Shimmer, ShimmerElementType } from '@fluentui/react';
import * as React from 'react';
import { FormEvent, ReactElement, useEffect, useMemo, useState } from 'react';
import { getHolidayListViewImageUrl } from '../Services/GlobalHolidaysService';
import { IHoliday, IUserInfo, IHolidaysRenderData } from '../Services/GlobalHolidaysService.types';
import * as Styled from './HolidaysList.styled';
import SVG from 'react-inlinesvg';
import * as moment from 'moment';
import { EventType, UsageEventName, UserEvent } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { IStyleSet, Label, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';

const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
    root: { marginTop: 10 },
};

interface HolidaysListProps {
    dropdownOptions: IDropdownOption[];
    holidays: IHoliday[];
    userInfo: IUserInfo;
    loading: boolean;
    error: boolean;
    selectedHolidayType: IDropdownOption | null;
    setSelectedHolidayType: React.Dispatch<React.SetStateAction<IDropdownOption>>;
    onInitiateCalendar: (
        newSelectedYear?: number,
        newSelectedHoliday?: IHoliday,
        newHolidayRenderData?: IHolidaysRenderData
    ) => void;
    onSaveCalendar: () => void;
}

export const allDropdownOptions : IDropdownOption[] = [
    { key: 'corporate', text: 'Corporate holidays' },
    { key: 'retail', text: 'Retail holidays' }
];

/**
 *
 * @param holidayType - one of the holiday types defined in HOLIDAY_TYPES
 * @param dropdownOptions
 * @returns the index of the specified holiday type or -1 if invalid
 */
export const getDropdownIndexOfHolidayType = (holidayType: string, dropdownOptions: IDropdownOption[]) : number => {
    console.log(holidayType);
    console.log(dropdownOptions);
    if (!Object.values(['retail', 'corporate']).includes(holidayType)) {
        throw new Error(`holidayType ${holidayType} is invalid`);
    }

    const index = dropdownOptions.findIndex((option) => option.key === holidayType);
    return index;
};

export const HolidaysList = (props: HolidaysListProps): ReactElement => {
    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const currentYear = useMemo<number>(() => new Date().getFullYear(), []);

    const [selectedYear, setSelectedYear] = useState<number>(currentYear);

    const holidaysByType = useMemo<{ [key: string]: IHoliday[] }>(() => {
        const groupedHolidays: { [key: string]: IHoliday[] } = {};

        groupedHolidays['corporate'] = props.holidays.filter((holiday : IHoliday) => {
            return holiday.Corporate;
        })

        groupedHolidays['retail'] = props.holidays.filter((holiday: IHoliday) => {
            return holiday.Retail;
        })

        return groupedHolidays;
    }, [props.holidays, props.dropdownOptions]);

    // the list of different years to display
    const yearList = useMemo<number[]>(() => {
        if (!props.selectedHolidayType || !(holidaysByType && holidaysByType[props.selectedHolidayType.key])) return [];

        const startYears: number[] = holidaysByType[props.selectedHolidayType.key].map((holiday) =>
            getYearNumber(holiday.PublicHolidayStartDate)
        );
        const endYears: number[] = holidaysByType[props.selectedHolidayType.key].map((holiday) =>
            getYearNumber(holiday.PublicHolidayEndDate)
        );

        const uniqueYears = _.uniq(startYears.concat(endYears));

        const nextYear: number = currentYear + 1;

        return uniqueYears
            .filter((year: number) => {
                return year === currentYear || year === nextYear;
            })
            .sort();
    }, [holidaysByType, props.selectedHolidayType]);

    const displayedHolidayCount = useMemo<number>((): number => {
        if (
            !holidaysByType ||
            !props.selectedHolidayType ||
            !holidaysByType[props.selectedHolidayType.key] ||
            !selectedYear
        )
            return 0;

        return holidaysByType[props.selectedHolidayType.key].filter((holiday: IHoliday) => {
            return isHolidayInYear(selectedYear, holiday);
        }).length;
    }, [selectedYear, holidaysByType, props.selectedHolidayType]);

    const holidayListData: IHolidaysRenderData = useMemo(() => {
        // mapping of year to the holidays in the year
        let data: IHolidaysRenderData = {};

        if (!props.selectedHolidayType) return data;

        yearList.map((year) => {
            const holidaysCopy = [...holidaysByType[props.selectedHolidayType.key]];

            holidaysCopy.sort((a: IHoliday, b: IHoliday) => {
                return new Date(a.PublicHolidayStartDate).getTime() - new Date(b.PublicHolidayStartDate).getTime();
            });

            // organize holidays based on the year of the start date
            for (const holiday of holidaysCopy) {
                if (getYearNumber(holiday.PublicHolidayStartDate) === year) {
                    if (!data[year]) {
                        data[year] = [holiday];
                    } else {
                        data[year].push(holiday);
                    }
                }
            }
        });

        return data;
    }, [holidaysByType, yearList, props.selectedHolidayType]);

    // set initial selectedYear to be the current year
    useEffect(() => {
        setSelectedYear(currentYear);
    }, [props.holidays, props.selectedHolidayType]);

    // set the selected holiday when the user changes the holiday type, and when the user is first loaded
    useEffect(() => {
        if (!props.selectedHolidayType || !props.dropdownOptions || !props.userInfo || !holidaysByType) {
            return;
        }

        const userPersonnelTypeDropdownOption =
            props.dropdownOptions[getDropdownIndexOfHolidayType(props.userInfo.PersonnelType, props.dropdownOptions)];

        if (!holidaysByType[props.selectedHolidayType.key]?.length) {
            props.setSelectedHolidayType(userPersonnelTypeDropdownOption);
        }
    }, [holidaysByType, props.userInfo, props.dropdownOptions]);

    const onYearSelect = (yearItem) => {
        if (yearItem.props && yearItem.props.headerText) {
            setSelectedYear(parseInt(yearItem.props.headerText));
        }

        const event: UserEvent = {
            feature: 'GCH',
            subFeature: 'SelectYear',
            featureLocation: 'Home',
            eventName: UsageEventName.ButtonClicked,
            type: EventType.User,
        };
        telemetryClient.trackEvent(event, {
            year: yearItem.props.headerText,
        });
    };

    const getAddToCalendarHandler = (
        year?: number,
        selectedHoliday?: IHoliday,
        holidayRenderData?: IHolidaysRenderData
    ) => {
        return () => {
            const event: UserEvent = {
                feature: 'GCH',
                subFeature: 'ClickAddAllToCalendar',
                featureLocation: 'Home',
                eventName: UsageEventName.ButtonClicked,
                type: EventType.User,
            };
            telemetryClient.trackEvent(event);
            props.onInitiateCalendar(year, selectedHoliday, holidayRenderData);
        };
    };

    const renderTabs = () => {
        if (!props.loading) {
            const year = selectedYear || currentYear;

            return (
                <Styled.HolidayYearTabContainer>
                    <Styled.HolidayYearTabs yearsCount={yearList.length}>
                        <Pivot
                            onLinkClick={onYearSelect}
                            selectedKey={selectedYear.toString()}
                            styles={{
                                root: {
                                    marginBottom: 0,
                                },
                            }}
                        >
                            {yearList.map((year: number) => {
                                return (
                                    <PivotItem
                                        itemKey={year.toString()}
                                        headerText={year.toString()}
                                        headerButtonProps={{
                                            'data-order': 1,
                                            'data-title': `${year.toString()} Title`,
                                        }}
                                    />
                                );
                            })}
                        </Pivot>
                    </Styled.HolidayYearTabs>
                    {yearList?.length ? (
                        <Styled.CalendarAction
                            aria-label="Add all to calendar"
                            tabIndex={0}
                            onClick={getAddToCalendarHandler(year, undefined, holidayListData)}
                        >
                            <span className="textLeft">Add all to calendar</span>
                            <FontIcon iconName="AddEvent" />
                        </Styled.CalendarAction>
                    ) : undefined}
                </Styled.HolidayYearTabContainer>
            );
        }
    };

    const handleHolidayTypeChange = (e: FormEvent<HTMLDivElement>, option: IDropdownOption) => {
        const event: UserEvent = {
            feature: 'GCH',
            subFeature: 'SelectHolidaysType',
            featureLocation: 'Home',
            eventName: UsageEventName.ButtonClicked,
            type: EventType.User,
        };
        telemetryClient.trackEvent(event, {
            text: option.text,
        });
        return props.setSelectedHolidayType(option);
    };

    const renderHolidayTypeDropdown = () => {
        if (!props.loading) {
            if (props.dropdownOptions.length > 1) {
                return (
                    <Dropdown
                        selectedKey={props.selectedHolidayType?.key}
                        onChange={handleHolidayTypeChange}
                        options={props.dropdownOptions}
                        styles={{ dropdown: { width: 246 } }}
                        title={'Holiday Type Dropdown'}
                        ariaLabel="Holiday Type Dropdown"
                    />
                );
            } else if (props.dropdownOptions.length == 1) {
                return <Styled.HolidaysTypeSpan>{props.dropdownOptions[0].text}</Styled.HolidaysTypeSpan>;
            } else {
                return <div />;
            }
        }
    };

    const HolidayComponent = ({ holiday, isFocusable }: { holiday: IHoliday; isFocusable: boolean }) => {
        const holidayEndDate = new Date(holiday.PublicHolidayEndDate);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const holidayInPast = holidayEndDate < today;

        const images = getHolidayListViewImageUrl(holiday, holidayInPast);

        const [imgSrc, setImgSrc] = useState<string>(images.main);
        const [errored, setErrored] = useState<boolean>(false);

        const onError = () => {
            if (!errored) {
                setImgSrc(images.placeholder);
                setErrored(true);
            }
        };

        const addToCalendar = !holidayInPast ? (
            <Styled.CalendarAction
                disabled={!isFocusable}
                onClick={getAddToCalendarHandler(undefined, holiday, holidayListData)}
                 aria-label={holiday.PublicHolidayName +"Add to calendar"} >
                <FontIcon iconName="AddTo" />
                <span className="textRight">Add to calendar</span>
            </Styled.CalendarAction>
        ) : undefined;

        return (
            <Styled.HolidayItem>
                <Styled.HolidayIconWrapper>
                    {imgSrc && imgSrc.endsWith('.png') ? (
                        <Styled.HolidayImageWrapper>
                            <Styled.HolidayImage
                                src={imgSrc}
                                alt={holiday.PublicHolidayName + ' Icon'}
                                onError={onError}
                            />
                        </Styled.HolidayImageWrapper>
                    ) : (
                        <SVG width={100} height={100} src={imgSrc} onError={onError} />
                    )}
                </Styled.HolidayIconWrapper>
                <Styled.HolidayItemText holidayInPast={holidayInPast}>
                    <Styled.HolidayItemTitle>{holiday.PublicHolidayName}</Styled.HolidayItemTitle>
                    <div>{holiday.PublicHolidayDate}</div>
                    {addToCalendar}
                </Styled.HolidayItemText>
            </Styled.HolidayItem>
        );
    };

    const renderLoading = () => {
        if (props.loading) {
            const itemShimmer = (
                <>
                    <Styled.HolidayItem>
                        <Shimmer
                            width={110}
                            style={{ marginRight: '12px' }}
                            shimmerElements={[{ type: ShimmerElementType.line, height: 100, width: 100 }]}
                        />
                        <div style={{ flex: 1 }}>
                            <Shimmer
                                width={'300px'}
                                style={{ marginBottom: '5px' }}
                                shimmerElements={[{ type: ShimmerElementType.line, height: 24 }]}
                            />
                            <Shimmer
                                width={'200px'}
                                shimmerElements={[{ type: ShimmerElementType.line, height: 20 }]}
                            />
                        </div>
                    </Styled.HolidayItem>
                </>
            );
            return [...Array(3)].map(() => itemShimmer);
        }
    };

    const renderNoHolidaysWarning = () => {
        if (!props.loading) {
            if (
                !props.selectedHolidayType ||
                holidaysByType[props.selectedHolidayType.key].filter((holiday) =>
                    isHolidayInYear(selectedYear, holiday)
                ).length === 0
            ) {
                return 'No holidays available';
            }
        }
    };

    const renderHolidayContent = () => {
        if (!props.loading) {
            return (
                <Pivot selectedKey={selectedYear.toString()}>
                    {Object.keys(holidayListData).map((year, i) => {
                        const holidays = holidayListData[parseInt(year)];
                        const isFocusable = year === selectedYear.toString(); // disable buttons that are aria-hidden for accessibility

                        return (
                            <PivotItem itemKey={year}>
                                {holidays.map((holiday: IHoliday) => {
                                    return <HolidayComponent holiday={holiday} isFocusable={isFocusable} />;
                                })}
                            </PivotItem>
                        );
                    })}
                </Pivot>
            );
        }
    };

    if (props.error) {
        return (
            <ErrorStateComponent
                iconType={ErrorStateIconType.DataIssue}
                headingText="Something went wrong :("
                descriptionText="Could not retrieve holidays"
            />
        );
    }

    return (
        <>
            <Styled.ControlsRow>
                {renderTabs()}
                {renderHolidayTypeDropdown()}
            </Styled.ControlsRow>
            <Styled.HolidayList itemCount={!props.loading ? displayedHolidayCount : 0}>
                {renderLoading()}
                {renderNoHolidaysWarning()}
                {renderHolidayContent()}
            </Styled.HolidayList>
        </>
    );
};

const getYearNumber = (holidayDateString: string): number => {
    return moment(holidayDateString).year();
};

const isHolidayInYear = (year: number, holiday: IHoliday): boolean => {
    return (
        year === getYearNumber(holiday.PublicHolidayStartDate) || year === getYearNumber(holiday.PublicHolidayEndDate)
    );
};
