

const holidays = [{
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 1, 2019",
    "PublicHolidayStartDate": "2019-01-01 00:00:00.000",
    "PublicHolidayEndDate": "2019-01-01 00:00:00.000",
    "PublicHolidayName": "New Year's Day",
    "ListViewImageName": "LV_US_NewYearsDay.svg",
    "RegImageName": "US_NewYearsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 21, 2019",
    "PublicHolidayStartDate": "2019-01-21 00:00:00.000",
    "PublicHolidayEndDate": "2019-01-21 00:00:00.000",
    "PublicHolidayName": "Martin Luther King Day",
    "ListViewImageName": "LV_US_MLKDay.svg",
    "RegImageName": "US_MLKDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "February 18, 2019",
    "PublicHolidayStartDate": "2019-02-18 00:00:00.000",
    "PublicHolidayEndDate": "2019-02-18 00:00:00.000",
    "PublicHolidayName": "Presidents Day",
    "ListViewImageName": "LV_US_PresidentsDay.svg",
    "RegImageName": "US_PresidentsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "May 27, 2019",
    "PublicHolidayStartDate": "2019-05-27 00:00:00.000",
    "PublicHolidayEndDate": "2019-05-27 00:00:00.000",
    "PublicHolidayName": "Memorial Day",
    "ListViewImageName": "LV_US_MemorialDay.svg",
    "RegImageName": "US_MemorialDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "July 4, 2019",
    "PublicHolidayStartDate": "2019-07-04 00:00:00.000",
    "PublicHolidayEndDate": "2019-07-04 00:00:00.000",
    "PublicHolidayName": "Independence Day",
    "ListViewImageName": "LV_US_IndependenceDay.svg",
    "RegImageName": "US_IndependenceDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "September 2, 2019",
    "PublicHolidayStartDate": "2019-09-02 00:00:00.000",
    "PublicHolidayEndDate": "2019-09-02 00:00:00.000",
    "PublicHolidayName": "Labor Day",
    "ListViewImageName": "LV_US_LaborDay.svg",
    "RegImageName": "US_LaborDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "November 28, 2019 - November 29, 2019",
    "PublicHolidayStartDate": "2019-11-28 00:00:00.000",
    "PublicHolidayEndDate": "2019-11-29 00:00:00.000",
    "PublicHolidayName": "Thanksgiving Day and Day after Thanksgiving",
    "ListViewImageName": "LV_US_Thanksgiving.svg",
    "RegImageName": "US_Thanksgiving.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 24, 2019 - December 25, 2019",
    "PublicHolidayStartDate": "2019-12-24 00:00:00.000",
    "PublicHolidayEndDate": "2019-12-25 00:00:00.000",
    "PublicHolidayName": "Christmas Eve and Christmas Day",
    "ListViewImageName": "LV_US_Christmas.svg",
    "RegImageName": "US_Christmas.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 1, 2020",
    "PublicHolidayStartDate": "2020-01-01 00:00:00.000",
    "PublicHolidayEndDate": "2020-01-01 00:00:00.000",
    "PublicHolidayName": "New Year's Day",
    "ListViewImageName": "LV_US_NewYearsDay.svg",
    "RegImageName": "US_NewYearsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 20, 2020",
    "PublicHolidayStartDate": "2020-01-20 00:00:00.000",
    "PublicHolidayEndDate": "2020-01-20 00:00:00.000",
    "PublicHolidayName": "Martin Luther King Day",
    "ListViewImageName": "LV_US_MLKDay.svg",
    "RegImageName": "US_MLKDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "February 17, 2020",
    "PublicHolidayStartDate": "2020-02-17 00:00:00.000",
    "PublicHolidayEndDate": "2020-02-17 00:00:00.000",
    "PublicHolidayName": "Presidents Day",
    "ListViewImageName": "LV_US_PresidentsDay.svg",
    "RegImageName": "US_PresidentsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "May 25, 2020",
    "PublicHolidayStartDate": "2020-05-25 00:00:00.000",
    "PublicHolidayEndDate": "2020-05-25 00:00:00.000",
    "PublicHolidayName": "Memorial Day",
    "ListViewImageName": "LV_US_MemorialDay.svg",
    "RegImageName": "US_MemorialDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "July 3, 2020",
    "PublicHolidayStartDate": "2020-07-03 00:00:00.000",
    "PublicHolidayEndDate": "2020-07-03 00:00:00.000",
    "PublicHolidayName": "Independence Day",
    "ListViewImageName": "LV_US_IndependenceDay.svg",
    "RegImageName": "US_IndependenceDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "September 7, 2020",
    "PublicHolidayStartDate": "2020-09-07 00:00:00.000",
    "PublicHolidayEndDate": "2020-09-07 00:00:00.000",
    "PublicHolidayName": "Labor Day",
    "ListViewImageName": "LV_US_LaborDay.svg",
    "RegImageName": "US_LaborDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "November 26, 2020 - November 27, 2020",
    "PublicHolidayStartDate": "2020-11-26 00:00:00.000",
    "PublicHolidayEndDate": "2020-11-27 00:00:00.000",
    "PublicHolidayName": "Thanksgiving Day and Day after Thanksgiving",
    "ListViewImageName": "LV_US_Thanksgiving.svg",
    "RegImageName": "US_Thanksgiving.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 24, 2020 - December 25, 2020",
    "PublicHolidayStartDate": "2020-12-24 00:00:00.000",
    "PublicHolidayEndDate": "2020-12-25 00:00:00.000",
    "PublicHolidayName": "Christmas Eve and Christmas Day",
    "ListViewImageName": "LV_US_Christmas.svg",
    "RegImageName": "US_Christmas.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 1, 2021",
    "PublicHolidayStartDate": "2021-01-01 00:00:00.000",
    "PublicHolidayEndDate": "2021-01-01 00:00:00.000",
    "PublicHolidayName": "New Year's Day",
    "ListViewImageName": "LV_US_NewYearsDay.svg",
    "RegImageName": "US_NewYearsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 18, 2021",
    "PublicHolidayStartDate": "2021-01-18 00:00:00.000",
    "PublicHolidayEndDate": "2021-01-18 00:00:00.000",
    "PublicHolidayName": "Martin Luther King Day",
    "ListViewImageName": "LV_US_MLKDay.svg",
    "RegImageName": "US_MLKDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "February 15, 2021",
    "PublicHolidayStartDate": "2021-02-15 00:00:00.000",
    "PublicHolidayEndDate": "2021-02-15 00:00:00.000",
    "PublicHolidayName": "Presidents Day",
    "ListViewImageName": "LV_US_PresidentsDay.svg",
    "RegImageName": "US_PresidentsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "May 31, 2021",
    "PublicHolidayStartDate": "2021-05-31 00:00:00.000",
    "PublicHolidayEndDate": "2021-05-31 00:00:00.000",
    "PublicHolidayName": "Memorial Day",
    "ListViewImageName": "LV_US_MemorialDay.svg",
    "RegImageName": "US_MemorialDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "July 5, 2021",
    "PublicHolidayStartDate": "2021-07-05 00:00:00.000",
    "PublicHolidayEndDate": "2021-07-05 00:00:00.000",
    "PublicHolidayName": "Independence Day",
    "ListViewImageName": "LV_US_IndependenceDay.svg",
    "RegImageName": "US_IndependenceDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "September 6, 2021",
    "PublicHolidayStartDate": "2021-09-06 00:00:00.000",
    "PublicHolidayEndDate": "2021-09-06 00:00:00.000",
    "PublicHolidayName": "Labor Day",
    "ListViewImageName": "LV_US_LaborDay.svg",
    "RegImageName": "US_LaborDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "November 25, 2021 - November 26, 2021",
    "PublicHolidayStartDate": "2021-11-25 00:00:00.000",
    "PublicHolidayEndDate": "2021-11-26 00:00:00.000",
    "PublicHolidayName": "Thanksgiving Day and Day after Thanksgiving",
    "ListViewImageName": "LV_US_Thanksgiving.svg",
    "RegImageName": "US_Thanksgiving.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 23, 2021 - December 24, 2021",
    "PublicHolidayStartDate": "2021-12-23 00:00:00.000",
    "PublicHolidayEndDate": "2021-12-24 00:00:00.000",
    "PublicHolidayName": "Christmas Eve and Christmas Day",
    "ListViewImageName": "LV_US_Christmas.svg",
    "RegImageName": "US_Christmas.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 31, 2021",
    "PublicHolidayStartDate": "2021-12-31 00:00:00.000",
    "PublicHolidayEndDate": "2021-12-31 00:00:00.000",
    "PublicHolidayName": "New Year’s Day 2022 (Observed)",
    "ListViewImageName": "LV_US_NewYearsDay.svg",
    "RegImageName": "US_NewYearsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_NewYearsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "January 17, 2022",
    "PublicHolidayStartDate": "2022-01-17 00:00:00.000",
    "PublicHolidayEndDate": "2022-01-17 00:00:00.000",
    "PublicHolidayName": "Martin Luther King Day",
    "ListViewImageName": "LV_US_MLKDay.svg",
    "RegImageName": "US_MLKDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MLKDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "February 21, 2022",
    "PublicHolidayStartDate": "2022-02-21 00:00:00.000",
    "PublicHolidayEndDate": "2022-02-21 00:00:00.000",
    "PublicHolidayName": "Presidents Day",
    "ListViewImageName": "LV_US_PresidentsDay.svg",
    "RegImageName": "US_PresidentsDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_PresidentsDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "May 30, 2022",
    "PublicHolidayStartDate": "2022-05-30 00:00:00.000",
    "PublicHolidayEndDate": "2022-05-30 00:00:00.000",
    "PublicHolidayName": "Memorial Day",
    "ListViewImageName": "LV_US_MemorialDay.svg",
    "RegImageName": "US_MemorialDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_MemorialDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "July 4, 2022",
    "PublicHolidayStartDate": "2022-07-04 00:00:00.000",
    "PublicHolidayEndDate": "2022-07-04 00:00:00.000",
    "PublicHolidayName": "Independence Day",
    "ListViewImageName": "LV_US_IndependenceDay.svg",
    "RegImageName": "US_IndependenceDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_IndependenceDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "September 5, 2022",
    "PublicHolidayStartDate": "2022-09-05 00:00:00.000",
    "PublicHolidayEndDate": "2022-09-05 00:00:00.000",
    "PublicHolidayName": "Labor Day",
    "ListViewImageName": "LV_US_LaborDay.svg",
    "RegImageName": "US_LaborDay.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_LaborDay.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "November 24, 2022",
    "PublicHolidayStartDate": "2022-11-24 00:00:00.000",
    "PublicHolidayEndDate": "2022-11-24 00:00:00.000",
    "PublicHolidayName": "Thanksgiving Day",
    "ListViewImageName": "LV_US_Thanksgiving.svg",
    "RegImageName": "US_Thanksgiving.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "November 25, 2022",
    "PublicHolidayStartDate": "2022-11-25 00:00:00.000",
    "PublicHolidayEndDate": "2022-11-25 00:00:00.000",
    "PublicHolidayName": "Day after Thanksgiving",
    "ListViewImageName": "LV_US_Thanksgiving.svg",
    "RegImageName": "US_Thanksgiving.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Thanksgiving.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 23, 2022",
    "PublicHolidayStartDate": "2022-12-23 00:00:00.000",
    "PublicHolidayEndDate": "2022-12-23 00:00:00.000",
    "PublicHolidayName": "Christmas Eve (Observed)",
    "ListViewImageName": "LV_US_Christmas.svg",
    "RegImageName": "US_Christmas.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}, {
    "CountryCode": "US",
    "CompanyCode": "1010",
    "PersonnelSubAreaCode": "0",
    "PersonnelSubAreaName": "All sub-areas",
    "CountryName": "United States",
    "PublicHolidayDate": "December 26, 2022",
    "PublicHolidayStartDate": "2022-12-26 00:00:00.000",
    "PublicHolidayEndDate": "2022-12-26 00:00:00.000",
    "PublicHolidayName": "Christmas Day (Observed)",
    "ListViewImageName": "LV_US_Christmas.svg",
    "RegImageName": "US_Christmas.svg",
    "ListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "RegImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D",
    "GrayscaledListViewImageUrl": "https://enggchwebcdn.azureedge.net/icons/US/GS_LV_US_Christmas.svg?sv=2020-02-10&se=2021-12-18T21%3A49%3A33Z&sr=c&sp=r&sig=Gvn2%2B8H6VB99mKRE75xSTfmEheeOr11aW7zfD8l6RXM%3D"
}];

export default holidays;