import * as React from 'react';
import { useContext } from 'react';
import { CalendarContext, ITask, Status } from "../../State/Calendar";
import { CoherenceModal } from '@coherence-design-system/controls';
import { DefaultButton, PrimaryButton, SpinnerSize, Toggle } from '@fluentui/react';
import * as Styled from './UpdatePanel.styled';
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";

interface IUpdatePanelProps {
    hideUpdatePanel: boolean,
    setHideUpdatePanel: React.Dispatch<React.SetStateAction<boolean>>,
    setCalendarPanelClose: () => void 
}

const UpdatePanel = ({ hideUpdatePanel, setHideUpdatePanel, setCalendarPanelClose } : IUpdatePanelProps) => {
    const { calendarStore } = useContext(CalendarContext);
    let tasksInStore = calendarStore.tasks;
    let tasks : ITask[] = [];
    let isDone : boolean = true;

    if (tasksInStore.GET_EVENTS && tasksInStore.GET_EVENTS.status != Status.NotSelected) {
        tasks.push(tasksInStore.GET_EVENTS);
        isDone = isDone && tasksInStore.GET_EVENTS.status == Status.Complete;
    }

    if (tasksInStore.CANCEL_MEETINGS && tasksInStore.CANCEL_MEETINGS.status != Status.NotSelected) {
        tasks.push(tasksInStore.CANCEL_MEETINGS);
        isDone = isDone && tasksInStore.CANCEL_MEETINGS.status == Status.Complete;
    }

    if (tasksInStore.REPLY_TENTATIVE && tasksInStore.REPLY_TENTATIVE.status != Status.NotSelected) {
        tasks.push(tasksInStore.REPLY_TENTATIVE);
        isDone = isDone && tasksInStore.REPLY_TENTATIVE.status == Status.Complete;
    }

    if (tasksInStore.BLOCK_CALENDAR && tasksInStore.BLOCK_CALENDAR.status != Status.NotSelected) {
        tasks.push(tasksInStore.BLOCK_CALENDAR);
        isDone = isDone && tasksInStore.BLOCK_CALENDAR.status == Status.Complete;
    }

    const Icon = ({status, size} : {status: Status, size: number}) => {

        // status = Status.Loading;

        if (status == Status.Loading) {
            return <Styled.Spinner size={size}/>
        } else if (status == Status.NotStarted) {
            return <FaCheckCircle size={size} color="lightgrey"/>
        } else if (status == Status.Complete) {
            return <FaCheckCircle size={size} color="green"/>
        } else { // error
            return <FaTimesCircle size={size} color="red" />
        }
    }

    const handleSuccessClose = () => {
        setCalendarPanelClose();
        setHideUpdatePanel(true);
    }

    return (
        <CoherenceModal
            modalWidth="small"
            modalHeight="responsive"
            isOpen={!hideUpdatePanel}
            onDismiss={() => setHideUpdatePanel(true)}
            title={isDone ? "Done " : "Updating"}
            styles={{
                subtitle: {
                    marginTop: "1rem"
                },
                contentContainer: {
                    padding: "0rem 2rem 2rem 2rem"
                }
            }}
        >
            <Styled.UpdateContent>
                {tasks.map((t, i) => {
                    return (
                        <Styled.TaskRow>
                            <div><Icon status={t.status} size={28} /></div>
                            <Styled.StatusText>{t.text}</Styled.StatusText>
                        </Styled.TaskRow>
                    )
                })}
            </Styled.UpdateContent>

            <div>{
                isDone ? 
                    <Styled.LowerContainer>
                        <PrimaryButton
                            text='Dismiss'
                            ariaLabel='Dismiss'
                            onClick={handleSuccessClose}
                        /> 
                    </Styled.LowerContainer> : 
                    <Styled.LowerContainer>
                        <Styled.WarningText>Note: Closing this dialog, closing this page, or refreshing this page will cancel the calendar update process.</Styled.WarningText>
                        <DefaultButton
                            text='Close'
                            ariaLabel='Close'
                            onClick={() => setHideUpdatePanel(true)}
                        /> 
                    </Styled.LowerContainer>
                }
            </div>
        </CoherenceModal>
    )
}

export default UpdatePanel;
