import { ReactElement } from 'react';
import * as React from 'react';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';

interface UndoPanelProps {
    onConfirm: () => void,
    onCancel: () => void,
}

// Fly out panel to confirm user wants to undo all changes
export const UndoPanel = (props: UndoPanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    return (
        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Confirmation'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >
            <p>
                You have made changes that have not been submitted. Are you sure you want to undo all changes?
            </p>

            <DefaultButton
                text='Cancel'
                title='Cancel'
                usageEvent={{ feature: 'GHC', subFeature: 'UndoAllChanges.Cancel', featureLocation: 'Admin' }}
                style={{ marginRight: 10, marginTop: 20 }}
                onClick={props.onCancel}
            />

            <DefaultButton
                text='Confirm'
                title='Confirm'
                usageEvent={{ feature: 'GHC', subFeature: 'UndoAllChanges.Confirm', featureLocation: 'Admin' }}
                style={{ marginTop: 20, color: 'white', backgroundColor: '#e50000', borderColor: '#e50000' }}
                onClick={props.onConfirm}
            />
        </CoherencePanel>
    );
}