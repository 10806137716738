import {
    IHolidayCompany,
    IHolidayCountry,
    IHoliday,
    IHolidayRegion,
    IHolidaySubArea,
    IPerson,
    IHolidayDomainData,
    IHolidaysCustomText,
    IHolidayYearSet,
    IHolidayResponse
} from './GlobalHolidaysService.types';
import { IHttpClient } from '@micro-frontend-react/employee-experience';
import ServiceInterface from './ServiceInterface';

const requestDefaults = {
    resource: `${__HR_RESOURCE_URI__}`,
};

import getByCompanySubAreaMockData from '../Data/getByCompanySubArea'; 
import includeDomainData from '../Data/includeDomainData';
import { SCOPES } from './ServiceUtils';
export default class GlobalHolidaysService implements ServiceInterface {
    _httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this._httpClient = httpClient;
    }

    public getMockHolidayDomainData = () : any => {
        return includeDomainData as IHolidayDomainData;
    }

    public getMockHolidays = () : IHoliday[] => {
        return getByCompanySubAreaMockData as IHoliday[];
    }

    public getPersonUpn = async (): Promise<string> => {
        const { data } = await Promise.resolve(
            this._httpClient.request({
                url: 'https://graph.microsoft.com/v1.0/me',
                resource: 'https://graph.microsoft.com',
            })
        );
        return (data as IPerson).userPrincipalName;
    };

    public getHolidayDomainData = async (): Promise<IHolidayDomainData> => {
        try {

            const upn = await this.getPersonUpn();

            if (!upn) {
                throw new Error ("Cannot find UPN");
            }

            const holidayDomainData: IHolidayDomainData = await this._httpClient
            .request({
                ...requestDefaults,
                url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/${upn}/getByUpn/includedomaindata`,
            })
            .then((result) => result.data as IHolidayDomainData);
            
            return holidayDomainData;

        } catch (err) {
            console.error(err);
            return null as IHolidayDomainData;
        }
    };

    public getRetailHolidayDomainData = async (): Promise<IHolidayDomainData> => {
        try {

            const upn = await this.getPersonUpn();

            if (!upn) {
                throw new Error ("Cannot find UPN");
            }

            const holidayDomainData: IHolidayDomainData = await this._httpClient
            .request({
                ...requestDefaults,
                url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/retail/${upn}/getByUpn/includedomaindata`,
            })
            .then((result) => result.data as IHolidayDomainData);
            
            return holidayDomainData;

        } catch (err) {
            console.error(err);
            return null as IHolidayDomainData;
        }
    };

    public getSubAreas = async (companyCode: string): Promise<IHolidaySubArea[]> => {
        try {

            const subAreas = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/domaindata/subAreas/${companyCode}/getByCompanyCode`,
                }).then((result) => result.data as IHolidaySubArea[]));
                
            return subAreas;

        } catch (err) {
            console.error(err);
            return [] as IHolidaySubArea[];
        }
    }

    public getHolidays = async (companyCode: string, subAreaCode: string): Promise<IHoliday[]> => {
        let corporateHolidaysPromise = this.getCorporateHolidays(companyCode, subAreaCode);
        let retailHolidaysPromise = this.getRetailHolidays(companyCode, subAreaCode);

        const bothResults = await Promise.all([corporateHolidaysPromise, retailHolidaysPromise]);

        let corporateHolidays = bothResults[0];
        let retailHolidays = bothResults[1];

        // Deduplicate holidays
        const allHolidays = [...corporateHolidays];
        retailHolidays.map((retailHoliday : IHoliday) => {
            if (!corporateHolidays.some((corporateHoliday : IHoliday) => {
                return corporateHoliday.PublicHolidayDate === retailHoliday.PublicHolidayDate
                    && corporateHoliday.PublicHolidayName === retailHoliday.PublicHolidayName
            })) {
                allHolidays.push(retailHoliday);
            }
        });

        return allHolidays;
    };

    public getCorporateHolidays = async (companyCode: string, subAreaCode: string): Promise<IHoliday[]> => {
        try {
         
            const {
                data: { Holidays },
            } = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/${companyCode}/${subAreaCode}/getByCompanySubArea`,
                })
            );
        
            return Holidays as IHoliday[];

        } catch (err) {
            console.error(err);
            return [] as IHoliday[];
        }
    };

    public getRetailHolidays = async (companyCode: string, subAreaCode: string): Promise<IHoliday[]> => {
        try {
         
            const {
                data: { Holidays },
            } = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/retail/${companyCode}/${subAreaCode}/getByCompanySubArea`,
                })
            );
    
            return Holidays as IHoliday[];

        } catch (err) {
            console.error(err);
            return [] as IHoliday[];
        }
    };

     // Gets non MsVacation holidays from table storage
     public getNonMsVacationHolidays = async (companycode: string): Promise<IHolidayYearSet[]> => {
        try {

            const {
                data: { Holidays },
            } = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/${companycode}/getNonMsVacationHolidays`
                })
            );

            return Holidays as IHolidayYearSet[];

        } catch (err) {
            console.error(err);
            return [] as IHolidayYearSet[];
        }
    };

    // Posts non MsVacation holidays to table storage
    public postUpdatedHolidays = async (companycode: string, countrycode: string, holidays: IHoliday[]): Promise<IHolidayResponse> => {
        try {   

            const response = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    method: 'post',
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/submitHolidays/${companycode}/${countrycode}`,
                    data: holidays
                })
            );

            return response.data as IHolidayResponse;
            
        } catch (err) {
            console.error(err);
            return {
                Success: false
            };
        }
    }

    // Deletes company code from non MsVacation storage
    public deleteCompanyCode = async (companycode: string, countrycode: string): Promise<IHolidayResponse> => {
        try {

            const response = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    method: 'delete',
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/deleteNonMsVacationCompanyCode/${companycode}/${countrycode}`,
                })
            );

            return response.data as IHolidayResponse;
        
        } catch (err) {
            console.error(err);
            return {
                Success: false
            };
        }
    }

    // Syncs holiday changes to cached holidays
    public syncHolidayData = async () : Promise<IHolidayResponse> => {
        try {

            const response = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    method: 'post',
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/refreshCacheHolidays`
                })
            );

            return response.data as IHolidayResponse;

        } catch (err) {
            console.error(err);
            return {
                Success: false
            };
        }
    }

    public getHolidaysCustomText = async () : Promise<IHolidaysCustomText[]> =>  {
        try {
            const res = await this._httpClient.request({
                ...requestDefaults,
                url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/GlobalCountryHolidays/getHolidaysTextMapping`,
            });

            return res.data as IHolidaysCustomText[];

        } catch (err) {
            console.error(err);
            return [] as IHolidaysCustomText[];
        }
    }

    public convertToRegions = (
        companyCodes: IHolidayCompany[],
        countryCodes: IHolidayCountry[],
        subAreaCodes: IHolidaySubArea[]
    ): IHolidayRegion[] => {
        const companyCodesMap: { [key: string]: string } = this.convertArrayToObj(
            companyCodes,
            'CompanyCode',
            'CountryCode'
        );
        const countryCodesMap: { [key: string]: string } = this.convertArrayToObj(
            countryCodes,
            'CountryCode',
            'CountryName'
        );

        const subAreasExistForCompanyCode: { [key: string]: boolean } = {};

        const regions: IHolidayRegion[] = subAreaCodes.map((subArea: IHolidaySubArea) => {
            const countryCode = companyCodesMap[subArea.CompanyCode];
            const countryName = countryCodesMap[countryCode];

            subAreasExistForCompanyCode[subArea.CompanyCode] = true;

            return {
                CompanyCode: subArea.CompanyCode,
                CountryCode: countryCode,
                CountryName: countryName,
                SubAreaCode: subArea.PersonnelSubAreaCode,
                SubAreaName: subArea.PersonnelSubAreaName,
            };
        });

        companyCodes.map((company: IHolidayCompany) => {
            const countryCode = companyCodesMap[company.CompanyCode];
            const countryName = countryCodesMap[countryCode];

            if (!subAreasExistForCompanyCode[company.CompanyCode]) {
                regions.push({
                    CompanyCode: company.CompanyCode,
                    CountryCode: countryCode,
                    CountryName: countryName,
                    SubAreaCode: '0',
                    SubAreaName: '',
                });
            }
        });

        return regions;
    };

    private convertArrayToObj = (
        arrayOfObjects: any[],
        keyName: string,
        valueName: string
    ): { [key: string]: string } => {
        return arrayOfObjects.reduce(
            (acc: any[], item: any) => ({
                ...acc,
                [item[keyName]]: item[valueName],
            }),
            {}
        );
    };
}

export const getHolidayListViewImageUrl = (holiday: IHoliday, holidayInPast: boolean): {main: string, placeholder: string} => {
    let holidayImageUrl : string;
    let placeHolderImageUrl = 'images/HolidayPlaceholderGS.png'
    
    if (!holiday) {
        return {
            main: placeHolderImageUrl,
            placeholder: placeHolderImageUrl
        }
    }

    if (holidayInPast) {
        holidayImageUrl = holiday.GrayscaledListViewImageUrl;
        placeHolderImageUrl = 'images/HolidayPlaceholderGS.png'
    } else {
        holidayImageUrl = holiday.ListViewImageUrl;
        placeHolderImageUrl = 'images/HolidayPlaceholder.png'
    }

    return {
        main: holidayImageUrl,
        placeholder: placeHolderImageUrl
    }

};

export const getHolidayRegImageUrl = (holiday: IHoliday): {main: string, placeholder: string} => {
    let holidayImageUrl = 'images/HolidayPlaceholder.png';
    if (holiday && holiday.RegImageUrl) {
        holidayImageUrl = holiday.RegImageUrl;
    }
    
    return {
        main: holidayImageUrl,
        placeholder: 'images/HolidayPlaceholder.png'
    }
};
