import { ReactElement, useEffect, useState } from 'react';
import * as React from 'react';
import { ErrorStateComponent, ErrorStateIconType } from '@coherence-design-system/controls';
import { usePageTracking } from '@micro-frontend-react/employee-experience/lib/usePageTracking';
import { usePageTitle } from '@micro-frontend-react/employee-experience/lib/usePageTitle';
import { Stack, IDropdownOption, MessageBar, MessageBarType } from '@fluentui/react';
import { HolidayOptionsMenu } from './Components/HolidayOptionsMenu';
import { HolidayManager } from './Components/HolidayManager';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import AdminService from '../../Services/AdminService';
import GlobalHolidaysService from '../../Services/GlobalHolidaysService';
import { IHolidayRegion, IHolidayYearSet, IHolidaySubArea } from '../../Services/GlobalHolidaysService.types';
import { Page, PageHeading, PageHeadingCaption  } from '../../Shared/Layout.styled';
import { EventType, UsageEventName, UserEvent } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';

export const AdminPageUserEvent: Partial<UserEvent> = {
    feature: 'GCH',
    type: EventType.User
};

export const AdminPage = (): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    usePageTitle(`${__APP_NAME__} Admin Portal`);
    const pageEvent = { ...AdminPageUserEvent, subFeature: 'Admin', eventName: UsageEventName.PageLoad };
    // @ts-ignore
    usePageTracking(pageEvent);

    const [allowedAdmin, setAllowedAdmin] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [messageText, setMessageText] = useState<string>('');
    const [isError, setIsError] = useState<boolean>(false);

    const [selectedRegion, setSelectedRegion] = useState<IHolidayRegion>();
    const [isNewRegion, setIsNewRegion] = useState<boolean>(false);
    const [displayHolidayManager, setDisplayHolidayManager] = useState<boolean>(false);
    const [holidaysList, setHolidaysList] = useState<IHolidayYearSet[]>([]);
    const [subAreaList, setSubAreaList] = useState<IHolidaySubArea[]>([]);

    const holidayTypes : IDropdownOption[] = [
        { key: 'corporate', text: 'Corporate holiday' },
        { key: 'retail', text: 'Direct Sales and Support holiday' },
        { key: 'both', text: 'Both'}
    ];

    const { httpClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const adminService = new AdminService(httpClient);
    const globalHolidaysService = new GlobalHolidaysService(httpClient);

    useEffect(() => {
        adminService.getPermissions()
            .then((results) => {setAllowedAdmin(results.Admin); setLoading(false);})
            .catch((err) => {console.error("err: ", err); setAllowedAdmin(false); setLoading(false);});
    }, []);

    // Handles progression from HolidayOptionsMenu to HolidayManager by getting holidays
    // and sub areas
    const onMenuNext = () => {
        const event: UserEvent = {
            feature: 'GCH',
            subFeature: 'SelectCompanyCode',
            featureLocation: 'Admin',
            eventName: UsageEventName.ButtonClicked,
            type: EventType.User,
        };
        telemetryClient.trackEvent(event, {
            CompanyCode: selectedRegion.CompanyCode
        });
        
        globalHolidaysService.getNonMsVacationHolidays(selectedRegion.CompanyCode)
            .then((results) => {
                setHolidaysList(results);
                setDisplayHolidayManager(true);
                globalHolidaysService.getSubAreas(selectedRegion.CompanyCode)
                    .then((result: IHolidaySubArea[]) => setSubAreaList(result))
                    .catch((err) => {console.error("err: ", err)});
            })
            .catch((err) => {console.error("err: ", err)});
    }

    // When user returns to menu, resets selections
    const onReset = () => {
        const event: UserEvent = {
            feature: 'GCH',
            subFeature: 'ResetMenu',
            featureLocation: 'Admin',
            eventName: UsageEventName.ButtonClicked,
            type: EventType.User,
        };
        telemetryClient.trackEvent(event);
        
        setDisplayHolidayManager(false);
        setHolidaysList([]);
    }

    // Renders menu for selecting a managed company code to edit holidays for
    // Includes option to add a new managed company code out of non managed codes
    const renderHolidayOptionsMenu = () => {
        return (
            <HolidayOptionsMenu
                onChange={(region, isNew) => {
                    setSelectedRegion(region);
                    setIsNewRegion(isNew);
                }}
                onNext={onMenuNext}
                globalHolidaysService={globalHolidaysService}
                displayMessage={(message: string, isError: boolean) => {
                    setMessageText(message);
                    setIsError(isError);
                }}
            />
        )
    }
    
    // Renders table of holidays and toolbar for making and submitting modifications
    const renderHolidayManager = () => {
        return (
            <HolidayManager
                region={selectedRegion}
                isNew={isNewRegion}
                holidays={holidaysList}
                holidayTypes={holidayTypes}
                subAreas={subAreaList}
                globalHolidaysService={globalHolidaysService}
                onBack={onReset}
                displayMessage={(message: string, isError: boolean) => {
                    setMessageText(message);
                    setIsError(isError);
                }}
            />
        );
    }

    // Message bar to display errors during admin processes 
    const renderMessageBar = () => {
        if (messageText !== '') {
            return (
                <MessageBar 
                    messageBarType={ isError ? MessageBarType.error : MessageBarType.success }
                    dismissButtonAriaLabel='Dismiss Message'
                    onDismiss={() => {setMessageText('')}}>
                    {messageText}
                </MessageBar>
            )
        }
    }

    return (
        <> 
            <div role="alert">
                { renderMessageBar() }
            </div>

            <Page>
                { !loading && !allowedAdmin && (
                    <ErrorStateComponent
                        iconType={ErrorStateIconType.OrangeCapAndCat}
                        headingText='You do not have permission to view this page'
                    />
                )}

                { !loading && allowedAdmin && (
                    <Stack>
                        <Stack.Item grow={1}>
                            <PageHeading>
                                Manage Holidays
                            </PageHeading>
                        </Stack.Item>
            
                        <Stack.Item>
                            <PageHeadingCaption>
                                Add, modify, and delete holidays in different company codes.
                            </PageHeadingCaption>
                        </Stack.Item>
        
                        <Stack.Item>
                            {!displayHolidayManager && (renderHolidayOptionsMenu())}
                            {displayHolidayManager && (renderHolidayManager())}  
                        </Stack.Item>
                    </Stack>
                )}
            </Page>
        </>
    )
}
