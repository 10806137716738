interface HolidayListForm {
    itemCount: number
}

interface HolidayTabForm {
    yearsCount: number
}

interface HolidayItemTextForm {
    holidayInPast: boolean
}

export const HolidayList = styled.div<HolidayListForm>`
    column-count: ${props => props.itemCount >= 10 ? '2' : '1'};

    @media (max-width: 1024px) {
        column-count: 1
    }
`;

export const ControlsRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    flex-direction: row;
    margin-bottom: 16px;

    @media (max-width: 700px) {
        flex-direction: column-reverse;
    }
`;

export const HolidayYearTabContainer = styled.div`
    display: flex;
`;

export const HolidayYearTabs = styled.div<HolidayTabForm>`
    min-width: ${props => `${props.yearsCount * 60}px`}
`;

export const HolidaysTypeSpan = styled.span`
    display: flex;
    align-items: center;
`;

export const HolidayItem = styled.div`
    break-inside: avoid;
    display: flex;
    margin-bottom: 24px;
`;

export const HolidayItemText = styled.div<HolidayItemTextForm>`

    ${props => !props.holidayInPast ? '' : `
        opacity: 0.7;
    `}

    > * {
        margin-bottom: 5px;
    }
`;

export const HolidayImage = styled.img`
    width: 100%;
`;

export const HolidayImageWrapper = styled.div`
    height: 100%;
    width: 100%;
    background-color: #F8F8F8;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
`;

export const HolidayIconWrapper = styled.div`
    height: 100px;
    width: 100px;
    flex-shrink: 0;
    margin-right: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const HolidayItemTitle = styled.div`
    font-weight: bold;
    font-size: 18px;
`;

export const CalendarAction = styled.button`
    display: flex;
    align-items: center;
    background: transparent;
    color: #0078D4;
    line-height: 20px;
    font-size: 14px;
    border: none;
    padding: 0;

    .textLeft {
        margin-right: 6px;
    }

    .textRight {
        margin-left: 6px;
    }
`;