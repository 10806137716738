import * as React from 'react';
import { HeaderRoot } from './Header.styled';
import { CoherenceHeader } from '@coherence-design-system/controls/lib/header/CoherenceHeader';
import {
  SearchSettings,
  ICoherenceHeaderProps,
  FarRightNotificationPanelProps,
  FarRightSettingsPanelProps,
  FarRightFeedbackPanelProps,
  FarRightProfilePanelProps,
} from '@coherence-design-system/controls/lib/header/CoherenceHeader.types';
import { useUser } from '@micro-frontend-react/employee-experience/lib/useUser';
import { useGraphPhoto } from '@micro-frontend-react/employee-experience/lib/useGraphPhoto';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { UserEvent, EventType, UsageEventName } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { HeaderPanel } from './Header.types';
import { FeedbackAgeGroup, FeedbackAuthenticationType, FeedbackPolicyValue, FeedbackUiType, IFeedbackInitOptions, OCVInitialization, initializeOCVFeedback } from '@coherence-design-system/controls';

export function Header(props: ICoherenceHeaderProps): React.ReactElement {
  const { farRightSettings, searchSettings, ...otherProps } = props;
  const { telemetryClient, authClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
  const [ocvButtonIsFocused, setOcvButtonIsFocused] = React.useState(false);
  const user = useUser();
  const photo = useGraphPhoto();

  const getTenantIdFromOid = (oid: string) => {
    if(!oid) return '';
    else {
      const tenantId = oid.split('.')[1]
      return tenantId;
    }
  }

  const feedbackOptions: IFeedbackInitOptions = {
    // Please refer to our document for more details on FeedbackInitOptions
    // Or see the below link for the completed parameters sample
    // https://office.visualstudio.com/OC/_git/M365AdminUX?path=/docs/centro/consume/hvcs/ocv-inapp-feedback-example/react-sample/src/inapp-feedback/defaultFeedbackOptions.ts
    appId: __OCV_APP_ID__,
    clientName: __OCV_APP_NAME__,
    authenticationType: FeedbackAuthenticationType.AAD,
    ageGroup: FeedbackAgeGroup.Adult,
    callbackFunctions: {
      onDismiss: (_isFeedbackSent?: boolean) => {
        console.log('Dismissing OCV panel');
        _handleOcvButtonFocusState();
      },
      onError: (_isFeedbackSent?: string | undefined) => {
        console.log('OCV panel has an error');
        _handleOcvButtonFocusState();
      },
    },
    feedbackConfig: {
      feedbackUiType: FeedbackUiType.SidePane,
      isDisplayed: false,
      isEmailCollectionEnabled: true,
      isFileUploadEnabled: true,
      isScreenshotEnabled: true,
      isScreenRecordingEnabled: true,
      complianceChecks: { // Required for AAD authenticationType
        connectedExperiences: FeedbackPolicyValue.Enabled,
        policyAllowContact: FeedbackPolicyValue.Enabled,
        policyAllowContent: FeedbackPolicyValue.Enabled,
        policyAllowFeedback: FeedbackPolicyValue.Enabled,
        policyAllowScreenshot: FeedbackPolicyValue.Enabled,
        policyAllowSurvey: FeedbackPolicyValue.Enabled
      },
    },
    themeOptions: { baseTheme: 'WindowsLight' }, // Use the theme that suits your app
    tenantId: getTenantIdFromOid(user?.oid) || 'd0000000-0000-0000-0000-000000000000',
    userId: user?.id || 'loggableUserId',
  };

  const searchConfig: SearchSettings | undefined = searchSettings;
  if (searchConfig) {
    const originalOnSearch = searchConfig.onSearch;
    searchConfig.onSearch = (searchTerm: string): void => {
      if (!originalOnSearch) return;

      const searchEvent: UserEvent = {
        subFeature: 'Header.Search',
        businessTransactionId: searchTerm,
        type: EventType.User,
        eventName: UsageEventName.TextChanged,
      };
      telemetryClient.trackEvent(searchEvent);

      originalOnSearch(searchTerm);
    };
  }

  const handleLogOutClicked = (): void => {
    const logout: UserEvent = {
      subFeature: 'Header.Logout',
      type: EventType.User,
      eventName: UsageEventName.ButtonClicked,
    };
    telemetryClient.trackEvent(logout);

    authClient.logOut().catch();
  };

  const getPanelOpenHandler = (panel: HeaderPanel): (() => void) => {
    return (): void => {
      const panelEvent: UserEvent = {
        subFeature: `Header.${panel.toString()}`,
        type: EventType.User,
        eventName: UsageEventName.PanelOpened,
        businessTransactionId: panel.toString(),
      };
      telemetryClient.trackEvent(panelEvent);
      telemetryClient.startTrackPage(panel);
    };
  };

  const getPanelDismissHandler = (panel: HeaderPanel): (() => void) => {
    return (): void => {
      const panelEvent: UserEvent = {
        subFeature: `Header.${panel.toString()}`,
        type: EventType.User,
        eventName: UsageEventName.PanelClosed,
        businessTransactionId: panel.toString(),
      };
      telemetryClient.trackEvent(panelEvent);
      telemetryClient.stopTrackPage(panel);
    };
  };

  const notificationConfig: FarRightNotificationPanelProps | undefined = farRightSettings?.notificationsSettings;
  if (notificationConfig) {
    notificationConfig.panelSettings.onOpened = getPanelOpenHandler(HeaderPanel.NotificationPanel);
    notificationConfig.panelSettings.onDismissed = getPanelDismissHandler(HeaderPanel.NotificationPanel);
  }

  const settingsConfig: FarRightSettingsPanelProps | undefined = farRightSettings?.settingsSettings;
  if (settingsConfig) {
    settingsConfig.panelSettings.onOpened = getPanelOpenHandler(HeaderPanel.SettingsPanel);
    settingsConfig.panelSettings.onDismissed = getPanelDismissHandler(HeaderPanel.SettingsPanel);
  }

  const helpConfig: FarRightSettingsPanelProps | undefined = farRightSettings?.helpSettings;
  if (helpConfig) {
    helpConfig.panelSettings.onOpened = getPanelOpenHandler(HeaderPanel.HelpPanel);
    helpConfig.panelSettings.onDismissed = getPanelDismissHandler(HeaderPanel.HelpPanel);
  }

  //Feedback Config handled directly here due to state concerns
  const feedbackConfig: FarRightFeedbackPanelProps | undefined = {
    buttonSettings: {
      title: 'Feedback',
      ariaLabel: 'Feedback',
    },
    panelSettings: {
      ocvButtonIsFocused: ocvButtonIsFocused,
      onClick: () => {
        setOcvButtonIsFocused(true);
        return true;
      },
      launchOptions: {
        categories: {
          show: false,
          customCategories: [],
        },
      },
      ocvInitOptions: feedbackOptions,
    },
  };

  const profileConfig: FarRightProfilePanelProps = {
    ...farRightSettings?.profileSettings,
    panelSettings: {
      ...farRightSettings?.profileSettings?.panelSettings,
      logOutLink: 'javascript:void(0);',
      fullName: user?.name ?? '',
      emailAddress: user?.email ?? '',
      imageUrl: photo || undefined,
      onLogOut: handleLogOutClicked,
      onOpened: getPanelOpenHandler(HeaderPanel.ProfilePanel),
      onDismissed: getPanelDismissHandler(HeaderPanel.ProfilePanel),
    },
  };

  const _handleOcvButtonFocusState = () => {
    const overflowButton = document.getElementById('coher-header-overflow-button-id');
    if (overflowButton) {
      // set focus back to overflow button
      overflowButton.focus();
    } else {
      // set focus back to OCV button
      const ocvButton = document.getElementById('coher-header-ocv-button-id');
      if (ocvButton) {
        ocvButton.focus();
      }
    }

    setOcvButtonIsFocused(false);
  };

  const ocvInit: OCVInitialization = {
    appId: __OCV_APP_ID__,
    env: process.env.NODE_ENV === 'production' ? 'Prod' : 'Int',
    scriptUrl: '/ocv/officebrowserfeedback.min.js',
    stylesUrl: '/ocv/officebrowserfeedback.min.css',
    intlUrl: '/intl/',
    onError: (error: string) => {
      console.log('An error has occured during submission: ' + error);
      _handleOcvButtonFocusState();
    },
    telemetryGroup: { featureArea: 'Portal site' },
    onDismiss: (submitted: boolean) => {
      if (submitted) {
        console.log('Your feedback has been submitted');
      }
      _handleOcvButtonFocusState();
    },
  };

  initializeOCVFeedback(ocvInit);

  return (
    <HeaderRoot>
      <CoherenceHeader
        {...otherProps}
        searchSettings={searchConfig}
        farRightSettings={{
          ...farRightSettings,
          notificationsSettings: notificationConfig,
          settingsSettings: settingsConfig,
          helpSettings: helpConfig,
          feedbackSettings: feedbackConfig,
          profileSettings: profileConfig,
        }}
      />
    </HeaderRoot>
  );
}
