import { CoherenceModal, CoherencePanel, CoherencePanelSize }from '@coherence-design-system/controls';
import { DefaultButton, PrimaryButton, Toggle } from '@fluentui/react';
import * as React from 'react';
import { ReactElement, useState } from 'react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import * as Styled from './CalendarPanel.styled';
import { getHolidayRegImageUrl } from '../../Services/GlobalHolidaysService';
import { IHoliday, IHolidaysRenderData } from '../../Services/GlobalHolidaysService.types';
import SVG from 'react-inlinesvg';
import { ITask, CalendarContext, reducer as calendarReducer, initialState as calendarInitialState, CalendarManager, ICalendarPayload } from '../../State/Calendar';
import CalendarService from '../../Services/CalendarService';
import { AppContext } from '../../StateManagement';
import UpdatePanel from './UpdatePanel';
import { UserEvent, EventType, UsageEventName } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import * as moment from 'moment';

interface CalendarPanelProps {
    open: boolean;
    holidaysRenderData?: IHolidaysRenderData;
    selectedYear?: number;
    selectedHoliday?: IHoliday;
    onSuccess: () => void;
    onClose: () => void;
}

export const CalendarPanel = (props: CalendarPanelProps): ReactElement => {

    const { httpClient, telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { store: appStore, dispatch: appDispatch } = React.useContext(AppContext);
    const calendarService = new CalendarService(httpClient);

    const [cancelMeetings, setCancelMeetings] = useState<boolean>(false);
    const [replyTentative, setReplyTentative] = useState<boolean>(false);
    const [blockCalendar, setBlockCalendar] = useState<boolean>(false);

    const [hideDialog, setHideDialog] = useState<boolean>(true);
    const [hideUpdatePanel, setHideUpdatePanel] = useState<boolean>(true);

    const [calendarStore, calendarDispatch] = React.useReducer(calendarReducer, calendarInitialState);

    const calendarManager = new CalendarManager(calendarService, calendarStore, calendarDispatch, appStore, appDispatch, telemetryClient);

    if (!props.selectedYear && !props.selectedHoliday) return null;

    const CalendarHeader = () => {
        const images = getHolidayRegImageUrl(props.selectedHoliday);

        const [imgSrc, setImgSrc] = useState<string>(images.main);
        const [errored, setErrored] = useState<boolean>(false);

        const onError = () => {
            if (!errored) {
                setImgSrc(images.placeholder);
                setErrored(true);
            }
        }

        if (props.selectedYear) {
            return (
                <Styled.ImageSection>
                    <Styled.HolidayImage src='./images/HolidayAddAll.svg' alt='Holiday Icon' />
                    <Styled.HolidayName>{props.selectedYear} Holidays</Styled.HolidayName>
                    <Styled.HolidayDescription>
                        We'll add all of the holidays to your Outlook calendar for the year.
                        You can choose how to update your calendar.
                    </Styled.HolidayDescription>
                </Styled.ImageSection>
            );
        } else if (props.selectedHoliday) {
            return (
                <Styled.ImageSection>
                    <Styled.HolidayImageWrapper>
                        {
                            imgSrc && imgSrc.endsWith(".png") ? <Styled.HolidayImage src={imgSrc} alt={props.selectedHoliday.PublicHolidayName + " Image"} onError={onError}/> :
                                <SVG width={180} height={180} src={imgSrc} onError={onError}/>
                        }
                    </Styled.HolidayImageWrapper>
                    <Styled.HolidayName>{props.selectedHoliday?.PublicHolidayName}</Styled.HolidayName>
                    <Styled.HolidayDescription>{props.selectedHoliday?.PublicHolidayDate}</Styled.HolidayDescription>
                </Styled.ImageSection>
            );
        }
    }


    const getRemainingHolidaysInYear = () : IHoliday[] => {
        let allHolidaysInCurrentYear = props.holidaysRenderData[props.selectedYear];

        return allHolidaysInCurrentYear.filter(h => {
            console.log("h: ", h);
            let endDate = h.PublicHolidayEndDate;
            let endDateUnix : number = moment(endDate, "YYYY-MM-DD").unix();
            let todayUnix = moment().unix();
            return endDateUnix > todayUnix;
        });

    }

    const handleSubmit = () => {
        setHideDialog(true);

        let holidays : IHoliday[] = [];

        if (props.selectedYear) {
            holidays = getRemainingHolidaysInYear();
        } else if (props.selectedHoliday) {
            holidays = [props.selectedHoliday];
        }

        const year = props.selectedYear;
        const selectedHoliday = props.selectedHoliday;

        const event: UserEvent = {
            feature: 'GCH',
            subFeature: selectedHoliday ? 'AddToCalendar' : 'AddAllToCalendar',
            featureLocation: 'Home',
            eventName: UsageEventName.ButtonClicked,
            type: EventType.User,
        };

        const payload : ICalendarPayload = {
            actions: {
                isBlockCalendar: blockCalendar,
                isReplyTentative: replyTentative,
                isCancelMeetings: cancelMeetings        
            },
            holidays: holidays
        }

        const customProperties = {
            year: year ? year.toString() : null,
            PublicHolidayDate: selectedHoliday ? selectedHoliday.PublicHolidayDate : null,
            PublicHolidayStartDate: selectedHoliday ? selectedHoliday.PublicHolidayStartDate : null,
            PublicHolidayEndDate: selectedHoliday ? selectedHoliday.PublicHolidayEndDate : null,
            PublicHolidayName: selectedHoliday ? selectedHoliday.PublicHolidayName : null,
            CountryName: selectedHoliday ? selectedHoliday.CountryName : null,
            CompanyCode: selectedHoliday ? selectedHoliday.CompanyCode : null,
            PersonnelSubAreaCode: selectedHoliday ? selectedHoliday.PersonnelSubAreaCode : null,
            PersonnelSubAreaName: selectedHoliday ? selectedHoliday.PersonnelSubAreaName : null,
            HolidayType: selectedHoliday ? selectedHoliday.HolidayType : null,
            Holidays: JSON.stringify(holidays),
            isBlockCalendar: blockCalendar.toString(), 
            isReplyTentative: replyTentative.toString(),
            isCancelMeetings: cancelMeetings.toString(),
        }

        telemetryClient.trackEvent(event, customProperties);

        calendarManager.manageCalendar(payload).then(res => {
            if (res) {
                props.onSuccess();
            }
        });

        setHideUpdatePanel(false);
    }

    const onRenderModalFooter = () => <Styled.ModalFooterContainer>
        <PrimaryButton
            text='Submit'
            ariaLabel='Submit'
            onClick={handleSubmit}
            disabled={!(replyTentative || blockCalendar || cancelMeetings)}
        />
        <DefaultButton
            text='Cancel'
            ariaLabel='Cancel'
            onClick={() => setHideDialog(true)}
        />
    </Styled.ModalFooterContainer>

    const RenderSaveWarning = () => {
        return (
            <CoherenceModal
                modalWidth="small"
                modalHeight="responsive"
                isOpen={!hideDialog}
                onDismiss={() => setHideDialog(true)}
                title={'Update Calendar?'}
                subtitle={'Are you sure you want to make changes to your calendar?'}
                onRenderFooter={onRenderModalFooter}
                styles={{
                    subtitle: {
                        marginTop: "1rem"
                    }
                }}
            />
        )
    };

    const handleCancelMeetingsChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked) {
            const event: UserEvent = {
                feature: 'GCH',
                subFeature: 'ToggleOnCancelMeetings',
                featureLocation: 'Home',
                eventName: UsageEventName.ButtonClicked,
                type: EventType.User,
            };
            telemetryClient.trackEvent(event);
        }
        setCancelMeetings(checked);
    }

    const handleReplyTentativeChange = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked) {
            const event: UserEvent = {
                feature: 'GCH',
                subFeature: 'ToggleOnReplyTentative',
                featureLocation: 'Home',
                eventName: UsageEventName.ButtonClicked,
                type: EventType.User,
            };
            telemetryClient.trackEvent(event);
        }
        setReplyTentative(checked);
    }

    const handleBlockCalendarChange =  (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        if (checked) {
            const event: UserEvent = {
                feature: 'GCH',
                subFeature: 'ToggleOnBlockCalendar',
                featureLocation: 'Home',
                eventName: UsageEventName.ButtonClicked,
                type: EventType.User,
            };
            telemetryClient.trackEvent(event);
        }
        setBlockCalendar(checked);
    }

    const handleMainSubmit = () => {
        if (blockCalendar || replyTentative || cancelMeetings) {
            setHideDialog(false);
        }
    };

    return (
        <CoherencePanel
            panelSize={CoherencePanelSize.medium}
            titleText='Manage out-of-office'
            onRenderFooter={{
                primaryButton: {
                    text: 'Submit out-of-office assist',
                    onAction: () => handleMainSubmit(),
                    disabled: !(cancelMeetings || replyTentative || blockCalendar)
                }
            }}
            isOpen={props.open}
            onDismiss={props.onClose}
            closeButtonAriaLabel="Close manage out-of-office calendar panel"
            telemetryHook={telemetryClient}
        >
            <CalendarContext.Provider value={{calendarStore, calendarDispatch, calendarManager}}>
                <Styled.CalendarPanel>
                    <CalendarHeader />
                    <div>
                        <Styled.SectionHeader>Calendar Assist</Styled.SectionHeader>
                        <div>
                            <Styled.ToggleSection>
                                <Styled.ToggleLabel>Cancel your meetings</Styled.ToggleLabel>
                                <Styled.ToggleOption>
                                    <Styled.ToggleDescription>
                                        <span>Cancel any meetings you organized occuring on the holiday and sets your out of office message.</span>
                                        <Styled.CancelWarning show={cancelMeetings}>
                                            * Meetings that span more than one day (like a two-day conference) will be cancelled for all days.
                                        </Styled.CancelWarning>
                                    </Styled.ToggleDescription>
                                    <Toggle onChange={handleCancelMeetingsChange}
                                        ariaLabel="Toggle to cancel meetings ocurring on the holiday" 
                                    />
                                </Styled.ToggleOption>
                            </Styled.ToggleSection>
                            <Styled.ToggleSection>
                                <Styled.ToggleLabel>Reply "Tentative" to meeting requests</Styled.ToggleLabel>
                                <Styled.ToggleOption>
                                    <Styled.ToggleDescription>Replies "Tentative" to all meetings occuring during the holiday and sets your out of office message.</Styled.ToggleDescription>
                                    <Toggle onChange={handleReplyTentativeChange} 
                                        ariaLabel="Toggle to reply 'Tentative' to meeting requests occuring on the holiday" 
                                    />
                                </Styled.ToggleOption>
                            </Styled.ToggleSection>
                            <Styled.ToggleSection>
                                <Styled.ToggleLabel>Block off your calendar</Styled.ToggleLabel>
                                <Styled.ToggleOption>
                                    <Styled.ToggleDescription>Set an "Out of office" event for "All day" during the holiday</Styled.ToggleDescription>
                                    <Toggle onChange={handleBlockCalendarChange}
                                        ariaLabel="Toggle to block calendar during the holiday" 
                                    />
                                </Styled.ToggleOption>
                            </Styled.ToggleSection>
                        </div>
                    </div>
                </Styled.CalendarPanel>
                <RenderSaveWarning />
                <UpdatePanel hideUpdatePanel={hideUpdatePanel} setHideUpdatePanel={setHideUpdatePanel} setCalendarPanelClose={props.onClose}/>
            </CalendarContext.Provider>
        </CoherencePanel>
    );
};
