import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import { GlobalHolidaysPage } from './GlobalHolidays/GlobalHolidaysPage';
import { AdminPage } from './GlobalHolidays/Admin/AdminPage';

export function Routes(): React.ReactElement {
    return (
        <Switch>
            <Route path="/" component={GlobalHolidaysPage} exact={true} />
            <Route path="/admin" component={AdminPage} exact={true} />
        </Switch>
    );
}