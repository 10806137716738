import { ReactElement, useState } from 'react';
import * as React from 'react';
import { UserEvent, UsageEventName, EventType } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';
import { CoherencePanel } from '@coherence-design-system/controls';
import { IHolidayEditable, IHolidayRegion } from '../../../Services/GlobalHolidaysService.types';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { Stack, Dropdown, IDropdownOption, TextField, DatePicker, DayOfWeek, FirstWeekOfYear } from '@fluentui/react';
import { InputTitle, InputContainer, Alert } from './Panel.styled';

// Fly out panel to edit a holiday
interface EditPanelProps {
    region: IHolidayRegion,
    holiday: IHolidayEditable,
    originalHoliday?: IHolidayEditable,  // null if holiday being edited is new, aka no original exists
    subAreas: IDropdownOption[],
    holidayTypes: IDropdownOption[],
    onConfirm: (newHoliday: IHolidayEditable) => void,
    onCancel: () => void,
}

export const EditPanel = (props: EditPanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [name, setName] = useState<string>(props.holiday.PublicHolidayName);
    const [subArea, setSubArea] = useState<IDropdownOption>({ key: props.holiday.PersonnelSubAreaCode, text: props.holiday.PersonnelSubAreaName });
    const [holidayType, setHolidayType] = useState<IDropdownOption>(getHolidayType(props.holiday));
    const [startDate, setStartDate] = useState<Date>(ingestDateString(props.holiday.PublicHolidayStartDate));
    const [endDate, setEndDate] = useState<Date>(ingestDateString(props.holiday.PublicHolidayEndDate));
    const [displayInvalidInputAlert, setDisplayInvalidInputAlert] = useState<boolean>(false);
    const [displayDateError, setDisplayDateError] = useState<boolean>(false);

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];

    const onPressConfirm = () => {
        if (name === "" || startDate == null || endDate == null) {
            setDisplayDateError(false);
            setDisplayInvalidInputAlert(true);
        } else if (endDate < startDate) {
            setDisplayInvalidInputAlert(false);
            setDisplayDateError(true);
        } else {
            const updatedHoliday: IHolidayEditable = {
                id: props.originalHoliday? props.originalHoliday.id : -1,
                isModified: checkDifference(),
                isDeleted: false,
                PublicHolidayDate: `${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`,
                PublicHolidayStartDate: formatDateToString(startDate),
                PublicHolidayEndDate: formatDateToString(endDate),
                PublicHolidayName: name,
                CountryName: props.region.CountryName,
                CountryCode: props.region.CountryCode,
                CompanyCode: props.region.CompanyCode,
                PersonnelSubAreaCode: subArea.key.toString(),
                PersonnelSubAreaName: subArea.text,
                ListViewImageUrl: null,
                RegImageUrl: null,
                GrayscaledListViewImageUrl: null,
                Corporate: holidayType.key === 'corporate' || holidayType.key === 'both',
                Retail: holidayType.key === 'retail' || holidayType.key === 'both',
            };
            props.onConfirm(updatedHoliday);
        }
    }

    const checkDifference = () : boolean => {
        return !props.originalHoliday || !(
            name === props.originalHoliday.PublicHolidayName
            && subArea.key === props.originalHoliday.PersonnelSubAreaCode
            && holidayType.key === getHolidayType(props.originalHoliday).key
            && formatDateToString(startDate) === props.originalHoliday.PublicHolidayStartDate
            && formatDateToString(endDate) === props.originalHoliday.PublicHolidayEndDate
        );
    }

    const onPressRevert = () => {
        setName(props.originalHoliday.PublicHolidayName);
        setSubArea({ key: props.originalHoliday.PersonnelSubAreaCode, text: props.originalHoliday.PersonnelSubAreaName });
        setHolidayType(getHolidayType(props.originalHoliday));
        setStartDate(ingestDateString(props.originalHoliday.PublicHolidayStartDate));
        setEndDate(ingestDateString(props.originalHoliday.PublicHolidayEndDate));
        setDisplayInvalidInputAlert(false);
    }

    const renderInvalidInputError = () => {
        return (
            <>
                { displayInvalidInputAlert && (
                    <Alert>Name, sub area, start date, and end date are all required.</Alert>
                )}
            </>
        );
    }

    const renderDateError = () => {
        return (
            <>
                { displayDateError && (
                    <Alert>End date cannot be before start date.</Alert>
                )}
            </>
        )
    }

    const renderSubAreaDropDown = () => {
        return (
            <>
                <InputTitle>Sub Area:</InputTitle>
                {
                    (props.subAreas.length > 0) && (
                        <InputContainer>
                            <Dropdown
                                selectedKey={subArea.key}
                                onChange={
                                    (e, option) => {
                                        const event: UserEvent = {
                                            feature: 'GCH',
                                            subFeature: 'EditHoliday.SelectSubArea',
                                            featureLocation: 'Admin',
                                            eventName: UsageEventName.DropdownSelected,
                                            type: EventType.User,
                                        };
                                        telemetryClient.trackEvent(event, {
                                            SubArea: option.text
                                        });
                                        setSubArea(option);
                                    }
                                }
                                options={[...props.subAreas, { key: '0', text: 'All sub areas' }]}
                                title='Sub Area Dropdown'
                                ariaLabel='Sub Area Dropdown'
                            />
                        </InputContainer>
                    )
                }
                {
                    (!props.subAreas.length) && (
                        <p>{subArea.text}</p>
                    )
                }
            </>
        );
    }

    return (
        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Edit this Holiday'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >
            <Stack>
                <Stack.Item>
                    Edit this holiday in <strong>{`${props.region.CountryName} (${props.region.CompanyCode})`}.</strong>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Name</InputTitle>
                    <InputContainer>
                        <TextField
                            placeholder='Enter a name'
                            ariaLabel='Enter a name'
                            value={name}
                            required={true}
                            onChange={(e, value) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'EditHoliday.SetName',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.TextChanged,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Name: value
                                });
                                setName(value);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    {renderSubAreaDropDown()}
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Holiday Type</InputTitle>
                    <InputContainer>
                        <Dropdown
                            selectedKey={holidayType.key}
                            onChange={ 
                                (e, option) => {
                                    const event: UserEvent = {
                                        feature: 'GCH',
                                        subFeature: 'EditHoliday.SelectHolidayType',
                                        featureLocation: 'Admin',
                                        eventName: UsageEventName.DropdownSelected,
                                        type: EventType.User,
                                    };
                                    telemetryClient.trackEvent(event, {
                                        HolidayType: option.text
                                    });
                                    setHolidayType(option);
                                }
                            }
                            options={props.holidayTypes}
                            title='Holiday Type Dropdown'
                            ariaLabel='Holiday Type Dropdown'
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Start Date</InputTitle>
                    <InputContainer>
                        <DatePicker
                            value={startDate}
                            firstDayOfWeek={DayOfWeek.Sunday}
                            firstWeekOfYear={FirstWeekOfYear.FirstDay}
                            showMonthPickerAsOverlay={true}
                            isRequired={true}
                            placeholder='Select a start date'
                            ariaLabel='Select a start date'
                            onSelectDate={(date) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'EditHoliday,SelectStartDate',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.DatePickerClicked,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Date: date
                                });
                                setStartDate(date);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>End Date</InputTitle>
                    <InputContainer>
                        <DatePicker
                            value={endDate}
                            firstDayOfWeek={DayOfWeek.Sunday}
                            firstWeekOfYear={FirstWeekOfYear.FirstDay}
                            showMonthPickerAsOverlay={true}
                            isRequired={true}
                            placeholder='Select an end date'
                            ariaLabel='Select an end date'
                            onSelectDate={(date) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'EditHoliday.SelectEndDate',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.DatePickerClicked,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Date: date
                                });
                                setEndDate(date);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <DefaultButton
                        text='Cancel'
                        title='Cancel'
                        usageEvent={{ feature: 'GHC', subFeature: 'EditHoliday.Cancel', featureLocation: 'Admin' }}
                        style={{ marginRight: 10, marginTop: 20 }}
                        onClick={props.onCancel}
                    />

                    {
                        (props.originalHoliday != null) && (
                            <DefaultButton
                                text='Revert to Original'
                                title='Revert to Original'
                                usageEvent={{ feature: 'GHC', subFeature: 'EditHoliday.Revert', featureLocation: 'Admin' }}
                                style={{ marginTop: 20, marginRight: 10, color: '#e50000', borderColor: '#e50000' }}
                                onClick={onPressRevert}
                            />
                        )
                    }

                    <DefaultButton
                        text='Confirm'
                        title='Confirm'
                        usageEvent={{ feature: 'GHC', subFeature: 'EditHoliday.Confirm', featureLocation: 'Admin' }}
                        style={{ marginTop: 20, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' }}
                        onClick={onPressConfirm}
                    />
                </Stack.Item>

                <Stack.Item>
                    <div role="alert">
                        {renderInvalidInputError()}
                        {renderDateError()}
                    </div>
                </Stack.Item>
            </Stack>
        </CoherencePanel>
    );
}

// Utilities
const getHolidayType = (holiday : IHolidayEditable) => {
    if (!holiday.Retail && holiday.Corporate ) {
        return { key: 'corporate', text: 'Corporate holiday' };
    } else if (holiday.Retail && !holiday.Corporate ) {
        return { key: 'retail', text: 'Direct Sales and Support holiday' };
    } else {
        return { key: 'both', text: 'Both'};
    }
}

const formatDateToString = (date: Date) => {
    let result = `${date.getFullYear()}-`;
    date.getMonth() + 1 < 10 ? result += `0` : null;
    result += `${date.getMonth() + 1}-`;
    date.getDate() < 10 ? result += `0` : null;
    result += `${date.getDate()} 00:00:00.000`;
    return result;
}

const ingestDateString = (date: string) => {    
    return new Date(parseInt(date.substring(0, 4), 10), // year
        (parseInt(date.substring(5, 7), 10) - 1), // month index
        parseInt(date.substring(8, 10), 10) // day
    );
}