import { CoherencePanel, CoherencePanelSize } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import * as React from 'react';
import { HelpContext } from '../../Shared/HelpContext';
import { IFaqDataEntry } from './HelpPanelBase';
import * as Styled from './Help.styled';

export function HelpPanel(props: IFaqDataEntry): React.ReactElement {
    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const { question, answer } = props;

    function renderAnswer(answer : string) {
        if (!answer || answer.length == 0) {
            return answer;
        }

        let isHtmlText = answer.startsWith("<") && answer.endsWith(">");
        
        if (isHtmlText) {
            let htmlObj = {
                __html: answer
            };

            return <span dangerouslySetInnerHTML={htmlObj} />
        } else {
            return answer
        }
    }

    return (
        <HelpContext.Consumer>
            {(context) => (
                <CoherencePanel
                    backButton={{
                        ariaLabel: 'Go back',
                        onClick: () => {
                            context.toggleShowHelpContextPanel(false);
                        },
                    }}
                    onDismiss={() => {
                        context.toggleShowHelpContextPanel(false);
                    }}
                    isOpen={context.showHelpContextPanel}
                    customWidth={`305px`}
                    isLightDismiss={true}
                    isBlocking={false}
                    titleText={question}
                    closeButtonAriaLabel="Close panel"
                    hasCloseButton={true}
                    overlayProps={{
                        isDarkThemed: true,
                    }}
                    telemetryHook={telemetryClient}
                >
                    {
                        answer.length > 1 ? 
                        <ol>
                            {answer.map((a, i) =>
                                <Styled.ListItem key={i}>{renderAnswer(a)}</Styled.ListItem>
                            )}
                        </ol> : 
                        <p>{renderAnswer(answer[0])}</p>                            
                    }
                </CoherencePanel>
            )}
        </HelpContext.Consumer>
    );
}
