export const DropdownContainer = styled.div`
    position: relative;
    min-width: 196px;
    max-width: 45%;
`;

export const Alert = styled.div`
    padding-top: 10px;
    color: red;
`;

export const LoadingContainer = styled.div`
    justify-content: left;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
`;

export const LoadingText = styled.div`
    font-size: 20px;
    font-weight: 600;
    margin-left: 10px;
`;