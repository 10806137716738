import * as React from 'react';
import { Link } from '@micro-frontend-react/employee-experience/lib/Link';
import { Label } from '@fluentui/react';
import * as styled from './Footer.styled';
import useWindowSize from 'react-use/lib/useWindowSize'

export function Footer(): React.ReactElement {
    const year = new Date().getFullYear();
    const { width, height } = useWindowSize();

    return (
        <styled.FooterRoot>
            <styled.Container>
                <styled.FooterMenus>
                    <styled.FooterMenuItem windowWidth={width} windowHeight={height}>
                        <Link
                            to={__URL_MS_PRIVACY_NOTICE__}
                            title="Microsoft data privacy notice (Opens in a new tab)"
                            target="_blank"
                            usageEvent={{
                                feature: 'EVL',
                                subFeature: 'Home.Footer.DataPrivacyLink',
                            }}
                        >
                            Microsoft data privacy notice
                        </Link>
                    </styled.FooterMenuItem>
                    <styled.FooterMenuItem windowWidth={width} windowHeight={height}>
                        <Link
                            to={__URL_MS_LEGAL_NOTICE__}
                            title="Legal Notice (Opens in a new tab)"
                            target="_blank"
                            usageEvent={{
                                feature: 'EVL',
                                subFeature: 'Home.Footer.LegalNoticeLink',
                            }}
                        >
                            Legal notice
                        </Link>
                    </styled.FooterMenuItem>
                    <styled.FooterMenuItem
                        arie-aria-roledescription={`${year} Microsoft`}
                        windowWidth={width}
                        windowHeight={height}
                        tabIndex={0}
                        style={{ marginLeft: 'auto' }}
                    >
                        <Label style={{ fontWeight: 400 }}>&copy; {year} Microsoft</Label>
                    </styled.FooterMenuItem>
                </styled.FooterMenus>
            </styled.Container>
        </styled.FooterRoot>
    );
}

