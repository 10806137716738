
const fullWidthBorder = `
    border-bottom: 1px solid #E5E5E5;

    &:first-child {
        border-top: 1px solid #E5E5E5;
    }

    @media (min-width: 320px) and (max-width: 479px) {
    }

    @media (min-width: 480px) and (max-width: 639px) {
    }

    @media (min-width: 640px) {
    }
`;

export const CalendarPanel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem;

    @media (min-width: 320px) and (max-width: 479px) {
        padding: 0;
    }

    @media (min-width: 480px) and (max-width: 639px) {
        padding: 0;
    }

    @media (min-width: 640px) {
        padding: 2rem;
    }

`;

export const ImageSection = styled.div`
    margin-bottom: 30px;
    text-align: center;
`;

export const HolidayImage = styled.img`
    height: 120px;
`;

export const HolidayImageWrapper = styled.div`
    height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HolidayName = styled.div`
    color: #000000;
    font-size: 22px;
    font-weight: bold;
    margin-bottom: 12px;
`;

export const HolidayDescription = styled.div`
    color: #6E6E6E;
    font-size: 13px;
`;

export const SectionHeader = styled.div`
    color: #6E6E6E;
    font-size: 15px;
    margin-bottom: 12px;
`;

export const ToggleSection = styled.div`
    padding: 18px 0px;    
    ${fullWidthBorder}
`;

export const ToggleLabel = styled.div`
    color: #000000;
    font-size: 15px;
    margin-bottom: 0.5rem;
`;

export const ToggleOption = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ToggleDescription = styled.div`
    color: #646464;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
`;

export const CancelWarning = styled.span<any>`
    color: #d30707;
    margin-top: 0.4rem;
    display: ${p => p.show ? "block" : "none"};
`;

export const UpdateContent = styled.div`
    background-color: 'light-blue'
    padding: 20px;
`;

export const UpdateEventRow = styled.div`
    display: flex;
    flex-direction: row;
`

export const ActionItemRow = styled.div`
    display: flex;
    align-items: center;
`

export const ActionIconContainer = styled.span`
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const ModalFooterContainer = styled.div`
    & > * {
        margin-right: 1rem;
    }
    display: flex;
    flex-direction: row;
    justify-content: end;
`;
