import * as React from 'react';
import { css, FlattenSimpleInterpolation } from 'styled-components';

export default function CustomText({ text }: {
    text: string
}) {

    // const sampleText = "Find <a href='https://microsoft.sharepoint.com/teams/Dev_HRWebUAT/sitePages/VacacionesPA.aspx'}>Current regulations | 1067</a> about annual vacation time (normativa legal vigente - vacaciones) in Microsoft Panama."

    const createMarkup = (text: string) => {
        return {
            __html: text
        };
    }

    return <div style={{
        display: text ? "block" : "none",
        marginTop: "16px"
    }}
        dangerouslySetInnerHTML={createMarkup(text)}
    />;
}
