import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { Header as EEHeader } from './HeaderBase/HeaderBase';
import * as React from 'react';
import { useState } from 'react';
import { HelpPanelBase } from '../../GlobalHolidays/Help/HelpPanelBase';
import { HelpContext, IHelpContext } from '../../Shared/HelpContext';

export function Header(): React.ReactElement {
    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const toggleShowHelpContextPanel = (valueSuccess: boolean) => {
        _setMetadataContext({ ..._metadataContext, showHelpContextPanel: valueSuccess });
    };

    const initState: IHelpContext = {
        showHelpContextPanel: false,
        toggleShowHelpContextPanel: toggleShowHelpContextPanel,
    };

    const [_metadataContext, _setMetadataContext] = useState<IHelpContext>(initState);

    return (
        <HelpContext.Provider value={_metadataContext}>
            <EEHeader
                headerLabel={__APP_NAME__}
                appNameSettings={{
                    label: __APP_NAME__,
                }}
                farRightSettings={{
                    helpSettings: {
                        panelSettings: {
                            onClick: undefined,
                            onOpen: () => {
                                _metadataContext.toggleShowHelpContextPanel(false);
                            },
                            body: <HelpPanelBase />,
                            isLightDismiss: true,
                            onDismiss: () => {
                                _metadataContext.toggleShowHelpContextPanel(false);
                            },
                            focusTrapZoneProps: {
                                forceFocusInsideTrap: false,
                            },
                            closeButtonAriaLabel: 'Close help panel',
                        },
                    },
                    feedbackSettings: undefined,
                    profileSettings: {
                        panelSettings: {
                            fullName: '',
                            emailAddress: '',
                            imageUrl: '',
                            logOutLink: 'Sign Out',
                            hideSignOutDivider: true,
                        },
                    },
                }}
            />
        </HelpContext.Provider>
    );
}
