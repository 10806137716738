import { ReactElement, useMemo, useEffect } from 'react';
import * as React from 'react';
import { SingleHolidayContainer, LargeCol, MedCol, SmallCol, HeaderContainer, LargeHeadCol, MedHeadCol, SmallHeadCol } from './SingleHoliday.styled';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { Icon } from '@fluentui/react';
import { IHolidayEditable } from '../../../Services/GlobalHolidaysService.types';

interface SingleHolidayProps {
    holiday: IHolidayEditable,
    onEdit: () => void,
    onDelete: () => void,
}

// Component for a single holiday's row in the HolidayManager table
export const SingleHoliday = (props: SingleHolidayProps): ReactElement => {

    return (
        <SingleHolidayContainer modified={props.holiday.isModified} deleted={props.holiday.isDeleted}>
            <MedCol headers='HolidayName'>
                {   // Bold when modified (styling done this way for accessibility)
                    props.holiday.isModified && !props.holiday.isDeleted && (
                    <strong>{props.holiday.PublicHolidayName}</strong>
                )}

                {   // Italic when deleted
                    props.holiday.isDeleted && (
                    <em>{props.holiday.PublicHolidayName}</em>
                )}

                {   // Normal when unchanged
                    !props.holiday.isModified && !props.holiday.isDeleted && (
                    <>{props.holiday.PublicHolidayName}</>
                )}
            </MedCol>

            <MedCol headers='SubAreaName'>
                {   // Bold when modified
                    props.holiday.isModified && !props.holiday.isDeleted && (
                    <strong>{props.holiday.PersonnelSubAreaName}</strong>
                )}

                {   // Italic when deleted
                    props.holiday.isDeleted && (
                    <em>{props.holiday.PersonnelSubAreaName}</em>
                )}

                {   // Normal when unchanged
                    !props.holiday.isModified && !props.holiday.isDeleted && (
                    <>{props.holiday.PersonnelSubAreaName}</>
                )}
            </MedCol>

            <SmallCol headers='CorporateHoliday' >
                { props.holiday.Corporate ? (
                    <Icon 
                        title='Corporate Holiday'
                        aria-label='Corporate Holiday Check Mark'
                        iconName='CheckMark'
                        style={
                            props.holiday.isDeleted? {fontWeight: 500, fontStyle: 'italic'} :
                            props.holiday.isModified? {fontWeight: 'bold'} : 
                            {fontWeight: 'normal'}}
                    />
                ) : <Icon
                        title='Corporate Holiday'
                        aria-label='Corporate Holiday Not Marked'
                        iconName='Blocked'
                        style={{fontWeight: 'normal'}}/>
            }
            </SmallCol>
            
            <SmallCol headers='RetailHoliday' >
                { props.holiday.Retail ? (
                    <Icon
                        title='Retail Holiday'
                        aria-label='Retail Holiday Check Mark'
                        iconName='CheckMark'
                        style={
                            props.holiday.isDeleted? {fontWeight: 500, fontStyle: 'italic'} :
                            props.holiday.isModified? {fontWeight: 'bold'} : 
                            {fontWeight: 'normal'}}/>
                ) : <Icon
                        title='Retail Holiday'
                        aria-label='Retail Holiday Not Marked'
                        iconName='Blocked'
                        style={{fontWeight: 'normal'}}/>
                }
            </SmallCol>

            <MedCol headers='StartDate'>
                {   // Bold when modified
                    props.holiday.isModified && !props.holiday.isDeleted && (
                    <strong>{props.holiday.PublicHolidayStartDate.substring(0, 10)}</strong>
                )}

                {   // Italic when deleted
                    props.holiday.isDeleted && (
                    <em>{props.holiday.PublicHolidayStartDate.substring(0, 10)}</em>
                )}

                {   // Normal when unchanged
                    !props.holiday.isModified && !props.holiday.isDeleted && (
                    <>{props.holiday.PublicHolidayStartDate.substring(0, 10)}</>
                )}
            </MedCol>

            <MedCol headers='EndDate'>
                {   // Bold when modified
                    props.holiday.isModified && !props.holiday.isDeleted && (
                    <strong>{props.holiday.PublicHolidayEndDate.substring(0, 10)}</strong>
                )}

                {   // Italic when deleted
                    props.holiday.isDeleted && (
                    <em>{props.holiday.PublicHolidayEndDate.substring(0, 10)}</em>
                )}

                {   // Normal when unchanged
                    !props.holiday.isModified && !props.holiday.isDeleted && (
                    <>{props.holiday.PublicHolidayEndDate.substring(0, 10)}</>
                )}
            </MedCol>
            
            <LargeCol headers='EditOrDelete'>
                {
                    !props.holiday.isDeleted && (
                        <>
                            <DefaultButton
                                title={'Edit'}
                                hidden={props.holiday.isDeleted}
                                iconProps={{ ariaLabel: 'Edit Holiday', iconName: 'Edit' }}
                                usageEvent={{ feature: 'GHC', subFeature: 'EditHoliday', featureLocation: 'Admin' }}
                                style={{ margin: 2, backgroundColor: '#e3e3e3' }}
                                onClick={props.onEdit}
                            />

                            <DefaultButton
                                title={'Delete'}
                                hidden={props.holiday.isDeleted}
                                iconProps={{ ariaLabel: 'Delete Holiday', iconName: 'Trash' }}
                                usageEvent={{ feature: 'GHC', subFeature: 'DeleteHoliday', featureLocation: 'Admin'  }}
                                style={{ margin: 2, backgroundColor: '#e3e3e3' }}
                                onClick={props.onDelete}
                            />
                        </>
                    )
                }
            </LargeCol>
        </SingleHolidayContainer>
    );
}

export const HolidayHeader = (): ReactElement => {
    return (
        <HeaderContainer>
            <MedHeadCol
                role='columnheader'
                id='HolidayName'>
                Holiday
            </MedHeadCol>

            <MedHeadCol
                role='columnheader'
                id='SubAreaName'>
                SubArea
            </MedHeadCol>

            <SmallHeadCol
                role='columnheader'
                id='CorporateHoliday'>
                Corporate Holiday
            </SmallHeadCol>
            
            <SmallHeadCol
                role='columnheader'
                id='RetailHoliday'
                title='Direct Sales and Support Holiday'>
                Retail Holiday
            </SmallHeadCol>

            <MedHeadCol
                role='columnheader'
                id='StartDate'>
                Start Date
            </MedHeadCol>
            
            <MedHeadCol
                role='columnheader'
                id='EndDate'>
                End Date
            </MedHeadCol>
            
            <LargeHeadCol
                role='columnheader'
                id='EditOrDelete'>
                Edit or Delete
            </LargeHeadCol>
        </HeaderContainer>
    );
}
