import { ReactElement, useState } from 'react';
import * as React from 'react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { CoherencePanel } from '@coherence-design-system/controls';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { Stack, Dropdown, IDropdownOption, TextField, DatePicker, DayOfWeek, FirstWeekOfYear} from '@fluentui/react';
import { IHolidayEditable, IHolidayRegion } from '../../../Services/GlobalHolidaysService.types';
import { InputTitle, InputContainer, Alert } from './Panel.styled';
import { UserEvent, UsageEventName, EventType } from '@micro-frontend-react/employee-experience/lib/UsageTelemetry';

interface AddPanelProps {
    region: IHolidayRegion,
    subAreas: IDropdownOption[],
    holidayTypes: IDropdownOption[],
    onConfirm: (newHoliday: IHolidayEditable) => void,
    onCancel: () => void,
}

// Fly out panel to add a new holiday
export const AddPanel = (props: AddPanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [name, setName] = useState<string>("");
    const [subArea, setSubArea] = useState<IDropdownOption>({ key: '0', text: 'All sub areas' });
    const [holidayType, setHolidayType] = useState<IDropdownOption>({ key: 'corporate', text: 'Corporate Holiday'});
    const [startDate, setStartDate] = useState<Date>(null);
    const [endDate, setEndDate] = useState<Date>(null);
    const [displayInvalidInputAlert, setDisplayInvalidInputAlert] = useState<boolean>(false);
    const [displayDateError, setDisplayDateError] = useState<boolean>(false);

    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'];

    const onPressConfirm = () => {
        if (name === "" || startDate == null || endDate == null) {
            setDisplayDateError(false);
            setDisplayInvalidInputAlert(true);
        } else if (endDate < startDate) {
            setDisplayInvalidInputAlert(false);
            setDisplayDateError(true);
        } else {
            const newHoliday: IHolidayEditable = {
                id: -1,  // cannot be reverted to original since it is new
                isModified: true,
                isDeleted: false,
                PublicHolidayDate: `${monthNames[startDate.getMonth()]} ${startDate.getDate()}, ${startDate.getFullYear()}`,
                PublicHolidayStartDate: formatDateToString(startDate),
                PublicHolidayEndDate: formatDateToString(endDate),
                PublicHolidayName: name,
                CountryName: props.region.CountryName,
                CountryCode: props.region.CountryCode,
                CompanyCode: props.region.CompanyCode,
                PersonnelSubAreaCode: subArea.key.toString(),
                PersonnelSubAreaName: subArea.text,
                ListViewImageUrl: null,
                RegImageUrl: null,
                GrayscaledListViewImageUrl: null,
                Corporate: holidayType.key === 'corporate' || holidayType.key === 'both',
                Retail: holidayType.key === 'retail' || holidayType.key === 'both',
            };
            props.onConfirm(newHoliday);
        }
    }

    const renderInvalidInputError = () => {
        return (
            <>
                { displayInvalidInputAlert && (
                    <Alert>Name, sub area, start date, and end date are all required.</Alert>
                )}
            </>
        );
    }

    const renderDateError = () => {
        return (
            <>
                { displayDateError && (
                    <Alert>End date cannot be before start date.</Alert>
                )}
            </>
        )
    }

    const renderSubAreaDropDown = () => {
        return (
            <>
                <InputTitle>Sub Area:</InputTitle>
                {
                    (props.subAreas.length > 0) && (
                        <InputContainer>
                            <Dropdown
                                selectedKey={subArea.key}
                                onChange={
                                    (e, option) => {
                                        const event: UserEvent = {
                                            feature: 'GCH',
                                            subFeature: 'AddHoliday.SelectSubArea',
                                            featureLocation: 'Admin',
                                            eventName: UsageEventName.DropdownSelected,
                                            type: EventType.User,
                                        };
                                        telemetryClient.trackEvent(event, {
                                            SubArea: option.text
                                        });
                                        setSubArea(option);
                                    }
                                }
                                options={[...props.subAreas, { key: '0', text: 'All sub areas' }]}
                                title='Sub Area Dropdown'
                                ariaLabel='Sub Area Dropdown'
                            />
                        </InputContainer>
                    )
                }
                {
                    (!props.subAreas.length) && (
                        <p>{subArea.text}</p>
                    )
                }
            </>
        );
    }

    return (

        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Add a Holiday'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >

            <Stack>
                <Stack.Item>
                    Edit this a holiday in <strong>{`${props.region.CountryName} (${props.region.CompanyCode})`}.</strong>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Name</InputTitle>
                    <InputContainer>
                        <TextField
                            placeholder='Enter a name'
                            ariaLabel='Enter a name'
                            required={true}
                            value={name}
                            onChange={(e, value) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'AddHoliday.SetName',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.TextChanged,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Name: value
                                });
                                setName(value);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    {renderSubAreaDropDown()}
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Holiday Type</InputTitle>
                    <InputContainer>
                        <Dropdown
                            selectedKey={holidayType.key}
                            onChange={ 
                                (e, option) => {
                                    const event: UserEvent = {
                                        feature: 'GCH',
                                        subFeature: 'AddHoliday.SelectHolidayType',
                                        featureLocation: 'Admin',
                                        eventName: UsageEventName.DropdownSelected,
                                        type: EventType.User,
                                    };
                                    telemetryClient.trackEvent(event, {
                                        HolidayType: option.text
                                    });
                                    setHolidayType(option);
                                }
                            }
                            options={props.holidayTypes}
                            title='Holiday Type Dropdown'
                            ariaLabel='Holiday Type Dropdown'
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>Start Date</InputTitle>
                    <InputContainer>
                        <DatePicker
                            value={startDate}
                            firstDayOfWeek={DayOfWeek.Sunday}
                            firstWeekOfYear={FirstWeekOfYear.FirstDay}
                            showMonthPickerAsOverlay={true}
                            isRequired={true}
                            placeholder='Select a start date'
                            ariaLabel='Select a start date'
                            onSelectDate={(date) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'AddHoliday,SelectStartDate',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.DatePickerClicked,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Date: date
                                });
                                setStartDate(date);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <InputTitle>End Date</InputTitle>
                    <InputContainer>
                        <DatePicker
                            value={endDate}
                            firstDayOfWeek={DayOfWeek.Sunday}
                            firstWeekOfYear={FirstWeekOfYear.FirstDay}
                            showMonthPickerAsOverlay={true}
                            isRequired={true}
                            placeholder='Select an end date'
                            ariaLabel='Select an end date'
                            onSelectDate={(date) => {
                                const event: UserEvent = {
                                    feature: 'GCH',
                                    subFeature: 'AddHoliday.SelectEndDate',
                                    featureLocation: 'Admin',
                                    eventName: UsageEventName.DatePickerClicked,
                                    type: EventType.User,
                                };
                                telemetryClient.trackEvent(event, {
                                    Date: date
                                });
                                setEndDate(date);
                            }}
                        />
                    </InputContainer>
                </Stack.Item>

                <Stack.Item>
                    <DefaultButton
                        text='Cancel'
                        title='Cancel'
                        usageEvent={{ feature: 'GHC', subFeature: 'AddHoliday.Cancel', featureLocation: 'Admin' }}
                        style={{ marginRight: 10, marginTop: 20 }}
                        onClick={props.onCancel}
                    />

                    <DefaultButton
                        text='Confirm'
                        title='Confirm'
                        usageEvent={{ feature: 'GHC', subFeature: 'AddHoliday.Confirm', featureLocation: 'Admin' }}
                        style={{ marginTop: 20, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' }}
                        onClick={onPressConfirm}
                    />
                </Stack.Item>

                <Stack.Item>
                    <div role="alert">
                        {renderInvalidInputError()}
                        {renderDateError()}
                    </div>
                </Stack.Item>

            </Stack>
        </CoherencePanel>
    );
}

// Utilities
const formatDateToString = (date: Date) => {
    let result = `${date.getFullYear()}-`;
    date.getMonth() + 1 < 10 ? result += `0` : null;
    result += `${date.getMonth() + 1}-`;
    date.getDate() < 10 ? result += `0` : null;
    result += `${date.getDate()} 00:00:00.000`;
    return result;
}