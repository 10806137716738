import { ReactElement } from 'react';
import * as React from 'react';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { IHolidayEditable,IHolidayRegion } from '../../../Services/GlobalHolidaysService.types';
import { HolidayDescription } from '../../CalendarPanel/CalendarPanel.styled';

interface DeletePanelProps {
    holiday: IHolidayEditable,
    region: IHolidayRegion,
    onConfirm: (deletedOriginal? : IHolidayEditable) => void,
    onCancel: () => void,
}

// Fly out panel to delete a Holiday
export const DeletePanel = (props: DeletePanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    return (
        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Delete Holiday'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >
            <p>
                Deleting <strong>{props.holiday.PublicHolidayName}</strong> from <strong>{props.region.CountryName} ({props.region.CompanyCode}) </strong>
                for sub area(s) <strong>{props.holiday.PersonnelSubAreaName} </strong>with start date <strong>{props.holiday.PublicHolidayStartDate.substring(0, 10)} </strong>
                and end date <strong>{props.holiday.PublicHolidayEndDate.substring(0, 10)}</strong>.
            </p>

            <DefaultButton
                text='Cancel'
                title='Cancel'
                usageEvent={{ feature: 'GHC', subFeature: 'DeletePanel.Cancel', featureLocation: 'Admin' }}
                style={{ marginRight: 10, marginTop: 20 }}
                onClick={props.onCancel}
            />

            <DefaultButton
                text='Confirm'
                title='Confirm'
                usageEvent={{ feature: 'GHC', subFeature: 'DeletePanel.Confirm', featureLocation: 'Admin' }}   
                style={{ marginTop: 20, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' }}
                onClick={() => {
                    if (props.holiday.id != -1) {
                        const deletedOriginal: IHolidayEditable = {
                            ...props.holiday,
                            isDeleted: true
                        };
                        props.onConfirm(deletedOriginal);
                    } else {
                        props.onConfirm();
                    }
                }}
            />
        </CoherencePanel>
    );
}