import { ReactElement, useState } from 'react';
import * as React from 'react';
import { CoherencePanel } from '@coherence-design-system/controls';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { IHoliday, IHolidayRegion, IHolidayEditable, IHolidayResponse } from '../../../Services/GlobalHolidaysService.types';
import GlobalHolidaysService from '../../../Services/GlobalHolidaysService';

interface SubmitPanelProps {
    region: IHolidayRegion,
    holidays: Map<string, IHolidayEditable[]>,
    globalHolidaysService: GlobalHolidaysService,
    onCancel: () => void,
    onClose: () => void
}

// Fly out to submit changes
export const SubmitPanel = (props: SubmitPanelProps): ReactElement => {

    const { telemetryClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);

    const [submitted, setSubmitted] = useState<boolean>(false);
    const [response, setResponse] = useState<IHolidayResponse>({Success: false});

    // Handles submission of changes into database
    const onSubmit = () => {
        // Flatten holidays map into array of IHoliday format (remove ID)
        const finalHolidays : IHoliday[] = [];
        props.holidays.forEach((holidaySet, year) => {
            holidaySet.map((holiday) => {
                if (!holiday.isDeleted) {
                    delete holiday['id'];
                    delete holiday['isModified'];
                    delete holiday['isDeleted'];
                    finalHolidays.push(holiday);
                }
            })
        });

        // Submit holidays and get response
        props.globalHolidaysService.postUpdatedHolidays(
            props.region.CompanyCode,
            props.region.CountryCode,
            finalHolidays)
            .then((results) => {setResponse(results); setSubmitted(true);})
            .catch((err) => {setSubmitted(true); console.error("err: ", err);});
    }

    return (
        <CoherencePanel
            isOpen={true}
            customWidth='600px'
            isLightDismiss={true}
            titleText='Confirmation'
            closeButtonAriaLabel='Close panel'
            hasCloseButton={false}
            overlayProps={{
                isDarkThemed: true,
            }}
            telemetryHook={telemetryClient}
        >
            {
                !submitted && (
                    <p>
                        Would you like to submit these changes for {`${props.region.CountryName} (${props.region.CompanyCode})`}?
                    </p>
                )
            }

            {
                submitted && response.Success && (
                    <p>
                        Holidays successfully submitted! Changes may not be visible in the main holidays app for up 
                        to {response.CacheRefreshTimeInHours} hours, but are available in the admin portal. To make them 
                        immediately visible in the main holidays app, go to admin home page and use the "Sync Holiday Data"
                        button.
                    </p>
                )
            }

            {
                submitted && !response.Success && (
                    <p>
                        Something went wrong, please try again soon.
                    </p>
                )
            }

            {
                !submitted && (
                    <>
                        <DefaultButton
                            text={'Cancel'}
                            title={'Cancel'}
                            usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays.Cancel', featureLocation: 'Admin' }}
                            style={{ marginRight: 10, marginTop: 20 }}
                            onClick={props.onCancel}
                        />

                        <DefaultButton
                            text='Submit'
                            title='Submit'
                            usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays.Confirm', featureLocation: 'Admin' }}     
                            style={{ marginTop: 20, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' }}
                            onClick={onSubmit}
                        />
                    </>
                )
            }

            {
                submitted && (
                    <DefaultButton
                        text={'Go Back'}
                        title={'Go Back'}
                        usageEvent={{ feature: 'GHC', subFeature: 'SubmitHolidays.Close', featureLocation: 'Admin' }}
                        style={{ marginRight: 10, marginTop: 20 }}
                        onClick={props.onClose}
                    />
                )
            }
        </CoherencePanel>
    );
}