
export const LoadingContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const Loading = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;
