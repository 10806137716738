import { ReactElement, useState, useEffect } from 'react';
import * as React from 'react';
import { Context } from '@micro-frontend-react/employee-experience/lib/Context';
import { IEmployeeExperienceContext } from '@micro-frontend-react/employee-experience/lib/IEmployeeExperienceContext';
import { DefaultButton } from '@micro-frontend-react/employee-experience/lib/Buttons/DefaultButton';
import { Stack, IComboBoxOption, Spinner, SpinnerSize } from '@fluentui/react';
import { CompanyCodeSelector } from './CompanyCodeSelector';
import { IHolidayRegion, IHolidayResponse } from '../../../Services/GlobalHolidaysService.types';
import CompanyCodeService from '../../../Services/CompanyCodeService';
import GlobalHolidaysService from '../../../Services/GlobalHolidaysService';
import { SectionTitle } from '../../../Shared/Layout.styled';
import { LoadingContainer, LoadingText } from './HolidayOptionsMenu.styled';

interface HolidayOptionsMenuProps {
    onChange: (selection: IHolidayRegion, isNew: boolean) => void,
    onNext: () => void,
    globalHolidaysService: GlobalHolidaysService,
    displayMessage: (message: string, isError: boolean) => void
}

export const HolidayOptionsMenu = (props: HolidayOptionsMenuProps): ReactElement => {
    
    const { httpClient } = React.useContext(Context as React.Context<IEmployeeExperienceContext>);
    const companyCodeService = new CompanyCodeService(httpClient);

    const initialOptionManaged : IComboBoxOption = { key: '0', text: 'Search for a Managed Company Code' };
    const initialOptionNew : IComboBoxOption = { key: '0', text: 'Search for a Company Code' };
    const newOption : IComboBoxOption = { key: 'new', text: 'Add a New Managed Company Code' };

    const [managedCompanyCodeOptions, setManagedCompanyCodeOptions] = useState<IHolidayRegion[]>([]);
    const [newCompanyCodeOptions, setNewCompanyCodeOptions] = useState<IHolidayRegion[]>([]);
    const [isNewRegion, setIsNewRegion] = useState<boolean>(false);
    const [selectedCompanyCode, setSelectedCompanyCode] = useState<string>(initialOptionManaged.key.toString());
    const [displayNewCompanyCodeSelection, setDisplayNewCompanyCodeSelections] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [syncing, setSyncing] = useState<boolean>(false);

    useEffect(() => {
        // Loads options for managed and new (/nonmanaged) company codes
        companyCodeService.getNonMsVacationCompanyCodes()
            .then((results: IHolidayRegion[]) => {
                setManagedCompanyCodeOptions(results);
                companyCodeService.getMsVacationCompanyCodes()
                    .then((results: IHolidayRegion[]) => {
                        setNewCompanyCodeOptions(results);
                        setLoading(false);
                    }).catch((err) => {console.error("err: ", err)});
            }).catch((err) => {console.error("err: ", err)});
        }, []);

    // Validates selection and advances or prompts selection of a new company
    // code if desired by user
    const onClickNext = () => {
        if (selectedCompanyCode == '0') {
            props.displayMessage('You must select a company code to continue', true);
        } else if (selectedCompanyCode == 'new') {
            setSelectedCompanyCode(initialOptionNew.key.toString());
            setIsNewRegion(true);
            setDisplayNewCompanyCodeSelections(true);
            props.displayMessage('', false);  // Clears any lingering message
        } else {
            props.onNext();
        }
    }

    const renderSyncHolidays = () => {
        return (
            <>
                {
                    !loading && (
                        <> 
                            <SectionTitle style={{marginTop: 30, marginBottom: 15}}>Sync Holiday Data</SectionTitle>
                            <p>
                                Refresh active application holiday data. Will make any changes made in admin portal
                                immediately available in hollidays app.
                            </p>
                            {
                                !syncing && (
                                    <DefaultButton
                                        text='Sync Holiday Data'
                                        title='Sync Holiday Data'
                                        usageEvent={{ feature: 'GHC', subFeature: 'BackToMenu.Confirm', featureLocation: 'Admin' }}
                                        style={{ marginTop: 20, color: 'white', backgroundColor: '#0078d4', borderColor: '#0078d4' }}
                                        onClick={() => {
                                            setSyncing(true);
                                            props.globalHolidaysService.syncHolidayData()
                                                .then((results : IHolidayResponse) => {
                                                    if (results.Success) {
                                                        props.displayMessage('Refresh successful - All holiday data is up to date', false);
                                                    } else {
                                                        props.displayMessage('Refresh failed - try again later', true);
                                                    }
                                                    setSyncing(false);
                                                })
                                                .catch((err) => {
                                                    console.error("err: ", err);
                                                    props.displayMessage('Refresh failed - try again later', true);
                                                    setSyncing(false);
                                                });
                                        }}
                                    />
                                )
                            }

                            {
                                syncing && (
                                    <LoadingContainer>
                                        <Spinner size={SpinnerSize.medium} />
                                    </LoadingContainer>
                                )
                            }
                        </>
                    )
                }
            </>
        );
    }

    return (
        <>
            <Stack>
                { loading && (
                    <Stack.Item>
                        <LoadingContainer>
                            <Spinner size={SpinnerSize.medium} />
                            <LoadingText>Loading...</LoadingText>
                        </LoadingContainer>
                    </Stack.Item>
                )}

                { !loading && !displayNewCompanyCodeSelection && (
                    <>
                        <Stack.Item>
                                <SectionTitle style={{marginBottom: 20}}>Company Code</SectionTitle>
                                <CompanyCodeSelector 
                                    onChange={(selection) => {
                                        setSelectedCompanyCode(selection);
                                        props.onChange(managedCompanyCodeOptions.filter((option) => { 
                                            return option.CompanyCode === selection; 
                                        })[0], isNewRegion);
                                    }}
                                    options={
                                        [...managedCompanyCodeOptions
                                            .map(option => ({ key: option.CompanyCode, text: `${option.CountryName} (${option.CompanyCode})`}))
                                            .sort((a, b) => {
                                                var textA = a.text.toUpperCase();
                                                var textB = b.text.toUpperCase();
                                                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                            }), newOption]
                                    }
                                    placeholderOption={initialOptionManaged}
                                />
                        </Stack.Item>
                    </>
                )}

                { !loading && displayNewCompanyCodeSelection && (
                        <Stack.Item>
                                <SectionTitle>Add New Managed Company Code</SectionTitle>
                                    <CompanyCodeSelector 
                                        onChange={(selection) => {
                                            setSelectedCompanyCode(selection);
                                            props.onChange(newCompanyCodeOptions.filter((option) => { 
                                                return option.CompanyCode === selection; 
                                            })[0], isNewRegion);
                                        }}
                                        options={
                                            [...newCompanyCodeOptions
                                                .map(option => ({ key: option.CompanyCode, text: `${option.CountryName} (${option.CompanyCode})`}))
                                                .sort((a, b) => {
                                                    var textA = a.text.toUpperCase();
                                                    var textB = b.text.toUpperCase();
                                                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                                                })]
                                        }
                                        placeholderOption={initialOptionNew}
                                    />
                        </Stack.Item>       
                )}

                { !loading && (               
                    <Stack.Item>
                        { displayNewCompanyCodeSelection && (
                            <DefaultButton
                                text='Back'
                                title='Back'
                                usageEvent={{ feature: 'GHC', subFeature: 'AdminPage.BackButton' }}
                                style={{ marginRight: 10, marginTop: 20 }}
                                onClick={() => {
                                    setSelectedCompanyCode(initialOptionManaged.key.toString());
                                    setDisplayNewCompanyCodeSelections(false);
                                    setIsNewRegion(false);
                                    props.displayMessage('', false);  // Clears any lingering message
                                }}
                            />
                        )}
                        <DefaultButton
                            text='Next'
                            title='Next'
                            usageEvent={{ feature: 'GHC', subFeature: 'AdminPage.NextButton' }}
                            style={{ marginRight: 10, marginTop: 20 }}
                            onClick={onClickNext}
                        />
                    </Stack.Item>
                )}
            </Stack>

            {renderSyncHolidays()}
        </>
    );
}
