import { IHttpClient } from '@micro-frontend-react/employee-experience/lib/IHttpClient';
import ServiceInterface from './ServiceInterface';

const requestDefaults = {
    resource: `${__HR_RESOURCE_URI__}`,
};

export interface IAdminPermissions {
    Alias: string;
    Admin: boolean;
}

export default class AdminService implements ServiceInterface {
    _httpClient: IHttpClient;

    constructor(httpClient: IHttpClient) {
        this._httpClient = httpClient;
    }

    public getPermissions = async (): Promise<IAdminPermissions> => {
        try {
            const permissions = await Promise.resolve(
                this._httpClient.request({
                    ...requestDefaults,
                    url: `${__HR_HOLIDAYS_BASE_URL__}/api/v1/AdminFunctionality/permissions`,
                })
                    .then((result) => result.data as IAdminPermissions));
            return permissions;
        } catch (err) {
            console.error(err);
            return null as IAdminPermissions;
        }
    }
}
