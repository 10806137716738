import * as React from 'react';
/**
 * Annoucements.js
 *
 *
 * The only required prop is `message` which is the text used when announcing
 * the message to the screen reader user.
 */

const StyledDiv = styled.div`
    position: absolute;
    top: 0;
    left: -10000px;
    width: 1px;
    height: 1px;
    font-size: 1px;
    overflow-hidden;
}
`;

const Announcements = ({message} : {message: string}) => {
    return <StyledDiv role="status" aria-live="polite" aria-atomic="true" aria-hidden="false">
        {message}
    </StyledDiv>
}

export default Announcements;